/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import DropDownBase from './DropDownBase';
import { connect } from 'react-redux'
import { loadProductName } from '../../../actions'

class DropDownProduct extends Component {

    componentDidMount(){
        loadProductName(this.props.dispatch)
    }

    render() {
        const { products_name } = this.props;

        const _options = []
        for(let i in products_name){
            _options.push({
                key: i,
                text: products_name[i].name,
                value: products_name[i].id
            })
        }

        return (<DropDownBase options={_options} {...this.props} />
        );
    }
}

const mapStateToProps = state =>{
    return ({
        products_name: state.products_name,
    })
  }
  export default connect(
    mapStateToProps,
  )(DropDownProduct)