/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Button,
  Grid,
  Input,
  Checkbox,
  Header,
  Segment,
  Dropdown
} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import CalculateDay from '../Lease/CalculateDay';
import MsgInput from '../Error/MsgInput'
import { connect } from 'react-redux'


class Setting extends Component {

  constructor(props) {
    super(props);

    const { branch } = props.auth
    this.state = {
      error: {},
      setting: {
        branch: branch.id
      },
      branch_id: branch.id,
      accounts: [],
    }

    this.handlerChangeBranch = this.handlerChangeBranch.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this)
    this.saveSetting = this.saveSetting.bind(this)
    this.handlerChangeCalculateDate = this.handlerChangeCalculateDate.bind(this)
  }

  componentDidMount() {
    this.setState({
      loader_active: true
    });

    var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
    var lease_setting = Utility.getFetch(Settings.baseUrl + '/branch_setting/?is_enabled=1');
    var account = Utility.getFetch(Settings.baseUrl + '/bank_account/?is_enabled=1');
    Promise.all([branches, lease_setting, account]).then((values) => {

      this.branches = values[0]
      this.lease_setting = values[1]

      let branches = []
      for (let i in this.branches) {
        branches.push({
          value: this.branches[i].id,
          key: this.branches[i].id,
          text: this.branches[i].name
        });
      }
      let accounts = []
      values[2].forEach(a => {
        accounts.push({
          value: a.id,
          key: a.id,
          text: `${a.name} (${a.number} | ${a.bank.name})`,
          branch: a.branch,
        })
      });
      this.setState({
        branches: branches,
        accounts: accounts,
        loader_active: false
      });

      let setting = this.getSettingByBranch(this.state.branch_id)

      if (setting) {
        this.setState({
          setting: setting
        })
      }
    });
  }

  getSettingByBranch(b_id) {
    for (let i in this.lease_setting) {
      if (this.lease_setting[i].branch == b_id) {
        return {
          ...this.lease_setting[i],
          transfer_bank_account: this.state.accounts.filter((a) => a.branch.includes(this.state.branch_id)).find((a) => a.value === this.lease_setting[i].transfer_bank_account) ? this.lease_setting[i].transfer_bank_account : null
        }
      }
    }
    return false
  }

  onChangeInput(e, v) {
    let setting = this.state.setting;
    if (v.name == 'counter' || v.name == 'tax_counter' || v.name == 'interest' || v.name == 'min_interest' || v.name == 'month' || v.name == 'staff_counter' || v.name == 'savings_counter' || v.name == 'savings_fee' || v.name == 'savings_withdraw_min_balance' || v.name == 'savings_min_open' || v.name == 'overdue_end_date') {
      if (isNaN(Number(v.value))) {
      } else {
        if (v.value.length === 0) {
          v.value = 0
        }
        let j = parseFloat(v.value)
        if (j < 0)
          v.value = 0
        if ((v.name == 'interest' || v.name == 'savings_fee') && j > 100)
          v.value = 100
        if (v.name == 'staff_counter' && j > 999)
          v.value = 999
      }
    }
    if (v.type == 'checkbox') {
      setting[v.name] = v.checked ? 1 : 0;
    } else
      if (v.name == 'interest' || v.name == 'min_interest' || v.name == 'savings_fee' || v.name == 'savings_withdraw_min_balance' || v.name == 'savings_min_open') {
        if (isNaN(Number(v.value))) {
        } else {
          let value = 0
          if (v.value.toString().split('.').length != 1) {
            if (v.value.split('.')[1] == '') {
              if (parseInt(v.value.split('.')[0]) != 100)
                value = parseInt(v.value.split('.')[0]) + '.'
              else
                value = parseInt(v.value.split('.')[0])
            } else
              value = parseInt(v.value.split('.')[0]) + '.' + v.value.split('.')[1]
          } else {
            value = parseInt(v.value)
          }
          setting[v.name] = value;
        }
      } else if (v.name == 'counter' || v.name == 'tax_counter' || v.name == 'month' || v.name == 'staff_counter' || v.name == 'savings_counter' || v.name == 'overdue_end_date') {
        if (isNaN(Number(v.value))) {
        } else {
          setting[v.name] = parseInt(v.value);
        }
      } else if (v.name == 'tax_code') {
        if (/^([0-9])*$/.test(v.value)) {
          setting[v.name] = v.value
        }

      } else {
        setting[v.name] = v.value;
      }
    this.setState({
      setting: setting
    })


  }

  saveSetting(e) {
    let setting = this.state.setting;
    let error = {}
    if (setting.tax_code == null || setting.tax_code == '') {
      error['tax_code'] = '*ไม่ควรเป็นค่าว่าง'
      this.tax_code.focus()
    }

    if (setting.staff_code == null || setting.staff_code == '') {
      error['staff_code'] = '*ไม่ควรเป็นค่าว่าง'
      this.staff_code.focus()
    }

    if (setting.code == null || setting.code == '') {
      error['code'] = '*ไม่ควรเป็นค่าว่าง'
      this.code.focus()
    }

    if (setting.transfer_bank_account == null || setting.transfer_bank_account == '') {
      error['transfer_bank_account'] = '*ไม่ควรเป็นค่าว่าง'
    }

    if (setting.min_interest < 20) {
      error['min_interest'] = '*ต้องไม่น้อยกว่า 20.00'
      this.min_interest.focus()
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error: error })
      return;
    }
    this.setState({
      button_loading: true
    })
    if (setting.id == null) {
      let url = Settings.baseUrl + "/branch_setting/";
      Utility.post(url, setting, (s, d) => {

        if (s) {

          this.setState({
            button_loading: false,
            error: {}
          })
          this.componentDidMount()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else {
          this.componentDidMount()
          this.setState({
            button_loading: false,
            error: d
          })

        }
      });
    } else {
      let url = Settings.baseUrl + "/branch_setting/" + setting.id + "/";
      if (!window.confirm('ยืนยันการเปลี่ยนแปลงตั้งค่าสาขา ' + Utility.getObject(this.props.branches, this.state.branch_id).name + '\nควรบันทึกการเปลี่ยนแปลงสาขาหลังจากปิดร้าน!')) {
        this.setState({
          button_loading: false,
        })
        return;
      }
      Utility.put(url, setting, (s, d) => {


        let error = {}
        let setting = {}
        if (s) {
          setting = d
          this.componentDidMount()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else {
          error = d
          if (error.tax_code != null) {
            this.tax_code.focus()
          } else if (error.staff_code != null) {
            this.staff_code.focus()
          } else if (error.code != null) {
            this.code.focus()
          } else if (error.savings_counter != null) {
            this.savings_counter.focus()
          } if (error.min_interest != null) {
            this.min_interest.focus()
          }
        }
        this.setState({
          button_loading: false,
          error: error
        })

      });
    }
  }

  handlerChangeBranch(e, v) {
    this.setState({
      branch_id: v.value
    })
    let setting = this.getSettingByBranch(v.value)

    if (setting) {
      this.setState({
        setting: {
          ...setting,
          code_r: setting.code_r || '',
          noti_name_1: setting.noti_name_1 || '',
          noti_token_1: setting.noti_token_1 || '',
          noti_name_2: setting.noti_name_2 || '',
          noti_token_2: setting.noti_token_2 || '',
        }
      })
    } else {
      this.setState({
        setting: {
          branch: v.value,
          counter: '',
          code: '',
          counter_r: '',
          code_r: '',
          month: '',
          interest: '',
          min_interest: '',
          date: '',
          noti_name_1: '',
          noti_token_1: '',
          noti_name_2: '',
          noti_token_2: '',
        }
      })
    }
  }

  handlerChangeCalculateDate(e, v) {
    let setting = this.state.setting;
    setting.calculate_date = v.value;

    this.setState({
      setting: setting
    })
  }

  render() {
    return (
      <div>
        <Form>
          <Form.Field>
            <label>สาขา</label>
            <Dropdown id='dropDownBranch' placeholder='สาขา' search selection width={4} value={this.state.branch_id}
              defaultValue={this.state.branch_id}
              options={this.state.branches}
              onChange={this.handlerChangeBranch} />
          </Form.Field>
        </Form>
        <br />
        <Form className='attached fluid ' size='small'>
          <Grid divided='vertically'>
            <Grid.Row >
              <Grid.Column width={8}>
                <Segment>
                  <Header size='huge'>เลขที่สาขาใบกำกับภาษี</Header>
                  <Form.Field error={this.state.error.tax_code != null}>
                    <label>เลขที่สาขา <MsgInput text={this.state.error.tax_code} /></label>
                    <Input ref={(c) => { this.tax_code = c }} id='inputCatagoryVat' value={this.state.setting.tax_code} pattern="[0-9]{5}" name='tax_code' onChange={this.onChangeInput} maxLength={5} />
                    <small>ต้องเป็นตัวเลข 0-9 จำนวน 5 หลัก เช่น 00001</small>
                  </Form.Field>
                  {/* <Form.Field>
                    <label>เลขที่เริ่มต้น</label>
                    <Input id='inputStartIDVat' className='text-right' value={this.state.setting.tax_counter} pattern="[0-9]" name='tax_counter' onChange={this.onChangeInput} min='0'
                      // type='number'
                      maxLength={9}
                    />
                  </Form.Field> */}
                </Segment>
                <Segment>
                  <Header size='huge'>ขายฝาก</Header>
                  <Form.Field error={this.state.error.code != null}>
                    <label>หมวดเริ่มต้น (A) <MsgInput text={this.state.error.code} /></label>
                    <Input id='inputCatagoryLease' ref={(c) => { this.code = c }} value={this.state.setting.code} pattern="[A-Z]{2}" name='code' onChange={this.onChangeInput} maxLength='2' />
                    <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ2ตัวอักษรเช่น AA</small>
                  </Form.Field>
                  <Form.Field>
                    <label>เลขที่เริ่มต้น (A)</label>
                    <Input id='inputStartIDLease' className='text-right' value={this.state.setting.counter} min='0' pattern="[0-9]" name='counter' onChange={this.onChangeInput}
                      maxLength={5}
                    // type='number'
                    />
                  </Form.Field>
                  <br />
                  <hr />
                  <Form.Field error={this.state.error.code_r != null}>
                    <label>หมวดเริ่มต้น (R) <MsgInput text={this.state.error.code_r} /></label>
                    <Input id='inputCatagoryLease' ref={(c) => { this.code_r = c }} value={this.state.setting.code_r} pattern="[A-Z]{2}" name='code_r' onChange={this.onChangeInput} maxLength='2' />
                    <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ2ตัวอักษรเช่น AA</small>
                  </Form.Field>
                  <Form.Field>
                    <label>เลขที่เริ่มต้น (R)</label>
                    <Input id='inputStartIDLease' className='text-right' value={this.state.setting.counter_r} min='0' pattern="[0-9]" name='counter_r' onChange={this.onChangeInput}
                      maxLength={5}
                    // type='number'
                    />
                  </Form.Field>
                  <br />
                  <hr />
                  <Form.Field>
                    <label>จำนวนเดือนขายฝาก</label>
                    <Input id='inputMonthLease' className='text-right' value={this.state.setting.month} name='month' onChange={this.onChangeInput}
                      // type='number'
                      min={0}
                      maxLength={2} />
                  </Form.Field>
                  <Form.Field>
                    <label>ส่วนต่างราคาขายฝาก</label>
                    <Input
                      id='lease_price_diff'
                      className='text-right'
                      value={this.state.setting.lease_price_diff}
                      readOnly
                      disabled
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>อัตราดอกเบี้ยขายฝาก (%)</label>
                    <Input id='inputRateLease' className='text-right' value={this.state.setting.interest} name='interest'
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_interest) {
                            let setting = this.state.setting;
                            setting['interest'] = Number(setting['interest']).toFixed(2);
                            this.setState({
                              focus_interest: false,
                              setting: setting,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_interest) {
                          this.setState({
                            focus_interest: true,
                          });
                        }
                      }}
                      onChange={(e, d) => {
                        if (isNaN(Number(e.target.value))) {
                        } else {
                          let value = e.target.value
                          if (value.split('.').length != 1)
                            if (value.split('.')[1].length > 2) {
                              return;
                            }
                          this.onChangeInput(e, d)
                        }
                      }}
                      // type='number' 
                      min={0} max={100} />
                    <small>limit 100%</small>
                  </Form.Field>
                  <Form.Field error={this.state.error.min_interest != null}>
                    <label>ดอกเบี้ยขายฝากขั้นต่ำ <MsgInput text={this.state.error.min_interest} /></label>
                    <Input id='inputMinRateLease' className='text-right' name='min_interest'
                      value={this.state.setting.min_interest == undefined ? '' :
                        this.state.focus_min_interest ?
                          this.state.setting.min_interest :
                          Utility.priceFormat(this.state.setting.min_interest)}
                      ref={(c) => { this.min_interest = c }}
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_min_interest) {
                            this.setState({
                              focus_min_interest: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_min_interest) {
                          this.setState({
                            focus_min_interest: true,
                          });
                        }
                      }}
                      onChange={(e, d) => {
                        if (isNaN(Number(e.target.value))) {
                        } else {
                          let value = e.target.value
                          if (value.split('.')[0].length > 18) {
                            return;
                          }
                          if (value.split('.').length != 1) {
                            if (value.split('.')[1].length > 2) {
                              return;
                            }
                          }
                          this.onChangeInput(e, d)
                        }
                      }}
                      // type='number'
                      min={0} />
                    <small>ขั้นต่ำ 20.00</small>

                  </Form.Field>
                  <Form.Field>
                    <label>กำหนดวันชำระไม่เกิน</label>
                    <Input id='inputOverdueEndDate' className='text-right' value={this.state.setting.overdue_end_date} name='overdue_end_date' onChange={this.onChangeInput}
                      // type='number'
                      min={0}
                      maxLength={2} />
                  </Form.Field>
                  <CalculateDay
                    onChange={this.handlerChangeCalculateDate}
                    value={this.state.setting.calculate_date}
                  />
                  <br />
                  <Form.Field>
                    <Checkbox id='userCanChangeRate' label='User สามารถแก้ไขอัตราดอกเบี้ยได้' value='1' checked={this.state.setting.is_user_edit_interest == 1} name='is_user_edit_interest' onChange={this.onChangeInput} />
                  </Form.Field>
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Header size='huge'>รหัสพนักงาน</Header>
                  <Form.Field error={this.state.error.staff_code != null}>
                    <label>หมวดเริ่มต้น <MsgInput text={this.state.error.staff_code} /></label>
                    <Input id='inputCatagoryStaff' ref={(c) => { this.staff_code = c }} value={this.state.setting.staff_code} name='staff_code' pattern="[A-Z]{1}" onChange={this.onChangeInput} maxLength={1} />
                    <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ1ตัวอักษรเช่น A</small>
                  </Form.Field>
                  <Form.Field>
                    <label>เลขที่เริ่มต้น</label>
                    <Input id='IDstaffstart' className='text-right' value={this.state.setting.staff_counter} pattern="[0-9]" name='staff_counter' onChange={this.onChangeInput} min='0'
                      maxLength={3}
                    // type='number'
                    />
                  </Form.Field>
                </Segment>
                <Segment>
                  <Header size='huge'>เลขที่บัญชีออมทอง</Header>
                  <Form.Field error={this.state.error.savings_counter != null}>
                    <label>เลขที่เริ่มต้น <MsgInput text={this.state.error.savings_counter} /></label>
                    <Input id='savings_counter' ref={(c) => { this.savings_counter = c }} className='text-right' value={this.state.setting.savings_counter} name='savings_counter' pattern="[0-9]" onChange={this.onChangeInput} min='0' maxLength={7} />
                    {/* <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ1ตัวอักษรเช่น A</small> */}
                  </Form.Field>
                  <Form.Field>
                    <label>%ค่าธรรมเนียมถอนเงินออมทอง</label>
                    <Input id='savings_fee'
                      className='text-right'
                      value={this.state.setting.savings_fee == undefined ? '' :
                        this.state.focus_savings_fee ?
                          this.state.setting.savings_fee :
                          this.state.setting.savings_fee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                      pattern="[0-9]"
                      name='savings_fee'
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_savings_fee) {
                            let setting = this.state.setting;
                            setting['savings_fee'] = Number(setting['savings_fee']).toFixed(2);
                            this.setState({
                              focus_savings_fee: false,
                              setting: setting,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_savings_fee) {
                          this.setState({
                            focus_savings_fee: true,
                          });
                        }
                      }}
                      onChange={(e, d) => {
                        if (isNaN(Number(e.target.value))) {
                        } else {
                          let value = e.target.value
                          if (value.split('.').length != 1)
                            if (value.split('.')[1].length > 2) {
                              return;
                            }
                          this.onChangeInput(e, d)
                        }
                      }}
                      min={0}
                    // type='number'
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>ยอดเงินคงเหลือขั้นต่ำหลังถอนเงินสด</label>
                    <Input id='savings_withdraw_min_balance'
                      className='text-right'
                      value={this.state.setting.savings_withdraw_min_balance == undefined ? '' :
                        this.state.focus_savings_withdraw_min_balance ?
                          this.state.setting.savings_withdraw_min_balance :
                          Utility.priceFormat(this.state.setting.savings_withdraw_min_balance)}
                      pattern="[0-9]"
                      name='savings_withdraw_min_balance'
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_savings_withdraw_min_balance) {
                            let setting = this.state.setting;
                            setting['savings_withdraw_min_balance'] = Number(setting['savings_withdraw_min_balance']).toFixed(2);
                            this.setState({
                              focus_savings_withdraw_min_balance: false,
                              setting: setting,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_savings_withdraw_min_balance) {
                          this.setState({
                            focus_savings_withdraw_min_balance: true,
                          });
                        }
                      }}
                      onChange={(e, d) => {
                        if (isNaN(Number(e.target.value))) {
                        } else {
                          let value = e.target.value
                          if (value.split('.').length != 1)
                            if (value.split('.')[1].length > 2) {
                              return;
                            }
                          this.onChangeInput(e, d)
                        }
                      }}
                      maxLength={12}
                      min={0}
                    // type='number'
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>เงินฝากขั้นต่ำ</label>
                    <Input id='savings_min_open'
                      className='text-right'
                      value={this.state.setting.savings_min_open == undefined ? '' :
                        this.state.focus_savings_min_open ?
                          this.state.setting.savings_min_open :
                          Utility.priceFormat(this.state.setting.savings_min_open)}
                      pattern="[0-9]" name='savings_min_open'
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_savings_min_open) {
                            let setting = this.state.setting;
                            setting['savings_min_open'] = Number(setting['savings_min_open']).toFixed(2);
                            this.setState({
                              focus_savings_min_open: false,
                              setting: setting,
                            });
                          }
                        }, 0);
                      }}
                      maxLength={12}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_savings_min_open) {
                          this.setState({
                            focus_savings_min_open: true,
                          });
                        }
                      }}
                      onChange={(e, d) => {
                        if (isNaN(Number(e.target.value))) {
                        } else {
                          let value = e.target.value
                          if (value.split('.').length != 1)
                            if (value.split('.')[1].length > 2) {
                              return;
                            }
                          this.onChangeInput(e, d)
                        }
                      }}
                      min={0}
                    // type='number'
                    />
                  </Form.Field>
                </Segment>
                <Segment>
                  <Header size='huge'>รายรับ-รายจ่าย</Header>
                  <Form.Field error={this.state.error.transfer_bank_account != null}>
                    <label>บัญชีธนาคารตั้งต้น <MsgInput text={this.state.error.transfer_bank_account} /></label>
                    <Dropdown id='dropDownBranch' ref={(c) => { this.transfer_bank_account = c }} placeholder='เลือกบัญชี' name='transfer_bank_account' selection width={4} value={this.state.setting.transfer_bank_account}
                      options={this.state.accounts.filter((a) => a.branch.includes(this.state.branch_id))}
                      onChange={this.onChangeInput} />
                  </Form.Field>
                </Segment>
                <Segment>
                  <Header size='huge'>Line Notification แจ้งเตือนการชำระเงิน</Header>
                  <Form.Field>
                    <label>สำเร็จ {this.state.setting.noti_name_1 ? <small>{`=> ${this.state.setting.noti_name_1}`}</small> : <small style={{ color: 'red' }}>{` ไม่ส่ง`}</small>}</label>
                    <Input
                      id='inputToken1'
                      value={this.state.setting.noti_token_1}
                      name='noti_token_1'
                      onChange={this.onChangeInput}
                    />
                    <small><a href='https://notify-bot.line.me/my/' target='_blank' rel="noreferrer">Get Access Token</a></small>
                  </Form.Field>
                  <Form.Field>
                    <label>ไม่สำเร็จ {this.state.setting.noti_name_2 ? <small>{`=> ${this.state.setting.noti_name_2}`}</small> : <small style={{ color: 'red' }}>{` ไม่ส่ง`}</small>}</label>
                    <Input
                      id='inputToken1'
                      value={this.state.setting.noti_token_2}
                      name='noti_token_2'
                      onChange={this.onChangeInput}
                    />
                    <small><a href='https://notify-bot.line.me/my/' target='_blank' rel="noreferrer">Get Access Token</a></small>
                  </Form.Field>
                </Segment>
                <Form.Group>

                  <Form.Field width={16}>
                    <br />
                    <Button id='btnsave_setting' content='บันทึก' onClick={this.saveSetting}
                      loading={this.state.button_loading}
                      floated='right' type='button' primary />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}

export default connect(
  mapStateToProps,
)(Setting)
