/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Segment,
  Header,
  Button,
  Loader,
  Dimmer,
  Input,
  Sidebar,
  Icon,
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';

import { activations_branch } from '../../actions'
import LeaseForm from './LeaseForm'
import { connect } from 'react-redux'
import { lease_modal_edit, lease_modal_add_open, lease_modal_close } from '../../actions'
import moment from 'moment';
import config from '../../config';

class LeaseSearchByNumber extends Component {

  constructor(props) {
    super(props);

    this.state = {
      number: '',
      action: 'edit'
    }
    this.handleInput = this.handleInput.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault();
    const { branch, action, auth } = this.props
    this.setState({
      loading: true,
      number: '',
    })
    if(action == 'interest') {
      var url = `${Settings.baseUrl}/lease/?not_disabled=1&number=${this.state.number.trim()}&type=${config.rd ? 'R': ''}`
    } else {
      var url = `${Settings.baseUrl}/lease/?not_disabled=1&number=${this.state.number.trim()}&branch=${branch.id}&type=${config.rd ? 'R': ''}`
    }
    Utility.get(url, (s, d) => {
      this.setState({
        loading: false,
        action: 'edit'
      })
      if (s && d.length == 1) {
        localStorage.setItem('auto_action_lease', this.props.action);
        let lease = d[0];
        if ((lease.status == 3 || lease.status == 4)) {
          alert('ใบขายฝากถูก' + (lease.status == 3 ? 'ไถ่คืน' : 'คัดออก') + 'แล้ว!')
          localStorage.setItem('auto_action_lease', '');
          return false
        } else if (action == 'interest' || action == 'redeem'||action == 'add_delete') {
          const overdue_end_date = moment(lease.end_date,"YYYY-MM-DD").add(lease.overdue_end_date, 'days').format("YYYY-MM-DD")
          var role_overdue_end_date = true
          if (auth.role == 'S' || auth.role == 'A' || (auth.role == 'M' && auth.branch_list.length > 1)) {
              role_overdue_end_date = false
          }
          if (moment().format("YYYY-MM-DD") > overdue_end_date && role_overdue_end_date) {
            alert('สินค้าหลุดจำนำ เนื่องจากลูกค้าชำระดอกเบี้ยล่าช้าเกินกว่าวันที่กำหนด '+lease.overdue_end_date+' วัน')
            localStorage.setItem('auto_action_lease', '');
            return false
          }
        }
        this.props.handleOpenLease(lease)
      } else if (s && d.length > 1) {
        alert('กรุณากรอกเลขที่ใบขายฝากให้ครบ')
       }
      else {
        alert('ไม่พบข้อมูลขายฝากนี้')
      }

    })
  }

  componentDidMount() {

    const { branch } = this.props
    if (branch)
      Utility.get(Settings.baseUrl + "/branch_setting/?branch=" + branch.id, (e, d) => {
        this.setState({
          setting: d[0]
        })
      });
  }

  handleInput(e, v) {

    this.setState({
      number: v.value
    })
  }

  render() {
    const { lease_modal } = this.props
    return (
      <div>
        <Form >
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>{this.props.title}</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">

          <Sidebar.Pushable >
            <Segment textAlign='left' >
              <Sidebar animation='overlay' direction='top' visible={this.state.visible} inverted>
                <div className='message-alert'>
                  บันทึกข้อมูลสำเร็จ
            </div>
              </Sidebar>
              <Dimmer active={this.state.loader_active} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>
              <Form textAlign='left' onSubmit={this.onSubmit} size='small'>
                <Form.Field>
                  <label>เลขที่ใบขายฝาก</label>
                  <Input id='inputBillNum' placeholder='เลขที่ใบขายฝาก' value={this.state.number} name='number' onChange={this.handleInput} />
                </Form.Field>
                <center>
                  <br />
                  <Button id='btnSearch' loading={this.state.loading} type='submit' primary><Icon name='search' />ค้นหา</Button>
                </center>
              </Form>
            </Segment>
          </Sidebar.Pushable>

          {lease_modal.is_open && this.state.setting &&
            <LeaseForm
              setting={this.state.setting}
              action={this.state.action}
              sub_action={this.state.action == 'edit' ? this.props.action : null}
              open={true}
              loading={this.state.lease_loading}
              data={this.state.data}
              onClose={() => {
                this.setState({ open: false })
                this.props.handleCloseLease()
              }}
              onReset={() => {
                this.setState({
                  action: 'add'
                })
                setTimeout(() => {
                  this.props.handleAddLease()

                }, 1000)

              }}
            />}
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return ({
    lease_modal: state.lease_modal,
    auth: state.auth,
    branches: state.branches
  })
}
const mapDispatchToProps = dispatch => ({
  handleBranchChange: (branch) => {
    dispatch(activations_branch(dispatch,branch))
  },
  handleOpenLease: (lease) => {
    lease_modal_edit(dispatch, lease.id)
  },
  handleCloseLease: () => {

    dispatch(lease_modal_close())
  },
  handleAddLease: () => {
    dispatch(lease_modal_add_open())
  }

})
export default connect(
  mapStateToProps, mapDispatchToProps
)(LeaseSearchByNumber)

