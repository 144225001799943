/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
// import LedgerFormSearch from './LedgerFormSearch'
import { connect } from 'react-redux'
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Button,
  Icon,
  Dimmer, Loader, Segment,
  Label,
  Header,
  Popup,
  Input,
  Modal,
  Pagination,
  Image,
  Grid,
  GridColumn,
} from 'semantic-ui-react';
import MsgInput from '../Error/MsgInput'
import moment from 'moment';
import { ContextMenuProvider } from 'react-contexify';
import InputSearchCustomer from '../Customer/InputSearchCustomer';
import DatePicker from 'react-datepicker';
import DropDownBranch from '../Widget/DropDown/DropDownBranch';

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { rowIndex, data } = this.props;
    const status = data[rowIndex]['status']
    return (
      <Cell><center style={{ padding: '4px' }}>
        <a onClick={(e) => { this.props.onClickMenu(e, data[rowIndex]) }} ><Icon name={status === 1 ? 'edit' : 'eye'} /></a>
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      'word-break': 'break-word'
    }
    return (
      <Cell {...props} >
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id">
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>
                {field === 'status_display' &&
                  <Icon
                    name='sticky note'
                    color={data[rowIndex]['status'] === 3 ? 'green' : (data[rowIndex]['status'] === 4 ? 'red' : 'grey')}
                  />}
                {data[rowIndex][field]}
              </div>}
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class PaymentManage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loader_active: true,
      submiting: false,
      start_date: '',
      end_date: '',
      colSortDirs: {
        'created_datetime': 'ASC',
      },
      counter: 0,
      page: 1,
      page_size: 10,
      search: {
        status: ['1']
      },
      selected: {},
      msg_error: {},
      remark: '',
    }
    this.columnKey = 'created_datetime'
    this.sortDi = 'ASC'
    this.onLoad = this.onLoad.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.onAction = this.onAction.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  handlePaginationChange = (e, { activePage }) => {
    this.onLoad(activePage)
  }

  onAction(id, status) {
    this.setState({ submiting: true })
    Utility.post(`${Settings.baseUrl}/transfer-payment/${id}/`, { remark: this.state.remark, status }, (s, d) => {
      alert(`${s ? '✅✅✅' : '❌❌❌'}${d.message || ''}${s ? '✅✅✅' : '❌❌❌'}`)
      this.onLoad()
    })
  }

  onLoad(page = 1) {
    this.setState({ loader_active: true, selected: {}, page: page, submiting: false })
    const search = this.state.search
    let q = Utility.jsonToQueryString({
      ...search,
      status: (search.status || []).join(','),
      start_date: search.start_date ? Utility.formatDate2(search.start_date) : '',
      end_date: search.end_date ? Utility.formatDate2(search.end_date) : '',
      page: page,
      page_size: this.state.page_size,
      columnKey: this.columnKey,
      sortDi: this.sortDi,
    })
    Utility.get(Settings.baseUrl + '/transfer-payment/?' + q, (status, data) => {
      if (status == true) {
        this.setState({
          items: this.setFieldValue(data.results),
          counter: data.count,
          loader_active: false,
        });
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
    this.onLoad()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  setFieldValue(items) {
    items.forEach((i) => {
      i.branch_name = i.lease.branch_name
      i.lease_number = `(${i.lease.lease_type}) ${i.lease.number}`
      i.amount = Utility.priceFormat(i.amount)
      i.account = `${i.bank_name}, ${i.account_name}, ${i.account_number || ''}`
      i.date = Utility.formatDate(i.created_datetime);
      i.time = Utility.formatTime(i.created_datetime);
      i.image_url = i.image_url ? i.image_url : i.image
    })
    return items;
  }

  onSortChange(columnKey, sortDir) {
    this.columnKey = columnKey
    this.sortDi = sortDir
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    }, () => {
      this.onLoad()
    });
  }

  render() {
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h3'>
                <div style={{ padding: '7px' }} className="pull-left"> <Icon name='payment' /> การชำระเงิน </div>
              </Header>

            </Form.Field>
          </Form.Group>
        </Form>
        <Segment color='black' >
          <Dimmer className={!this.state.loader_active ? '' : 'active'} inverted>
            <Loader content='Loading' inverted />
          </Dimmer>
          <Form className='attached fluid' size='small' >
            <Form.Group>
              <Form.Field width={3}>
                <label>สาขา</label>
                <DropDownBranch
                  fluid
                  all
                  size='small'
                  selection
                  onChange={(e, v) => {
                    this.setState({ search: { ...this.state.search, branch: v.value } })
                  }}
                  name="branch"
                  value={this.state.search.branch}
                />
              </Form.Field>
              <Form.Field width={3}>
                <label>เลขที่ใบขายฝาก</label>
                <Input
                  fluid
                  onChange={(e, v) => {
                    this.setState({ search: { ...this.state.search, lease_number: v.value } })
                  }}
                  name="number"
                  value={this.state.search.lease_number || ''}
                  placeholder='เลขที่ใบขายฝาก'
                />
              </Form.Field>
              <Form.Dropdown
                width={3}
                label='ประเภทใบขายฝาก'
                placeholder='ประเภทใบขายฝาก'
                search
                selection
                name="lease_type"
                value={this.state.search.lease_type || ''}
                options={[
                  { key: '', text: 'ทั้งหมด', value: '' },
                  { key: 'A', text: 'A', value: 'A' },
                  { key: 'R', text: 'R', value: 'R' },
                ]}
                onChange={(e, v) => {
                  this.setState({ search: { ...this.state.search, lease_type: v.value } })
                }}
              />
              <InputSearchCustomer
                label='ลูกค้า'
                width={3}
                placeholder='พิมพ์ ชื่อ หรือ รหัสลูกค้า'
                onChange={(e, v) => {
                  this.setState({ search: { ...this.state.search, customer: v.value } })
                }}
                name="customer_id"
                value={this.state.search.customer}
              />
              <Form.Field width={4}>
                <label>บัญชีรับเงิน</label>
                <Input
                  fluid
                  onChange={(e, v) => {
                    this.setState({ search: { ...this.state.search, account: v.value } })
                  }}
                  name="account"
                  value={this.state.search.account || ''}
                  placeholder='บัญชีรับเงิน'
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Dropdown
                width={6}
                label='สถานะ'
                placeholder='สถานะ'
                search
                selection
                name="status"
                value={this.state.search.status || ''}
                multiple
                options={[
                  { key: '1', text: 'รอตรวจสอบ', value: '1' },
                  { key: '3', text: 'สำเร็จ', value: '3' },
                  { key: '4', text: 'ข้อมูลไม่ถูกต้อง', value: '4' },
                ]}
                onChange={(e, v) => {
                  this.setState({ search: { ...this.state.search, status: v.value } })
                }}
              />
              <Form.Field width={3}>
                <label>จากวันที่</label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  value={this.state.search.start_date || null}
                  selected={this.state.search.start_date || null}
                  onChange={(date) => {
                    this.setState({
                      search: {
                        ...this.state.search,
                        start_date: date,
                        end_date: this.state.search.end_date < date ? date : this.state.search.end_date,
                      }
                    })
                  }}
                />
              </Form.Field>
              <Form.Field width={3}>
                <label>ถึงวันที่</label>
                <DatePicker
                  minDate={this.state.search.start_date || null}
                  dateFormat="DD/MM/YYYY"
                  value={this.state.search.end_date}
                  selected={this.state.search.end_date || null}
                  onChange={(date) => {
                    this.setState({ search: { ...this.state.search, end_date: date } })
                  }}
                />
              </Form.Field>
              <Form.Field >
                <label><br /></label>
                <Button id='btnSearch' onClick={() => this.onLoad()}><Icon name='search' />ค้นหา</Button>
                <Button id='btnAll' onClick={() => {
                  this.setState({
                    search: {
                      status: ['1'],
                    }
                  }, () => this.onLoad())
                }} ><Icon name='search' />รอตรวจสอบ</Button>
                <Button id='clearSearch' onClick={() => { this.setState({ search: {} }) }} >รีเซ็ต</Button>
              </Form.Field>
            </Form.Group>
          </Form>
          <div id='table_width'>
            <Table
              rowsCount={this.state.items.length}
              rowHeight={35}
              headerHeight={35}
              width={this.state.table_width}
              height={402}>
              <Column
                header={<Cell></Cell>}
                cell={
                  <OptionItemsCell data={this.state.items} onClickMenu={(e, item) => {
                    this.setState({ selected: item, msg_error: {}, remark: '', submiting: false })
                  }} />
                }
                width={50}
              />
              <Column
                header={<SortHeaderCell
                  onSortChange={this.onSortChange}
                  field='status'
                  sortDir={this.state.colSortDirs.status}>
                  สถานะ
                </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={120} data={this.state.items} field="status_display" />
                }
                width={120}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='id'
                    sortDir={this.state.colSortDirs.id}>
                    เลขที่รายการ
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={100} data={this.state.items} field="number" />
                }
                width={100}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='created_datetime'
                    sortDir={this.state.colSortDirs.created_datetime}>
                    วันที่
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={90} data={this.state.items} field="date" />
                }
                width={90}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='created_datetime'
                    sortDir={this.state.colSortDirs.created_datetime}>
                    เวลา
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={50} data={this.state.items} field="time" />
                }
                width={50}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='lease__branch'
                    sortDir={this.state.colSortDirs.lease__branch}>
                    สาขา
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={100} data={this.state.items} field="branch_name" />
                }
                width={100}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='lease__number'
                    sortDir={this.state.colSortDirs.lease__number}>
                    เลขที่ใบขายฝาก
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={110} data={this.state.items} field="lease_number" />
                }
                width={110}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='amount'
                    className='text-right'
                    sortDir={this.state.colSortDirs.amount}>
                    ยอดเงินต่อดอก
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top right' width={105} data={this.state.items} field="amount" textAlign='text-right' />
                }
                width={105}
              />
              <Column
                header={<Cell>บัญชีรับเงิน</Cell>}
                cell={
                  <ItemsCell position='top left' width={180} data={this.state.items} field="account" />
                }
                width={180}
              />
              <Column
                header={<Cell>อนุมัติโดย</Cell>}
                cell={
                  <ItemsCell position='top left' width={120} data={this.state.items} field="action_by" />
                }
                width={120}
              />
              <Column
                header={<Cell >หมายเหตุ</Cell>}
                cell={
                  <ItemsCell position='top left' width={300} data={this.state.items} field="remark" />
                }
                width={300}
              />
            </Table>
          </div>
          <br />
          <Form className='attached fluid' size='small' >
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={this.state.counter.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} />
              <Form.Field width={14}>
                {Math.ceil(this.state.counter / this.state.page_size) > 1 && <Pagination
                  style={{ marginTop: '24px' }}
                  floated='right'
                  size='tiny'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange}
                  totalPages={Math.ceil(this.state.counter / this.state.page_size)}
                />}
              </Form.Field>
            </Form.Group>
            <Form.Group>

            </Form.Group>
          </Form>
        </Segment>
        <Modal open={Boolean(this.state.selected.id)} size='small' /*dimmer='blurring'*/ >
          <Button
            id='btnClose'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ selected: {} })} />
          <Modal.Header>{this.state.selected && <Icon
            name='sticky note'
            color={this.state.selected.status === 3 ? 'green' : (this.state.selected.status === 4 ? 'red' : 'grey')}
          />}{this.state.selected.number || ''}</Modal.Header>
          <Modal.Content className='scrolling'>
            <Modal.Description>
              <Grid divided>
                <GridColumn width={6}>
                  <b>เลขที่รายการ</b>: {`${this.state.selected.number}`}<br />
                  <b>เลขที่ใบขายฝาก</b>: {`${this.state.selected.lease_number}`}<br />
                  <b>ยอดเงินต่อดอก</b>: {`${this.state.selected.amount}`}<br />
                  <b>บัญชีรับเงิน</b><br />
                  {this.state.selected.account_name && <> - {this.state.selected.account_name}<br /></>}
                  {this.state.selected.bank_name && <> - {this.state.selected.bank_name}<br /></>}
                  {this.state.selected.account_number && <> - {this.state.selected.account_number}<br /></>}
                  {this.state.selected.is_duplicate && <p style={{ color: 'red' }}>**สลิปซ้ำ**</p>}
                </GridColumn>
                <GridColumn width={10}>
                  <Image src={this.state.selected.image_url} size='massive' verticalAlign='middle' centered rounded />
                </GridColumn>
              </Grid>
            </Modal.Description>
          </Modal.Content>
          {this.state.selected.status === 1 && <Modal.Actions>
            <Form size='small'>
              <Form.Field error={this.state.msg_error.remark}>
                <label style={{ textAlign: 'start' }}>หมายเหตุ <MsgInput text={this.state.msg_error.remark || ''} /></label>
                <Input id='Input'
                  onChange={(e) => {
                    this.setState({ remark: e.target.value })
                  }}
                  value={this.state.remark}
                  maxLength={255}
                  placeholder='หมายเหตุ'
                />
              </Form.Field>
            </Form>
            <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
              <Button size='small' color='green' disabled={this.state.submiting}
                onClick={(e) => {
                  if (window.confirm(`✅✅✅ อนุมัติ ✅✅✅\nเลขที่รายการ: ${this.state.selected.number}\nเลขที่ใบขายฝาก: ${this.state.selected.lease_number}\nยอดเงิน: ${this.state.selected.amount}${this.state.remark && `\nหมายเหตุ: ${this.state.remark}`}`
                  )) {
                    this.onAction(this.state.selected.id, 3)
                  }
                }}
              >อนุมัติ</Button>
              <Button size='small' color='red' disabled={this.state.submiting}
                onClick={(e) => {
                  if (!this.state.remark) {
                    this.setState({ msg_error: { remark: '*จำเป็น' } })
                  } else {
                    if (window.confirm(`❌❌❌ ปฏิเสธ ❌❌❌\nเลขที่รายการ: ${this.state.selected.number}\nเลขที่ใบขายฝาก: ${this.state.selected.lease_number}\nยอดเงิน: ${this.state.selected.amount}${this.state.remark && `\nหมายเหตุ: ${this.state.remark}`}`
                    )) {
                      this.onAction(this.state.selected.id, 4)
                    }
                  }
                }}
              >ปฏิเสธ</Button>
            </div>
          </Modal.Actions>}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ branches, auth }) => {
  return ({
    auth,
    branches,
  })
}

export default connect(
  mapStateToProps
)(PaymentManage)
