/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Header, Button, Loader, Dimmer,
    Popup, Modal, Input,
    Pagination,
} from 'semantic-ui-react';
import moment from 'moment';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import Settings from '../../Settings';
import Utility from '../../Utility';
import LeaseForm from './LeaseForm';
import LesePOSPrintPreview from './LesePOSPrintPreview';
import LeaseReportModal from './LeaseReportModal';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import OptionItemsCell from './Cell/OptionItemsCell'
import ImportModal from './Modal/ImportModal'
import { hotkeys } from 'react-keyboard-shortcuts'
import LeaseSearch from './LeaseSearch'
import { connect } from 'react-redux'
import { lease_modal_edit, lease_modal_add_open, lease_modal_close, get_noti } from '../../actions'
import MsgInput from '../Error/MsgInput'
import config from '../../config';

function collect(props) {
    return { positon: props.positon };
}
class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { rowIndex, field, data, ...props } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (
            <ContextMenuTrigger id="menu_lese_list"
                holdToDisplay={1000}
                key={rowIndex}
                positon={rowIndex}
                collect={collect}>
                <Cell {...this.props} onClick={() => {
                }} >
                    <Popup
                        position={this.props.position}
                        content={data[rowIndex][field]}
                        trigger={
                            <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>{data[rowIndex][field]}</div>}
                    />
                </Cell>
            </ContextMenuTrigger>
        );
    }
}

var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
    return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
    constructor(props) {
        super(props);

        this._onSortChange = this._onSortChange.bind(this);
    }

    render() {
        var { onSortChange, sortDir, children, ...props } = this.props;
        return (
            <Cell {...props}
                className={this.props.className ? this.props.className : ''}>
                <a onClick={this._onSortChange}>
                    {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
                </a>
            </Cell>
        );
    }

    _onSortChange(e) {
        e.preventDefault();
        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.field,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    }
}

class LeasePOS extends Component {

    constructor(props) {
        super(props);
        this.data_search = null
        this.state = {
            message_error_hidden: true,
            items: [],
            invoice_detail: '',
            total_item: 0,
            open: false,
            modal_data: {},
            is_enabled: 1,
            start_date: moment(),
            end_date: moment(),
            msg_error: {},
            colSortDirs: {
                'number': 'ASC',
            },
            totalPages: 1,
            page: 1,
            count: 0,
            item_report: [],
        }

        this.columnKey = 'number'
        this.sortDi = 'ASC'
        this.onSortChange = this.onSortChange.bind(this)
        this.product_code_all = [];
        this.product_name_all = [];
        this.categories = [];
        this.product_types = [];
        this.onContextMenu = this.onContextMenu.bind(this);
        this.handleClick = this.handleClick.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.searchReport = this.searchReport.bind(this)
    }

    onSortChange(columnKey, sortDir) {

        this.columnKey = columnKey
        this.sortDi = sortDir
        this.handleSearch(undefined, this.state.page)

        this.setState({
            colSortDirs: {
                [columnKey]: sortDir,
            },
        });
    }

    handleSearch(data, page) {
        if (data == undefined) {
            data = this.data_search
        }
        delete data.start_date
        delete data.end_date
        if (data.radio == 2 || data.radio == 3) {
            if (data.in_date_start)
                data.start_date = Utility.formatDate2(data.in_date_start)
            if (data.in_date_end)
                data.end_date = Utility.formatDate2(data.in_date_end)
        }
        this.data_search = data
        if (data.start_end_date)
            data.start_end_date = Utility.formatDate2(data.start_end_date)
        if (data.end_end_date)
            data.end_end_date = Utility.formatDate2(data.end_end_date)

        if (data.start_out_date)
            data.start_out_date = Utility.formatDate2(data.start_out_date)
        if (data.end_out_date)
            data.end_out_date = Utility.formatDate2(data.end_out_date)

        if (data.start_close_date)
            data.start_close_date = Utility.formatDate2(data.start_close_date)
        if (data.end_close_date)
            data.end_close_date = Utility.formatDate2(data.end_close_date)

        if (data.due_date)
            data.due_date = Utility.formatDate2(data.due_date)

        // if (data.radio == 10) {
        //     data.overdue = 1
        //     data.due_date = Utility.formatDate2(moment())
        // }

        // delete data.radio

        if (data.number) {
            data.number = data.number.trim()
        }
        if (data.phone) {
            data.phone = data.phone.trim()
        }
        if (data.phone == '')
            delete data.phone
        if (data.citizen_id) {
            data.citizen_id = data.citizen_id.trim()
        }
        // delete data.in_date_start
        // delete data.in_date_end
        const { branch, role } = this.props.auth

        data['branch'] = branch.id
        data['page'] = page ? page : this.state.page

        this.setState({
            page: page ? page : this.state.page,
            search_open: false,
            search_loading: true,
            loader_active: true
        })

        let s = Utility.jsonToQueryString({
            ...data,
            type: config.rd ? 'R' : data.type || '',
        })
        var lease = Utility.getFetch(`${Settings.baseUrl}/lease_data/?columnKey=${this.columnKey}&sortDi=${this.sortDi}&${s}`);
        Promise.all([lease]).then((values) => {
            var items = this.setFieldValue(values[0].results.lease, values[0].results.customer, values[0].results.branch, values[0].results.lease_product);
            // calculate
            let total_interest = 0;
            let total = 0;
            let weight = 0;
            let lease_in = '';
            let j = 0;
            let limit = items.length
            for (let i in items) {
                total_interest += parseFloat(items[i].total_interest)
                total += parseFloat(items[i].amount)
                weight += parseFloat(items[i].weight)
                lease_in += items[i].id
                if (j < limit - 1)
                    lease_in += ","
                j++
            }

            this.setState({
                totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
                items: items,
                count: values[0].count,
                loader_active: false,
                total: total,
                lease_in: lease_in,
                total_interest: total_interest,
                search_loading: false,
            });
        });
    }

    searchReport() {
        let data = this.data_search
        if (data.start_end_date)
            data.start_end_date = Utility.formatDate2(data.start_end_date)
        if (data.end_end_date)
            data.end_end_date = Utility.formatDate2(data.end_end_date)

        if (data.start_out_date)
            data.start_out_date = Utility.formatDate2(data.start_out_date)
        if (data.end_out_date)
            data.end_out_date = Utility.formatDate2(data.end_out_date)

        if (data.start_close_date)
            data.start_close_date = Utility.formatDate2(data.start_close_date)
        if (data.end_close_date)
            data.end_close_date = Utility.formatDate2(data.end_close_date)

        if (data.due_date)
            data.due_date = Utility.formatDate2(data.due_date)

        if (data.number) {
            data.number = data.number.trim()
        }
        if (data.phone) {
            data.phone = data.phone.trim()
        }
        if (data.phone == '')
            delete data.phone
        if (data.citizen_id) {
            data.citizen_id = data.citizen_id.trim()
        }
        const { branch, role } = this.props.auth

        data['branch'] = branch.id

        this.setState({
            search_loading: true,
            loader_active: true,
            item_report: [],
        })
        data.page = 1
        data.page_size = 100000
        let s = Utility.jsonToQueryString({
            ...data,
            type: config.rd ? 'R' : data.type || '',
        })
        var lease = Utility.getFetch(`${Settings.baseUrl}/lease_data/?columnKey=${this.columnKey}&sortDi=${this.sortDi}&${s}`);
        Promise.all([lease]).then((values) => {
            var items = this.setFieldValue(values[0].results.lease, values[0].results.customer, values[0].results.branch, values[0].results.lease_product);
            this.setState({
                item_report: items,
                loader_active: false,
                search_loading: false,
            });
        });
    }

    componentDidMount() {

        const { branch } = this.props.auth
        this.setState({
            total_item: 0,
        })

        var s = {
            branch: branch.id,
            is_enabled: 1
        }
        if (this.state.end_date != null && this.state.end_date != '') {
            s['end_date'] = Utility.formatDate2(this.state.end_date)
        }
        if (this.state.start_date != null && this.state.start_date != '') {
            s['start_date'] = Utility.formatDate2(this.state.start_date)
        }

        Utility.get(Settings.baseUrl + "/branch_setting/?branch=" + branch.id, (e, d) => {
            this.setState({
                setting: d[0]
            })
        });

        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        let elHeight = document.getElementById('client_width')
        this.setState({ table_width: elHeight.clientWidth });
    };

    handlePaginationChange(e, { activePage }) {
        this.handleSearch(undefined, activePage)
    }

    setFieldValue(item, customers, branchs, lease_products) {

        for (let i = 0; i < item.length; i++) {
            const customer = customers.find(k => k.id === item[i].customer)
            const branch = branchs.find(k => k.id === item[i].branch)
            let lease_product = []
            for (let j = 0; j < lease_products.length; j++) {
                if (lease_products[j].lease == item[i].id) {
                    lease_product.push(lease_products[j])
                }
            }

            item[i].branch_name = branch.name
            item[i].customer_name = customer.name
            item[i].lease_prodcut = lease_product
            item[i].amount_display = Utility.priceFormat(item[i].amount)
            item[i].status_title = Settings.status_lease[item[i].status]
            let amount = parseInt(item[i].amount)
            let interest = parseInt(item[i].interest)
            item[i].interest_m = Utility.priceFormat(amount * (interest / 100))
            item[i].enabled_title = item[i].is_enabled == 0 ? 'ยกเลิกบิลแล้ว' : ''
            item[i].start_date_display = Utility.formatDate(item[i].start_date)
            item[i].end_date_display = Utility.formatDate(item[i].end_date)
            item[i].close_date_display = item[i].status == 3 ? Utility.formatDate(item[i].close_date) : ''
            item[i].out_date_display = item[i].status == 4 ? Utility.formatDate(item[i].out_date) : ''
            item[i].total_interest = Utility.priceFormat(item[i].total_interest)

            //this.branches
        }
        return item;
    }

    clearFormSearch() {
        this.setState({
            branches_id: '',
            vendors_id: '',
            start_date: moment(),
            end_date: moment(),
            is_clear_stock: false,
            is_clear_bill: false
        })
    }

    onContextMenu(e, data) {
        e.preventDefault();
        this.props.handleOpenLease(data.id)
    }

    handleClick(e, data) {
        let item = this.state.items[data.positon];
        if (data.action == 'edit') {
            if (item.is_enabled == 0) {
                alert('ไม่สามารถแก้ไขข้อมูลได้เนื่องจากยกเลิกบิลแล้ว')
                return;
            }
            this.loadLeaseDetail(item.id, item)
        } else if (data.action == 'delete') { // delete bill
            if (item.is_enabled == 1) {
                alert('ไม่สามารถลบได้ กรุณายกเลิกบิลก่อน')
                return;
            } else {
                if (window.confirm('ยืนยันลบรายการนี้'))
                    this.submitDelete(item);
            }
        } else if (data.action == 'cancel_1') {
            if (item.is_enabled == 0) {
                alert('รายการนี้ถูกยกเลิกแล้ว')
                return;
            } else if (item.status == 1 || item.status == 2) {
                if (window.confirm('ยืนยันยกเลิกรายการขายฝาก')) {
                    this.submitCancel1(item);
                    // this.setState({modal_delete:true,item_id:item})
                }
            } else {
                alert('ไม่สามารถยกเลิกรายการขายฝากได้เนื่องจากรายการนี้ไม่อยู่ในสถานะใหม่ / ต่อดอก')
            }
        } else if (data.action == 'cancel_2') {

            if (item.is_enabled == 0) {
                alert('รายการนี้ถูกยกเลิกแล้ว')
                return;
            } else if (item.status == 3) {
                if (window.confirm('ยืนยันยกเลิกไถ่คืน'))
                    this.submitCancel2(item);
            } else {
                alert('ไม่สามารถยกเลิกไถ่คืนได้เนื่องจากรายการนี้ไม่อยู่ในสถานะไถ่คืน')
            }
        } else if (data.action == 'cancel_3') {
            if (item.is_enabled == 0) {
                alert('รายการนี้ถูกยกเลิกแล้ว')
                return;
            } else if (item.status == 4) {
                if (window.confirm('ยืนยันยกเลิกคัดออก'))
                    this.submitCancel3(item);
            } else {
                alert('ไม่สามารถยกเลิกคัดออกได้เนื่องจากรายการนี้ไม่อยู่ในสถานะคัดออก');
            }
        }
    }

    async submitDelete(item) { // ลบรายการขายฝาก

        let url = Settings.baseUrl + "/lease/" + item.id + "/"
        const res = await Utility.deleteAsync(url);
        if (res.status_code == 200 || res.status_code == 204) {
            alert('ลบเรียบร้อย');
            this.handleSearch(this.data_search, 1)
        } else if (res.status_code == 403) {
            alert('คุณไม่สามารถลบรายการได้');
        } else
            alert(res.data.error)
    }

    async submitCancel1(item) { // ยกเลิกขายฝาก
        let description = prompt("กรุณาระบุหมายเหตุ");
        if (description == null || description == "") {
            return;
        }
        const data_ = { 'description': description }
        let url = Settings.baseUrl + "/lease/" + item.id + "/cancel/"
        const res = await Utility.postAsync(url, data_);
        if (res.status_code == 200) {
            alert('ยกเลิกเรียบร้อย');
            this.handleSearch(this.data_search, 1)
        } else if (res.status_code == 403) {
            alert('คุณไม่สามารถลบรายการได้');
        } else {
            alert(res.data.error)
        }
        this.setState({
            modal_delete: false,
            delete_id: '',
            description_reject: ''
        })

        this.setState({ msg_error: {} })
    }

    async submitCancel2(item) { // ยกเลิกไถ่คืน
        let description = prompt("กรุณาระบุหมายเหตุ");
        if (description == null || description == "") {
            return;
        }
        const data_ = { 'description': description }
        let url = Settings.baseUrl + "/lease/" + item.id + "/cancel_redeem/"
        const res = await Utility.postAsync(url, data_);

        if (res.status_code == 200) {
            alert('ยกเลิกเรียบร้อย');
            this.handleSearch(this.data_search, 1)
        } else if (res.status_code == 403) {
            alert('คุณไม่สามารถลบรายการได้');
        } else
            alert(res.data.error)
    }

    async submitCancel3(item) { // ยกเลิกคัดออก
        let description = prompt("กรุณาระบุหมายเหตุ");
        if (description == null || description == "") {
            return;
        }
        const data_ = { 'description': description }
        let url = Settings.baseUrl + "/lease/" + item.id + "/cancel_out/"
        const res = await Utility.postAsync(url, data_);

        if (res.status_code == 200) {
            if (res.data.error != undefined) {
                alert(res.data.error)
            } else {
                alert('ยกเลิกเรียบร้อย');
                this.props.getNoti(this.props.auth.branch)
                this.handleSearch(this.data_search, 1)
            }



        } else if (res.status_code == 403) {
            alert('คุณไม่สามารถลบรายการได้');
        } else
            alert(res.data.error)
    }

    handleInputChange(e, v) {
        this.setState({
            [v.name]: v.value
        })
    }

    loadLeaseDetail(id, data) {
        this.props.handleOpenLease(id)
    }

    render() {
        const items = this.state.items;
        const { lease_modal } = this.props
        const { branch, role } = this.props.auth
        return (
            <div>
                <ImportModal open={this.state.import_open} onClose={() => this.setState({ import_open: false })} />
                <Form >
                    <Form.Group>
                        <Form.Field width={6}>
                            <Header floated='left' as='h3'>รายการขายฝาก</Header>
                        </Form.Field>
                        <Form.Field width={10}>
                            {role == 'A' || role == 'S' ?
                                <Button id='btnImport' content='Import' size='small' onClick={() => this.setState({ import_open: true })} floated='right' icon='file alternate outline' labelPosition='left' type='button' primary />
                                : ''}
                            <Button id='btnCreateLease' content='สร้างรายการขายฝาก (F1)' size='small' onClick={() => {
                                this.props.handleAddLease()
                            }} floated='right' icon='plus' labelPosition='left' type='button' primary />
                        </Form.Field>
                    </Form.Group>
                </Form>
                {role == 'A' || role == 'M' || role == 'S' ? <ContextMenu id="menu_lese_list">
                    <MenuItem data={{ action: 'edit' }} onClick={this.handleClick}>
                        แก้ไข
                    </MenuItem>
                    <MenuItem data={{ action: 'delete' }} onClick={this.handleClick}>
                        ลบ
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem data={{ action: 'cancel_1' }} onClick={this.handleClick}>
                        ยกเลิกรายการขายฝาก
                    </MenuItem>
                    <MenuItem data={{ action: 'cancel_2' }} onClick={this.handleClick}>
                        ยกเลิกการไถ่คืน
                    </MenuItem>
                    <MenuItem data={{ action: 'cancel_3' }} onClick={this.handleClick}>
                        ยกเลิกการคัดออก
                    </MenuItem>
                </ContextMenu> : ''}

                {lease_modal.is_open && this.state.setting &&
                    <LeaseForm
                        setting={this.state.setting}
                        onReset={() => {
                            setTimeout(() => {
                                this.props.handleAddLease()
                            }, 400)

                        }}
                        onCreate={(d) => {
                            this.setState({
                                modal_data: d,
                                modal_action: 'edit'
                            })
                        }}
                        onClose={() => {
                            this.props.leaseModalClose()

                            if (this.data_search)
                                this.handleSearch(this.data_search, 1)
                        }}
                    />
                }

                <div className='relative'>

                    {this.state.open_print ? <LesePOSPrintPreview
                        data={
                            {
                                start_date: this.state.start_date,
                                end_date: this.state.end_date,
                                total_amount: this.state.total_amount,
                                total_weight: this.state.total_weight
                            }
                        }
                        items={this.state.item_report}
                        loader_active={this.state.loader_active}
                        onClose={() => {
                            this.setState({ open_print: false });
                        }}
                    /> : ''}
                    <div id='client_width'>
                        <LeaseSearch onSubmit={this.handleSearch} />
                        <div className='relative'>
                            <Dimmer active={this.state.loader_active} inverted>
                                <Loader inverted content='Loading' />
                            </Dimmer>
                            <Table
                                rowsCount={items.length}
                                rowHeight={35}
                                headerHeight={30}
                                width={this.state.table_width}
                                height={402}>
                                <Column
                                    header={<Cell></Cell>}
                                    cell={
                                        <OptionItemsCell action={role == 'A' || role == 'M' || role == 'S' ? ['edit', 'delete'] : ['edit']} data={items} field="number" onClickMenu={this.handleClick} />
                                    }
                                    width={70}
                                />

                                <Column
                                    header={<SortHeaderCell
                                        onSortChange={this.onSortChange}
                                        field='number'
                                        sortDir={this.state.colSortDirs.number}>
                                        เลขที่ใบขายฝาก
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} id='leaseID' data={items} field="number" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>สาขา</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={items} field="branch_name" />
                                    }
                                    width={120}
                                />
                                {!config.rd && <Column

                                    header={<Cell>ประเภท</Cell>}
                                    cell={
                                        <ItemsCell position='top left' textAlign='text-center' width={50} data={items} field="lease_type" />
                                    }
                                    width={50}
                                />}
                                <Column
                                    header={<SortHeaderCell
                                        onSortChange={this.onSortChange}
                                        field='customer__name'
                                        sortDir={this.state.colSortDirs.customer__name}>
                                        ชื่อลูกค้า
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} id='cusName' data={items} field="customer_name" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        className='text-right'
                                        onSortChange={this.onSortChange}
                                        field='interest'
                                        sortDir={this.state.colSortDirs.interest}>
                                        อัตรา ดบ.
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top right' width={100} data={items} field="interest" textAlign="text-right" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        className='text-right'
                                        onSortChange={this.onSortChange}
                                        field='month'
                                        sortDir={this.state.colSortDirs.month}>
                                        จน. เดือน
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top right' width={100} data={items} field="month" textAlign="text-right" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        className='text-right'
                                        onSortChange={this.onSortChange}
                                        field='amount'
                                        sortDir={this.state.colSortDirs.amount}>
                                        จน. เงิน
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top right' width={100} data={items} field="amount_display" textAlign="text-right" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        className='text-right'
                                        onSortChange={this.onSortChange}
                                        field='amount'
                                        sortDir={this.state.colSortDirs.amount}>
                                        ดบ./เดือน
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top right' width={100} data={items} field="interest_m" textAlign="text-right" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        className='text-right'
                                        onSortChange={this.onSortChange}
                                        field='total_interest'
                                        sortDir={this.state.colSortDirs.total_interest}>
                                        รวม ดบ.รับ
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top right' width={100} data={items} field="total_interest" textAlign="text-right" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        onSortChange={this.onSortChange}
                                        field='start_date'
                                        sortDir={this.state.colSortDirs.start_date}>
                                        วันที่นำเข้า
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={100} data={items} field="start_date_display" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        onSortChange={this.onSortChange}
                                        field='end_date'
                                        sortDir={this.state.colSortDirs.end_date}>
                                        วันครบกำหนด
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={100} data={items} field="end_date_display" />
                                    }
                                    width={100}
                                />
                                <Column
                                    header={
                                        this.data_search == undefined ?
                                            <Cell>วันที่ไถ่คืน</Cell> :
                                            ['12', '8', '9'].indexOf(this.data_search.radio) < 0 ? <Cell>วันที่ไถ่คืน</Cell>
                                                :
                                                < SortHeaderCell
                                                    onSortChange={this.onSortChange}
                                                    field='close_date'
                                                    sortDir={this.state.colSortDirs.close_date}>
                                                    วันที่ไถ่คืน
                                                </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={130} data={items} field="close_date_display" />
                                    }
                                    width={130}
                                />
                                <Column
                                    header={
                                        this.data_search == undefined ?
                                            <Cell>วันที่คัดออก</Cell> :
                                            ['13', '10', '6'].indexOf(this.data_search.radio) < 0 ? <Cell>วันที่คัดออก</Cell>
                                                :
                                                <SortHeaderCell
                                                    onSortChange={this.onSortChange}
                                                    field='out_date'
                                                    sortDir={this.state.colSortDirs.out_date}>
                                                    วันที่คัดออก
                                                </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={130} data={items} field="out_date_display" />
                                    }
                                    width={130}
                                />
                                <Column
                                    header={<SortHeaderCell
                                        onSortChange={this.onSortChange}
                                        field='status'
                                        sortDir={this.state.colSortDirs.status}>
                                        สถานะ
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={100} data={items} field="status_title" />
                                    }
                                    width={100}
                                />

                                <Column
                                    header={<SortHeaderCell
                                        onSortChange={this.onSortChange}
                                        field='is_enabled'
                                        sortDir={this.state.colSortDirs.is_enabled}>
                                        สถานะยกเลิก
                                    </SortHeaderCell>}
                                    cell={
                                        <ItemsCell position='top left' width={100} data={items} field="enabled_title" />
                                    }
                                    width={100}
                                />
                            </Table>
                        </div>
                    </div>
                </div>
                <br />
                <Form size='small' >
                    <Form.Group>
                        <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.count)} />
                        <Form.Field width={16}>
                            <br />
                            <LeaseReportModal
                                data={this.data_search}
                                branch={this.props.auth.branch.id}
                            />
                            <Button id='print' content='พิมพ์ (F8)' size='small' onClick={() => {
                                this.searchReport()
                                this.setState({ open_print: true });
                            }} floated='right' icon='print' labelPosition='left' type='button' primary />
                            {this.state.totalPages > 1 && <Pagination
                                floated='right'
                                size='tiny'
                                activePage={this.state.page}
                                onPageChange={this.handlePaginationChange.bind(this)}
                                totalPages={this.state.totalPages}
                            />}
                        </Form.Field>
                    </Form.Group>
                </Form>

                <Modal open={this.state.modal_delete} size='tiny' /*dimmer='blurring'*/ >
                    <Button
                        id='btnClose'
                        circular
                        icon='close'
                        basic
                        floated='right'
                        name=''
                        onClick={() => {
                            this.setState({
                                modal_delete: false,
                                delete_id: '',
                                description_reject: ''
                            })
                            this.setState({ msg_error: {} })
                        }}
                    />
                    <Modal.Header>{'แจ้งเตือน'}</Modal.Header>
                    <Modal.Content className='scrolling'>
                        <Modal.Description>
                            <Form size='small'>
                                <Form.Field error={this.state.msg_error.description_reject != null} >
                                    <label>หมายเหตุ* <MsgInput text={this.state.msg_error.description_reject} /> </label>
                                    <Input id='Input'
                                        onChange={(e) => {
                                            this.setState({ description_reject: e.target.value })
                                        }}
                                        value={this.state.description_reject}
                                        maxLength={255} />
                                </Form.Field>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button id='btnSavePass' size='small' primary
                            onClick={(e) => {
                                if (this.state.description_reject == '' || this.state.description_reject == undefined) {
                                    this.setState({ msg_error: { description_reject: "ต้องระบุหมายเหตุ" } })
                                } else {
                                    this.submitCancel1(this.state.item_id);
                                }
                                // if (this.state.description_reject == '') {
                                //   this.setState({ msg_error: { description_reject: "ต้องระบุหมายเหตุ" } })
                                // } else {
                                //   this.deleteledger(this.state.description_reject)
                                // }
                            }}
                        >ยืนยัน</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }


    hot_keys = {
        'f1': {
            priority: 3,
            handler: (event) => {
                event.preventDefault()
                this.props.handleAddLease()
            }
        },
        'f8': {
            priority: 3,
            handler: (event) => {
                event.preventDefault()
                this.searchReport()
                this.setState({ open_print: true });

            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    getNoti: branch => get_noti(dispatch, branch),
    handleAddLease: () => {
        dispatch(lease_modal_add_open())
    },
    leaseModalClose: () => {
        dispatch(lease_modal_close())
    },
    handleOpenLease: (id) => {
        lease_modal_edit(dispatch, id)
    },
})

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches,
        lease_modal: state.lease_modal
    })
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(hotkeys(LeasePOS))