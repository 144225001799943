/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
   Segment, Button, Loader, Dimmer, Modal, Table, Image, Checkbox
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../Utility';
import Settings from '../../Settings';
import logo from '../../logo.png';
import signature from '../../signature.png'
import moment from 'moment';
import { connect } from 'react-redux'

class DisplayPrint extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const {branch} =this.props
        const divStyle = {
            color: '#111',
            'font-size': '12px',
            'line-height': '20px',
            'font-family': 'monospace',
            'border-collapse': 'collapse',
            border: '1px solid black'
        };
        const textRight = {
            'text-align': 'right'
        }
        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let invoice_info = Settings.invoice_info
        let tax_number = this.props.tax_number;
        let total = 0
        let total_weight = 0
        let total_cost = 0
        let total_amount = 0
        let total_unit = 0

        const bill_info = this.props.bill_info
        const bill_items = this.props.bill_items
        // let sell_cost = bill_info.gold_price.gold_ornaments_buy / 15.2
        let sell_cost = bill_info.gold_price.gold_ornaments_buy / 15.16

        let is_96 = true
        let sales_force = 0

        let total_pre_vat = 0
        let buy_today = 0
        let total_diff = 0
        let total_vat = 0
        
        let total_net = bill_info.kind == "SE" ? bill_info.total : parseFloat(bill_info.sell) + parseFloat(bill_info.total)
        
        let is_free= bill_info.kind == "XC"? Number(bill_info.total) == 0? true : false : false
        let fee_sum = 0

        let kind_cate = ""
        let kind_cate_name = ""
        let type_sale = 1
        let fee = 0
        let exchange = 0
        let exchange_diff = 0
        let kind = ""
        let fee_ex = 0
        let diff_exchange = parseFloat(bill_info.exchange) + parseFloat(bill_info.exchange_diff)
        let is_ta = true
        let bill_it = []
        for (let i in bill_items) {
            const item = JSON.parse(JSON.stringify(bill_items[i]))
            // bill_it.push(bill_items[i])
            const o = bill_it.find(k=>k.bill.bill_number === item.bill.bill_number && k.product.code === item.product.code && k.sell === item.sell)
            if (o === undefined){
                // bill_it.push(bill_items[i])
                bill_it.push(item)
                }
                else{
                    const index = bill_it.indexOf(o)
                    bill_it[index].amount +=1
                    // bill_it[index].weight =Number(bill_it[index].weight)  +Number(item.weight)
                    // bill_it[index].weight_b =Number(bill_it[index].weight_b) + Number(item.weight_b)
                    // bill_it[index].sell =Number(bill_it[index].sell) + Number(item.sell)
                }
            const product = bill_items[i].product
            
            const weight = parseFloat(item.weight)
            const sell = parseFloat(item.sell)
            const gold_ornaments_buy = bill_items[i].bill.gold_price.gold_ornaments_buy
            exchange = parseFloat(bill_items[i].bill.exchange)
            exchange_diff = parseFloat(bill_items[i].bill.exchange_diff)

            kind_cate = item.category.code
            kind_cate_name = item.category.name

            kind = item.bill.kind

            if (item.kind == "SE") {
                fee_sum += parseFloat(item.fee)
                total_weight += weight
                if (product.type_sale == 1 && item.category.code == "96") {
                    // buy_today += (parseFloat(gold_ornaments_buy)/15.16)*parseFloat(item.weight)
                    if(product.type_weight == 1) {
                        buy_today += (parseFloat(gold_ornaments_buy)/15.16)*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)
                    } else {
                        buy_today += (parseFloat(gold_ornaments_buy)/15.16)*(parseFloat(item.weight))
                    }
                    // buy_today = (parseFloat(gold_ornaments_buy)/15.16)*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)
                    
                } 
                else if (product.type_sale == 1 && item.category.code == "90") {
                    // buy_today += (parseFloat(gold_ornaments_buy)/15.16)*parseFloat(item.weight) * 0.9
                    if(product.type_weight == 1) {
                        buy_today += (parseFloat(gold_ornaments_buy)/15.16)*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)*0.9
                    } else {
                        buy_today += (parseFloat(gold_ornaments_buy)/15.16)*(parseFloat(item.weight))*0.9
                    }
                    
                    // buy_today = (parseFloat(gold_ornaments_buy)/15.16)*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)*0.9
                } 
                else if(item.category.code == "965"){
                    // buy_today += parseFloat(item.sell) - parseFloat(item.fee)
                    fee += parseFloat(item.fee)
                }
                else { 
                    type_sale = 2
                    //งานชิ้น
                }
                if(bill_info.kind == "XC"){
                    // diff_exchange += parseFloat(item.fee)
                    fee_ex += parseFloat(item.fee)
                    is_ta = item.product.kind.code == "TA"?true:false
                    if(item.category.code == "965"){

                        total_net = bill_info.sell
                    }
                }
            }
        }

        if(bill_info.kind == "XC"){
            if(kind_cate != "965") {
                total_net -= (fee_ex)
            }
            
        }
        
        if(kind_cate == "965"){
            // total_diff = ((fee) * 100/107).toFixed(2)
            total_diff = 0
        } else {
            if(kind_cate == "90"){
                sell_cost = sell_cost * 0.9
            }
            
            // let receive = bill_info.kind == "SE" ? (parseFloat(total_net) - buy_today) :diff_exchange + fee_ex
            let receive = (parseFloat(total_net) - buy_today)
            total_diff = ( receive* 100/107).toFixed(2)
            
            
        }
        total_vat = (total_diff * 0.07).toFixed(2)
       
        // let pre_vat = kind == "SE" ? parseFloat(total_net) - parseFloat(total_vat) : kind_cate == "965" ?parseFloat(buy_today) + parseFloat(total_diff) : parseFloat(buy_today) + parseFloat(total_diff) + parseFloat(total_vat)
        // let pre_vat = kind == "SE" ? parseFloat(total_net) - parseFloat(total_vat) : kind_cate == "965" ?parseFloat(buy_today) + parseFloat(total_diff) : parseFloat(buy_today) + parseFloat(total_diff)
        if(kind_cate === "965") {
            let pre_vat = parseFloat(total_net)
            total_pre_vat = (pre_vat).toFixed(2)

        } else {
            let pre_vat = parseFloat(total_net) - parseFloat(total_vat)
            total_pre_vat = (pre_vat).toFixed(2)
        }
        
        
        
        if(kind == "XC" && fee_sum+exchange+exchange_diff == 0 && is_ta){
            total_pre_vat = 0
            buy_today = 0
            total_diff = 0
            total_vat = 0
            total_net = 0
        } else if(kind == "XC" && fee_sum+exchange+exchange_diff == 0 && !is_ta){
            total_net = parseFloat(bill_info.sell)
            total_diff = ((total_net - buy_today)*(100/107)).toFixed(2)
            total_vat = (total_diff * 0.07).toFixed(2)
            total_pre_vat = total_net - total_vat 
        }
        

        return (<div >
            <div id='paperA4-portrait' style={{ 'page-break-before': 'always' }}>
                <Table basic className='nobarder' id='table-to-xls' style={divStyle}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan='3' style={textCenter}>
                                <Image src={logo} width={70} height={70} />
                            </Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}><b>{invoice_info.name}</b></Table.Cell>
                            <Table.Cell colSpan='3'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}> <b>ใบกำกับภาษี/ใบเสร็จรับเงิน</b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='3' style={textLeft}>{invoice_info.address1}</Table.Cell>
                            <Table.Cell colSpan='3'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}> <b>Tax Invoice</b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='3' style={textLeft}>{invoice_info.address2}</Table.Cell>
                            <Table.Cell colSpan='3'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}>{this.props.subtitle}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='7'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}> เลขประจำตัวผู้เสียภาษีอากร {invoice_info.citizen_id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='5' style={textLeft}><div style={textU}>สาขาที่ออกใบกำกับภาษี สาขาที่ {this.props.tax_code}</div></Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}> วันที่ </Table.Cell>
                            <Table.Cell style={textCenter}>  </Table.Cell>
                            <Table.Cell colSpan='2' style={textLeft}> {Utility.formatDate(bill_info.bill_date)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='5' style={textLeft}></Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}> เลขที่ใบกำกับภาษี </Table.Cell>
                            <Table.Cell style={textCenter}>  </Table.Cell>
                            <Table.Cell colSpan='2' style={textLeft}> {tax_number}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='5' style={textLeft}></Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}> วันครบกำหนด </Table.Cell>
                            <Table.Cell style={textCenter}>  </Table.Cell>
                            <Table.Cell colSpan='2' style={textLeft}> {Utility.formatDate(moment())}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell colSpan='2' style={textRight}>นามลูกค้า</Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.name}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>ทองคำแท่งซื้อเข้า บาทละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{(Utility.priceFormat(bill_info.gold_price.gold_bar_buy))}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2' style={textRight}>ที่อยู่</Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.address}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>ทองคำแท่งขายออก บาทละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{(Utility.priceFormat(bill_info.gold_price.gold_bar_sell))}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2' style={textRight}></Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.district} {bill_info.customer.city} {bill_info.customer.province} {bill_info.customer.postal_code}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{kind_cate!="965"?"ทองรูปพรรณ "+kind_cate_name+" รับชื้อคืน บาทละ":"ทองรูปพรรณรับซื้อคืน บาทละ"}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>  {(Utility.priceFormat(bill_info.gold_price.gold_ornaments_buy))}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2' style={textRight}>เลขประจำตัวผู้เสียภาษีอากร</Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.citizen_id}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{kind_cate!="965"?"ทองรูปพรรณ "+kind_cate_name+" รับชื้อคืน กรัมละ":"ทองรูปพรรณรับซื้อคืน กรัมละ"}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>  {Utility.priceFormat(sell_cost)}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        {type_sale == 1 ? 
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>ลำดับ</Table.Cell>
                            <Table.Cell colSpan='4' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>รายละเอียด</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>น้ำหนัก</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>จำนวน</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>หน่วยละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}>จำนวนเงิน</Table.Cell>
                        </Table.Row>:
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>ลำดับ</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>รายละเอียด</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>จำนวน</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>หน่วยละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}>จำนวนเงิน</Table.Cell>
                        </Table.Row>
                    }
                    {type_sale == 1 ? 
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Item</Table.Cell>
                            <Table.Cell colSpan='4' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Descripttion</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>(กรัม)</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Qunantity</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Unit</Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}>Amount</Table.Cell>
                    </Table.Row> :
                    <Table.Row>
                    <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Item</Table.Cell>
                    <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Descripttion</Table.Cell>
                    <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Qunantity</Table.Cell>
                    <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Unit</Table.Cell>
                    <Table.Cell colSpan='2' style={textCenter}>Amount</Table.Cell>
            </Table.Row>
                    }
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        {bill_it.map((item, i) => {
                            let unit = 0
                            if(item.product.type_sale == 2){
                                unit = (parseFloat(item.product.price_tag))
                            } 
                            else if(item.product.type_sale == 1 && item.category.code == "96"){
                                // unit = ((parseFloat(item.bill.gold_price.gold_ornaments_buy)/15.16 )* parseFloat(item.weight))
                                // if(item.product.type_weight === 1) {
                                //     unit = ((parseFloat(item.bill.gold_price.gold_ornaments_buy)/15.16 )*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16))
                                // } else {
                                //     unit = ((parseFloat(item.bill.gold_price.gold_ornaments_buy)/15.16 )*parseFloat(item.weight))
                                // }
                                unit = parseFloat(item.sell)
                                // unit = bill_info.kind === "SE" ? parseFloat(item.sell) : parseFloat(bill_info.sell) + parseFloat(bill_info.total) - parseFloat(item.fee)
                            }
                            else if(item.product.type_sale == 1 && item.category.code == "90"){
                                // unit = ((parseFloat(item.bill.gold_price.gold_ornaments_buy)/15.16 )* parseFloat(item.weight) * 0.9)
                                if(item.product.type_weight === 1) {
                                    unit = ((parseFloat(item.bill.gold_price.gold_ornaments_buy)/15.16 )*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)*0.9)
                                } else {
                                    unit = ((parseFloat(item.bill.gold_price.gold_ornaments_buy)/15.16 )*(parseFloat(item.weight))*0.9)
                                }
                               
                                
                            } else {
                                // unit = (parseFloat(item.sell) - parseFloat(item.fee) )
                                unit = parseFloat(item.sell)
                            }
                            total_cost += parseFloat(item.cost)
                            total_amount += parseFloat(item.amount)
                            total_unit += unit*parseFloat(item.amount)
                            return (
                            type_sale == 1 ?
                            <Table.Row key={i}>
                                <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>{i + 1}</Table.Cell>
                                <Table.Cell colSpan='4' style={{ 'border-right': '1px solid black', 'text-align': 'left' }}>{item.product && item.product.name}</Table.Cell>
                                <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{Utility.weightFormat(item.weight)}</Table.Cell>
                                <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{item.amount}</Table.Cell>
                                <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{Utility.priceFormat(unit)}</Table.Cell>
                                <Table.Cell colSpan='2' style={textRight}>{Utility.priceFormat(unit*parseFloat(item.amount))}</Table.Cell>
                            </Table.Row> : 
                            <Table.Row key={i}>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>{i + 1}</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'left' }}>{item.product && item.product.name}</Table.Cell>
                            {/* <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{Utility.weightFormat(item.weight)}</Table.Cell> */}
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{item.amount}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{Utility.priceFormat(unit)}</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{Utility.priceFormat(unit*parseFloat(item.amount))}</Table.Cell>
                        </Table.Row>
                        )
                        })}
                        {bill_it.length < 3 ? 
                        type_sale == 1 ?
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='4' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                        </Table.Row>:
                        <Table.Row>
                        <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                        <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                        <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                        <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                        <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                    </Table.Row> : ''}
                    {type_sale == 1 ? 
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='4' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                    </Table.Row> : <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                    </Table.Row>}
                    {type_sale == 1?
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='4' style={{ 'border-right': '1px solid black', 'text-align': 'left' }}>{Utility.arabicNumberToText((total_unit.toFixed(2)).toString())}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                    </Table.Row>:<Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'left' }}>{Utility.arabicNumberToText((total_unit.toFixed(2)).toString())}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                    </Table.Row> }

                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell style={textLeft}>&nbsp;&nbsp;หมายเหตุ</Table.Cell>
                            <Table.Cell colSpan='4' style={textLeft}>{kind_cate == "965" ? 'บริษัทได้ยื่นแบบภ.พ.01.3 เพื่อยกเว้นภาษีมูลค่าเพิ่มทองคำแท่งเรียบร้อยแล้ว' : 'แก้ไขใบส่งของกรุณาติดต่อภายใน 7วัน หากพ้นกำหนดจะไม่รับผิดชอบใดๆ ทั้งสิ้น'}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'left' }}></Table.Cell>
                        <Table.Cell style={textLeft}>รวมสินค้า   </Table.Cell>
                        <Table.Cell style={textRight} >{Utility.numberFormat(total_amount)}</Table.Cell>
                            <Table.Cell style={textLeft} >รายการ น้ำหนัก </Table.Cell>
                            <Table.Cell style={textRight}>{Utility.weightFormat(total_weight)} กรัม</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textLeft}>&nbsp;&nbsp;ชำระโดย</Table.Cell>
                            <Table.Cell style={textRight}><Checkbox checked={bill_info.payment == 'CS' || bill_info.payment == 'CC'} /></Table.Cell>
                            <Table.Cell style={textLeft}>เงินสด</Table.Cell>
                            <Table.Cell style={textRight}><Checkbox checked={bill_info.payment == 'CD' || bill_info.payment == 'CC'} /></Table.Cell>
                            <Table.Cell style={textLeft}>บัตรเครดิต</Table.Cell>
                            <Table.Cell style={textLeft} style={{ 'border-right': '1px solid black', 'text-align': 'left' }}></Table.Cell>
                            <Table.Cell style={textRight} colSpan='3'>ราคาสินค้ารวมค่ากำเหน็จก่อนภาษี</Table.Cell>
                            <Table.Cell style={textRight} colSpan='1'>{Utility.priceFormat(total_pre_vat)}</Table.Cell>
                        </Table.Row>
                        {type_sale == 1 ?
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black' }} colSpan='6'></Table.Cell>
                            <Table.Cell style={textRight} colSpan='3'>{kind_cate == "965" ?"ราคารับซื้อทองประจำวัน": "หักราคาทองรูปพรรณรับซื้อคืน"}</Table.Cell>
                            <Table.Cell style={textRight} colSpan='1'>{Utility.priceFormat(buy_today)}</Table.Cell>
                        </Table.Row> : ""}
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black' }} colSpan='6'></Table.Cell>
                            <Table.Cell style={textRight} colSpan='3'>จำนวนส่วนต่าง</Table.Cell>
                            <Table.Cell style={textRight} colSpan='1'>{Utility.priceFormat(total_diff)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black' }} colSpan='6'></Table.Cell>
                            <Table.Cell style={textRight} colSpan='3'>ภาษีมูลค่าเพิ่ม 7%</Table.Cell>
                            <Table.Cell style={textRight} colSpan='1'>{Utility.priceFormat(total_vat)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ background: '#eee', 'text-align': 'left', 'border': '1px solid black' }} colSpan='6'>&nbsp;&nbsp;เอกสารออกเป็นชุด</Table.Cell>
                            <Table.Cell style={textRight} colSpan='3'>รวมเงินรับสุทธิ</Table.Cell>
                            <Table.Cell style={textRight} colSpan='1'>{Utility.priceFormat(total_net)}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell style={textLeft} colSpan='10'>&nbsp;&nbsp;ได้รับสินค้าตามรายการในสภาพเรียบร้อย</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textLeft} colSpan='10'>&nbsp;RECEIVED THE ABOVE IN GOODS AND CONDITION</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='2'></Table.Cell>
                            <Table.Cell style={textCenter} colSpan='2'></Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'></Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'>{invoice_info.name}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='2'><br />.................................</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='2'><br />.................................</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'><br />.................................</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'><Image src={signature} style={{ margin: 'auto', width: '40%', display: 'block' }} /><p>.................................</p></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='2'>ผู้รับสินค้า/Received By</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='2'>ผู้ส่งสินค้า/Delivered By</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'>ผู้ขาย /  ผู้รับเงิน</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'>ผู้มีอำนาจลงนาม/Authorized Signature</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='2'>วันที่/Date……/……/…….</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='2'>วันที่/Date……/……/…….</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'>วันที่/Date   {Utility.formatDate(moment())}</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='3'></Table.Cell>
                        </Table.Row>
                    </Table.Body>

                </Table>
            </div>
        </div>)
    }
}

class ProductDetailPrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            bill_info: {},
            bill_items: []
        }
    }

    componentDidMount() {


        Utility.get(Settings.baseUrl + "/branch_setting/?is_enabled=1&branch=" + this.props.auth.branch.id, (e, resJson) => {
            this.b = resJson;
            this.setState({ tax_code: resJson[0].tax_code })
          });

        var bills = Utility.getFetch(Settings.baseUrl + "/bills/" + this.props.bill_id + "/");
        var bill_items = Utility.getFetch(Settings.baseUrl + "/bill/" + this.props.bill_id + "/items/");
        // var tax = Utility.getFetch(Settings.baseUrl + "/bill/" + this.props.bill_id + "/tax/");
        Promise.all([bills, bill_items]).then((values) => {
            let tax_number = values[0].bill_number
            let items = []
            let items2 = []
            for (let i in values[1]) {
                let item = values[1][i]
                // check if same product
                if(item.kind == "SE"){
                items.push(item)
                // const o = items2.find(k=>k.bill.bill_number === item.bill.bill_number && k.product.code === item.product.code)
                // if (o === undefined){
                //     items2.push(item)
                // }
                // else{
                    // const index = items2.indexOf(o)
                    // items2[index].amount +=1
                //     items2[index].weight =Number(items2[index].weight)  +Number(item.weight)
                //     items2[index].weight_b =Number(items2[index].weight_b) + Number(item.weight_b)
                //     items2[index].sell =Number(items2[index].sell) + Number(item.sell)
                // }
            }
            }
            this.setState({
                loader: false,
                tax_number: tax_number,
                bill_info: values[0],
                bill_items: items,
            })
        });
    }
    render() {

        const {branch} = this.props.auth

        let title = 'สรุปรายการซื้อ-ขายทอง';
        let filename = 'bills-slip'



        return (<div>
            <Modal id='modalPreview' open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    {this.state.loader ? <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment> : <div id='view-print'>
                    <DisplayPrint subtitle='ต้นฉบับ'branch={branch} bill_info={this.state.bill_info} bill_items={this.state.bill_items} tax_number={this.state.tax_number} tax_code={this.state.tax_code}/>
                    <DisplayPrint subtitle='สำเนา' branch={branch} bill_info={this.state.bill_info} bill_items={this.state.bill_items} tax_number={this.state.tax_number} tax_code={this.state.tax_code}/>
                    </div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="ui primary button small"
                        table="table-to-xls"
                        filename={filename}
                        sheet={title}
                        buttonText="Download as XLS" >
                    </ReactHTMLTableToExcel>

                    <Button primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 12px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif;width: 100%;height: 100%; margin: 0;padding: 0;">');
                        mywindow.document.write('<style>');
                        mywindow.document.write('@page {size: A4;margin: 5mm;} ');
                        mywindow.document.write('</style>');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(()=>{
                            mywindow.close();
                        },10000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}


const mapStateToProps = state =>{
    return ({
      auth: state.auth,
    })
  }

  export default connect(
    mapStateToProps,
  )(ProductDetailPrintPreview)