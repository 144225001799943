/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';

import {
  Form,
  Header,
  Segment,
  Icon,
  Menu,
  Grid,
} from 'semantic-ui-react';

import Branch from './Branch';
import Staffs from './Staffs';
import Users from './Users';
import Customer from './Customer';
import Categories from './Categories';
import ProductType from './ProductType';
import Pattern from './Pattern'
import ProductsList from './Product/ProductsList';
import Vendor from './Vendor';
import BankCard from './BankCard';
import BankAccount from './BankAccount';
import Bank from './Bank';
import LedgerCategory from './LedgerCategory';
import SettingBranch from './SettingBranch';
import { connect } from 'react-redux'
import Notifications from './Notifications';
import Careers from './Careers';
import Workplaces from './Workplaces';
import SettingScore from './SettingScore'
import AccountOnline from './AccountOnline';
import AccountStaff from './AccountStaff';

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.location.pathname
    }

    this.handleItemClick = this.handleItemClick.bind(this);
  }


  state = {
    activeItem: 'branch'
  };

  componentDidMount() {
    let elHeight = document.getElementById('content-body')
    this.setState({
      clientWidth: elHeight.clientWidth
    })
  }


  handleItemClick(e, { name, to }) {
    this.setState({
      activeItem: to
    });
  }

  render() {
    const { role } = this.props.auth
    return (
      <Router>
        <div>
          <Form size='small'>
            <Form.Group>
              <Form.Field width={6}>
                <Header floated='left' as='h4'><Icon name='folder outline' /> แฟ้มข้อมูล</Header>
              </Form.Field>
            </Form.Group>
          </Form>
          <Grid>
            <Grid.Column width={3}>
              <Segment color='black'>
                <Menu secondary vertical className='menu-left'>
                  {role == 'A' || role == 'S' ?
                    <Menu.Item
                      id='branch'
                      name='branch'
                      active={this.state.activeItem == '/files/branches'}
                      onClick={this.handleItemClick}
                      as={Link}
                      to='/files/branches'
                    >
                      สาขา
                    </Menu.Item> : ''}
                  <Menu.Item
                    id='staff'
                    name='staff'
                    active={this.state.activeItem == '/files/staffs'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/staffs'
                  >
                    พนักงาน
                  </Menu.Item>
                  <Menu.Item
                    id='users'
                    name='users'
                    active={this.state.activeItem == '/files/users'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/users'
                  >
                    ผู้ใช้งาน
                  </Menu.Item>
                  <Menu.Item
                    id='category'
                    name='category'
                    active={this.state.activeItem == '/files/categories'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/categories'
                  >
                    กลุ่มสินค้า
                  </Menu.Item>
                  <Menu.Item
                    id='productType'
                    name='productType'
                    active={this.state.activeItem == '/files/types'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/types'
                  >
                    ประเภทสินค้า
                  </Menu.Item>
                  <Menu.Item
                    id='productType'
                    name='productType'
                    active={this.state.activeItem == '/files/pattern'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/pattern'
                  >
                    ลายทอง
                  </Menu.Item>
                  <Menu.Item
                    id='product'
                    name='product'
                    active={this.state.activeItem == '/files/products'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/products'
                  >
                    สินค้า
                  </Menu.Item>
                  <Menu.Item
                    id='vendors'
                    name='vendors'
                    active={this.state.activeItem == '/files/vendors'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/vendors'
                  >
                    โรงงาน/ร้านส่ง
                  </Menu.Item>
                  <Menu.Item
                    id='bank'
                    name='bank'
                    active={this.state.activeItem == '/files/bank'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/bank'
                  >
                    ธนาคาร
                  </Menu.Item>
                  <Menu.Item
                    id='bank_card'
                    name='bank_card'
                    active={this.state.activeItem == '/files/bank_card'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/bank_card'
                  >
                    ชนิดบัตร
                  </Menu.Item>
                  <Menu.Item
                    id='bank_account'
                    name='bank_account'
                    active={this.state.activeItem == '/files/bank_account'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/bank_account'
                  >
                    บัญชีธนาคาร
                  </Menu.Item>
                  <Menu.Item
                    id='account-online'
                    name='account-online'
                    active={this.state.activeItem == '/files/account-online'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/account-online'
                  >
                    บัญชีออนไลน์
                  </Menu.Item>
                  <Menu.Item
                    id='account-staff'
                    name='account-staff'
                    active={this.state.activeItem == '/files/account-staff'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/account-staff'
                  >
                    บัญชีพนักงาน
                  </Menu.Item>
                  <Menu.Item
                    id='ledger_category'
                    name='ledger_category'
                    active={this.state.activeItem == '/files/ledger_category'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/ledger_category'
                  >
                    รายการรับ-จ่าย
                  </Menu.Item>
                  <Menu.Item
                    id='careers'
                    name='careers'
                    active={this.state.activeItem == '/files/careers'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/careers'
                  >
                    อาชีพ
                  </Menu.Item>
                  <Menu.Item
                    id='workplaces'
                    name='careers'
                    active={this.state.activeItem == '/files/workplaces'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/workplaces'
                  >
                    สถานที่ทำงาน
                  </Menu.Item>
                  <Menu.Item
                    id='setting_branch'
                    name='setting_branch'
                    active={this.state.activeItem == '/files/notifications'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/notifications'
                  >
                    ผู้รับแจ้งเตือน
                  </Menu.Item>
                  <Menu.Item
                    id='setting_score'
                    name='setting_score'
                    active={this.state.activeItem == '/files/setting_score'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/setting_score'
                  >
                    ตั้งค่าการคำนวณแต้ม
                  </Menu.Item>
                  <Menu.Item
                    id='setting_branch'
                    name='setting_branch'
                    active={this.state.activeItem == '/files/setting_branch'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/files/setting_branch'
                  >
                    ตั้งค่าตามสาขา
                  </Menu.Item>
                </Menu>
              </Segment>
            </Grid.Column>
            <Grid.Column stretched width={13} className="content-body" >
              <Segment color='black'>
                <div id="content-body">
                  <Route path="/files/branches" component={() => (<Branch clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/staffs" component={() => (<Staffs clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/users" component={() => (<Users clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/categories" component={() => (<Categories clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/types" component={() => (<ProductType clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/pattern" component={() => (<Pattern clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/products" component={() => (<ProductsList clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/customers" component={() => (<Customer clientWidth={this.state.clientWidth} items={[]} />)} />
                  <Route path="/files/vendors" component={() => (<Vendor clientWidth={this.state.clientWidth} items={[]} />)} />
                  <Route path="/files/bank" component={() => (<Bank clientWidth={this.state.clientWidth} items={[]} />)} />
                  <Route path="/files/bank_card" component={() => (<BankCard clientWidth={this.state.clientWidth} items={[]} />)} />
                  <Route path="/files/bank_account" component={() => (<BankAccount clientWidth={this.state.clientWidth} items={[]} />)} />
                  <Route path="/files/ledger_category" component={() => (<LedgerCategory clientWidth={this.state.clientWidth} items={[]} />)} />
                  <Route path="/files/workplaces" component={() => (<Workplaces clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/careers" component={() => (<Careers clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/notifications" component={() => (<Notifications clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/setting_score" component={SettingScore} />
                  <Route path="/files/setting_branch" component={SettingBranch} />
                  <Route path="/files/account-online" component={() => (<AccountOnline clientWidth={this.state.clientWidth} />)} />
                  <Route path="/files/account-staff" component={() => (<AccountStaff clientWidth={this.state.clientWidth} />)} />
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      </Router >
    );
  }
}
const mapStateToProps = state => {

  return ({
    auth: state.auth
  })
}
export default connect(
  mapStateToProps
)(Files)
