/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import Utility from '../../Utility';
import Settings from '../../Settings';
import PaymentInterest from './PaymentInterest';
import ItemsCell from '../Widget/ItemsCell'

import {
    Form,
    Input,
    Button,
    Dimmer,
    Loader,
    Icon,
    Popup
} from 'semantic-ui-react';
import moment from 'moment';
import config from '../../config';

class ItemsCellIcon extends Component {
    render() {
        const { rowIndex, data, roles } = this.props;
        var recordDate = new Date(data[rowIndex].record_date);
        var todaysDate = new Date();
        return (<Cell onClick={(e) => {
        }} >
            <center style={{ padding: '4px' }}>
                {
                    rowIndex == 0 && recordDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0) ?
                        <Popup 
                            trigger={
                                data[rowIndex].payment == "MOBILE" && roles ?
                                    <Icon disabled name='window close outline' />
                                :
                                    <a onClick={(e) => { this.props.onClick(e, data[rowIndex]) }} >
                                        <Icon name='window close outline' />
                                    </a>
                            } 
                            position="top center"
                        >
                            POS : ให้สิทธิ์ สามารถยกเลิกรายการดอกเบี้ยรับได้ภายในวันที่ทำรายการ<br/>
                            MOBILE : ให้สิทธิ์ สามารถยกเลิกรายการดอกเบี้ยรับได้ภายในวันที่ทำรายการ และจะต้องเป็นผู้จัดการเขตเท่านั้น
                        </Popup>
                        : ''
                }
            </center>
        </Cell>
        );
    }
}

class LeaseInterest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeItem: window.location.pathname,
            table_width: 600,
            table_hiegh: 150,
            lease_product: [],
            loading: false,
            setting: this.props.setting,
            lease_interest: [],
            total_interest: 0,
            pay_interest_open: props.sub_action == 'interest' || props.sub_action == 'redeem'
        }
        this.handelSubmit = this.handelSubmit.bind(this)
        this.handelDeleteItem = this.handelDeleteItem.bind(this)

    }

    componentDidMount() {

        if (this.props.data.id) {
            let lease_interest = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.data.id + '/interest/?is_enabled=1');
            this.setState({
                loading: true
            })

            Promise.all([lease_interest]).then((values) => {
                let total_interest = 0;
                for (let i in values[0]) {
                    total_interest += parseFloat(values[0][i].total_receive)
                }
                this.setState({
                    lease_interest: this.setFieldValue(values[0]),
                    total_interest: total_interest,
                    loading: false
                })
            });
        }
        setTimeout(() => {

            let elHeight = document.getElementById('w_content')
            if (elHeight)
                this.setState({ w_content: elHeight.clientWidth });
        }, 1000)

    }

    setFieldValue(list) {
        let j = list.length;
        for (let i in list) {
            list[i].no = j--
            list[i].pay_date_display = list[i].pay_date ? Utility.formatDate(list[i].pay_date) : ''
            list[i].interest_date_display = Utility.formatDate(list[i].interest_date)

            list[i].total_pay = Utility.priceFormat(list[i].total_receive)
            list[i].total = Utility.priceFormat(list[i].total)
            list[i].time = Utility.formatTime(list[i].record_date)
            if (list[i].ledger) {
                list[i].payment_title = Settings.payment[list[i].ledger.payment]
                list[i].branch_name = list[i].ledger.branch.name
                list[i].card_code = list[i].ledger.card_code
                list[i].card_code = list[i].ledger.card_code
            }
        }

        return list
    }

    handelSubmit(saving) {
        this.props.onSave(saving)
        this.setState({ pay_interest_open: false })
        this.componentDidMount();
    }

    handelDeleteItem(e, item) {
        if (this.props.data.status == 3 || this.props.data.status == 4)
            return
        let description = prompt("กรุณาระบุหมายเหตุ");
        if (description == null || description == "") {
            return;
        }

        const data_ = { 'description': description }
        if (window.confirm('ยืนยันลบ!')) {
            let r = Utility.patchAsync(Settings.baseUrl + '/lease/' + this.props.data.id + '/interest/' + item.id + '/', data_)
            Promise.all([r]).then((values) => {
                if (values[0].data.status) {
                    this.componentDidMount();
                    this.props.update_enddate();
                } else if (values[0].status_code == 403) {
                    alert('ไม่สามารถลบได้ สิทธิ์ superadmin/admin/manager เท่านั้น')
                } else {
                    alert(values[0].data.error)
                }
            })
        }
    }

    render() {
        const items = this.state.lease_interest
        return (<div id="content-body2">
            {this.state.pay_interest_open && <PaymentInterest open={this.state.open} action={this.props.sub_action} onSave={this.handelSubmit} onClose={() => { this.setState({ pay_interest_open: false }) }} data={this.props.data} setting={this.state.setting} />}

            <Form>
                <Form.Group>
                    <Form.Field width={16}>
                    {(this.props.data.status < 3 && (moment().format("YYYY-MM-DD") > this.props.overdue_end_date && this.props.role_overdue_end_date))&&<span style={{color:'red'}}>สินค้าหลุดจำนำ เนื่องจากลูกค้าชำระดอกเบี้ยล่าช้าเกินกว่าวันที่กำหนด {this.props.data.overdue_end_date} วัน</span>}
                    { this.props.loadingQueue &&<span style={{color:'red'}}>ระบบกำลังดำเนินการต่อดอก / ไถ่คืน</span>}    
                    {this.props.data.id ? <Button id='btnAdd' loading={this.props.loadingQueue} disabled={this.props.loadingQueue || this.props.data.status > 2 || (moment().format("YYYY-MM-DD") > this.props.overdue_end_date && this.props.role_overdue_end_date)} floated='right' primary content='เพิ่ม' size='mini' type='button' onClick={() => { this.setState({ pay_interest_open: true, open: true }) }} /> : ''}
                    </Form.Field>
                </Form.Group>
            </Form>
            <div id="w_content" className='relative'>
                <Dimmer active={this.state.loading} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
                <Table
                    rowsCount={items.length}
                    rowHeight={30}
                    headerHeight={30}
                    width={this.state.table_width}
                    height={this.state.table_hiegh}>
                    <Column
                        header={<Cell></Cell>}
                        cell={
                            <ItemsCellIcon data={items} roles={this.props.role_overdue_end_date} onClick={this.handelDeleteItem} />
                        }
                        width={50}
                    />
                    <Column
                        header={<Cell className='text-center'>ครั้งที่</Cell>}
                        cell={
                            <ItemsCell position='top right' width={50} data={items} field="no" textAlign='text-center' />
                        }
                        width={50}
                    />
                    <Column
                        header={<Cell className='text-right'>ดอกเบี้ยคำนวณ</Cell>}
                        cell={
                            <ItemsCell position='top right' width={180} data={items} field="total" textAlign='text-right' />
                        }
                        width={180}
                    />
                    <Column
                        header={<Cell className='text-right'>ดอกเบี้ยรับ</Cell>}
                        cell={
                            <ItemsCell position='top right' width={180} data={items} field="total_pay" textAlign='text-right' />
                        }
                        width={180}
                    />
                    <Column
                        header={<Cell>คิดดอกเบี้ยถึงวันที่</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="interest_date_display" />
                        }
                        width={120}
                    />
                    <Column
                        header={<Cell>วันที่จ่าย</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="pay_date_display" />
                        }
                        width={120}
                    />
                    <Column
                        header={<Cell>เวลา</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="time" />
                        }
                        width={120}
                    />
                    <Column
                        header={<Cell>สาขา</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="branch_name" />
                        }
                        width={120}
                    />
                    {!config.rd && <Column
                        header={<Cell>ประเภทการชำระ</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="payment_title" />
                        }
                        width={120}
                    />}
                    {!config.rd && <Column
                        header={<Cell>ช่องทางชำระ</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="payment" />
                        }
                        width={120}
                    />}
                    {!config.rd && <Column
                        header={<Cell>หมายเหตุ</Cell>}
                        cell={
                            <ItemsCell position='top left' width={120} data={items} field="description" />
                        }
                        width={120}
                    />}
                </Table>
            </div>
            <div style={{ marginTop: '5px' }}>
                <Form size='small'>
                    <Form.Field inline>
                        <label> รวมดอกเบี้ยรับ</label>
                        <Input
                            readOnly
                            value={Utility.priceFormat(this.state.total_interest)}
                            className="text-right" />
                    </Form.Field>
                </Form>
            </div>
        </div>
        );
    }
}

export default LeaseInterest;
