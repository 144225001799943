/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Checkbox, Divider
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import config from '../../../config';

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            is_all: true,
            is_sort: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                payment: 'POS'
            },
            items: [],
            lease_interest: [],
            item_td: [],
            branches_all: [],
            payment: [
                {
                    'text': 'POS',
                    'value': 'POS'
                },
                {
                    'text': 'MOBILE',
                    'value': 'MOBILE'
                }
            ]
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches_all/?is_enabled=1');

        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                branches_all: branches,
                get_branches_all: values[0],
            });

        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            is_all: true,
            is_sort: true,
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                payment: 'POS'
            },
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.ledger_category = 7
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if (!this.state.is_sort)
            search.sort = 'date'
        else
            search.sort = 'number'
        let fromData = Utility.jsonToQueryString({...search, payment: config.rd ? '' : search.payment, type: config.rd ? 'R' : ''})
        let url = Settings.baseReportUrl + "/lease/interest/report/?" + fromData

        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d),
                    item_td: this.setFieldValuelist(d)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items.interest) {
            let item = _items.interest[i]
            let products = _items.products.filter((p) => p.lease == item.lease)
            item.lease = _items.lease.find((l) => l.id == item.lease)
            item.ledger = _items.ledger.find((l) => l.id == item.ledger)
            item.customer = _items.customer.find((c) => c.id == item.lease.customer)
            for (let [index, product] of products.entries()) {
                if (index === 0) {
                    items.push({
                        'detail': item,
                        'product': product,
                        'head': true,
                        'number': Number(i) + 1,
                    })
                }
                else {
                    items.push({
                        'detail': item,
                        'product': product,
                        'head': false,
                        'number': Number(i) + 1,
                    })
                }
            }
        }
        return items
    }
    setFieldValuelist(_items) {

        let items = []
        for (let i in _items.interest_td) {
            let item = _items.interest_td[i]
            let products = _items.products_td.filter((p) => p.lease == item.lease)
            item.lease = _items.lease_td.find((l) => l.id == item.lease)
            item.customer = _items.customer_td.find((c) => c.id == item.lease.customer)
            for (let [index, product] of products.entries()) {
                if (index === 0) {
                    items.push({
                        'barnch': item.lease.branch,
                        'detail': item,
                        'product': product,
                        'head': true,
                        'number': Number(i) + 1,
                    })
                }
                else {
                    items.push({
                        'barnch': item.lease.branch,
                        'detail': item,
                        'product': product,
                        'head': false,
                        'number': Number(i) + 1,
                    })
                }
            }
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data
        let titletd = 'รายงานยอดดอกเบี้ย(ต่อดอกข้ามสาขา)';
        let title = 'รายงานยอดดอกเบี้ย(ต่อดอก)';
        let filename = 'lease-interest-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        const descriptionWidth = {
            width: "20%",
            'word-break': 'break-word'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }


        let weight = 0
        let amount = 0
        let total = 0
        let total_receive = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานยอดดอกเบี้ย(ต่อดอก)</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            {!config.rd && <Form.Field>
                                <label>ช่องทางชำระ</label>
                                <Dropdown selection options={this.state.payment} value={this.state.search.payment} name='payment' onChange={this.handlerInput} />
                            </Form.Field>}
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Divider />
                            <Checkbox radio label='เรียงลำดับตามเลขที่ขายฝาก' checked={this.state.is_sort} onChange={(e, v) => { this.setState({ is_sort: true }) }} />
                            <br />
                            <Checkbox radio label='เรียงลำดับตามวันที่,เลขที่ขายฝาก' checked={!this.state.is_sort} onChange={(e, v) => { this.setState({ is_sort: false }) }} />
                            <br />
                            <br />
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.HeaderCell className='table-header-border' colSpan={config.rd ? '9' : '11'}><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell className='table-header-border' colSpan={config.rd ? '9' : '11'}><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell className='table-header-border' colSpan={config.rd ? '9' : '11'}><center id='date'>ตั้งแต่วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>วันที่ต่อ</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textLeft}>รายการสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textRight}>น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textRight}>เงินต้น</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textRight}>ดอกเบี้ยคำนวณ</Table.HeaderCell>
                                            <Table.HeaderCell className='table-header-border-t-b' style={textRight}>ดอกเบี้ย</Table.HeaderCell>
                                            {!config.rd && <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>ช่องทางชำระ</Table.HeaderCell>}
                                            {!config.rd && <Table.HeaderCell className='table-header-border-t-b' style={{ width: "20%", textAlign: 'center' }}>หมายเหตุ</Table.HeaderCell>}
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                            const branchName = Utility.getObject(this.state.get_branches_all, item.detail.ledger.branch)
                                            if (item.head === true) {
                                                weight += parseFloat(item.detail.lease.weight)
                                                amount += parseInt(item.detail.lease.amount)
                                                total_receive += parseInt(item.detail.total_receive)
                                                total += parseInt(item.detail.total)
                                            }
                                            if (item.head === true) {
                                                return (<Table.Row>
                                                    <Table.Cell style={textCenter}>{item.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{item.detail.lease.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.record_date)}</Table.Cell>
                                                    <Table.Cell style={textLeft} width={3}>{item.detail.customer.name}</Table.Cell>
                                                    <Table.Cell style={textLeft} width={3}>{item.product.product_name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.product.weight)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.lease.amount)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.total)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.total_receive)}</Table.Cell>
                                                    {!config.rd && <Table.Cell style={textCenter}>{item.detail.payment}</Table.Cell>}
                                                    {!config.rd && <Table.Cell style={{...textLeft, ...descriptionWidth}}>{item.detail.description}{item.detail.description !== '' && <br />}{branchName && branchName.name !== branch_name ? `ต่อดอก-${branchName.name}` : ''}</Table.Cell>}
                                                </Table.Row>)
                                            } else {
                                                return (<Table.Row>
                                                    <Table.Cell colSpan='4' style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}>{item.product.product_name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.product.weight)}</Table.Cell>
                                                    <Table.Cell colSpan={config.rd ? '3' : '5'} style={textLeft}></Table.Cell>
                                                </Table.Row>)
                                            }
                                        })}

                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='5'><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='weight'>{Utility.weightFormat(weight)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='amount'>{Utility.priceFormat(amount)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='total'>{Utility.priceFormat(total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='total_receive'>{Utility.weightFormat(total_receive)}</b></div></Table.Cell>
                                            {!config.rd && <Table.Cell style={textLeft} colSpan='2'></Table.Cell>}
                                        </Table.Row>
                                    </Table.Body>
                                    {this.state.branches_all.map((branch_list, index) => {
                                        const data = this.state.item_td.find((item_index) => {
                                            return branch_list.value == item_index.barnch
                                        })
                                        if (data !== undefined) {
                                            let countindex = 0
                                            weight = 0
                                            amount = 0
                                            total_receive = 0
                                            total = 0
                                            return (

                                                <Table.Body>
                                                    <Table.Row >
                                                        <Table.HeaderCell className='table-row-hide' colSpan='11'></Table.HeaderCell>
                                                    </Table.Row>
                                                    <Table.Row >
                                                        <Table.HeaderCell className='table-header-border' colSpan='11'><center>{titletd}</center></Table.HeaderCell>
                                                    </Table.Row>
                                                    <Table.Row >
                                                        <Table.HeaderCell className='table-header-border' colSpan='11'><center id='branch'>สาขา : {this.state.branches_all[index].text}</center></Table.HeaderCell>
                                                    </Table.Row>
                                                    <Table.Row >
                                                        <Table.HeaderCell className='table-header-border' colSpan='11'><center id='date'>ตั้งแต่วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>ลำดับ</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>วันที่ต่อ</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textLeft}>รายการสินค้า</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textRight}>น้ำหนัก</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textRight}>เงินต้น</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textRight}>ดอกเบี้ยคำนวณ</Table.HeaderCell>
                                                        <Table.HeaderCell className='table-header-border-t-b' style={textRight}>ดอกเบี้ย</Table.HeaderCell>
                                                        {!config.rd && <Table.HeaderCell className='table-header-border-t-b' style={textCenter}>ช่องทางชำระ</Table.HeaderCell>}
                                                        {!config.rd && <Table.HeaderCell className='table-header-border-t-b' style={{ width: "20%", textAlign: 'center' }}>หมายเหตุ</Table.HeaderCell>}
                                                    </Table.Row>

                                                    {this.state.item_td.map((item, i) => {
                                                        if (item.barnch == this.state.branches_all[index].value) {
                                                            if (item.head === true) {
                                                                weight += parseFloat(item.detail.lease.weight)
                                                                amount += parseInt(item.detail.lease.amount)
                                                                total_receive += parseInt(item.detail.total_receive)
                                                                total += parseInt(item.detail.total)
                                                                countindex += 1
                                                                return (

                                                                    <Table.Row>
                                                                        <Table.Cell style={textCenter}>{countindex}</Table.Cell>
                                                                        <Table.Cell style={textCenter}>{item.detail.lease.number}</Table.Cell>
                                                                        <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.record_date)}</Table.Cell>
                                                                        <Table.Cell style={textLeft} width={3}>{item.detail.customer.name}</Table.Cell>
                                                                        <Table.Cell style={textLeft} width={3}>{item.product.product_name}</Table.Cell>
                                                                        <Table.Cell style={textRight}>{Utility.weightFormat(item.product.weight)}</Table.Cell>
                                                                        <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.lease.amount)}</Table.Cell>
                                                                        <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.total)}</Table.Cell>
                                                                        <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.total_receive)}</Table.Cell>
                                                                        {!config.rd && <Table.Cell style={textLeft}>{item.detail.payment}</Table.Cell>}
                                                                        {!config.rd && <Table.Cell style={{...textLeft, ...descriptionWidth}}>{item.detail.description}</Table.Cell>}
                                                                    </Table.Row>)
                                                            } else {
                                                                return (<Table.Row>
                                                                    <Table.Cell colSpan='4' style={textLeft}></Table.Cell>
                                                                    <Table.Cell style={textLeft}>{item.product.product_name}</Table.Cell>
                                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.product.weight)}</Table.Cell>
                                                                    <Table.Cell colSpan={config.rd ? '3': '5'} style={textLeft}></Table.Cell>
                                                                </Table.Row>)
                                                            }
                                                        }
                                                    })}

                                                    <Table.Row>
                                                        <Table.Cell style={textRight} colSpan='5'><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b id='weight'>{Utility.weightFormat(weight)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b id='amount'>{Utility.priceFormat(amount)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b id='total'>{Utility.priceFormat(total)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b id='total_receive'>{Utility.weightFormat(total_receive)}</b></div></Table.Cell>
                                                        {!config.rd && <Table.Cell style={textLeft} colSpan='2'></Table.Cell>}
                                                    </Table.Row>
                                                </Table.Body>)
                                        }
                                        else {
                                            return ''
                                        }
                                    })
                                    }
                                </Table>
                                {/* ############################################# */}
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)