/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import ReactDOM from "react-dom";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  Form,
  Input,
  Dropdown,
  Button,
  Segment,
  Icon,
  Label,
  Modal,
  Grid,
  Checkbox,
  FormField,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import "react-dates/lib/css/_datepicker.css";
import Utility from "../../Utility";
import Settings from "../../Settings";
import MsgInput from "../Error/MsgInput";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { periodOption } from "../Files/BankCard";
class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.is_submit = false;
    this.state = {
      kind: "IN",
      total: props.total,
      ledger_category: "",
      cash: props.total,
      card: 0,
      card_bank_card: "",
      card_period: '0',
      card_fee: 0,
      card_service: 0,
      card_code: "",
      card_start: "",
      description: "",
      status: 1,
      show_card: false,
      payment_date: moment(),
      cash_receipt: "",
      error: {},
      savings: false,
      balance: 0,
      setting_balance_saving: 0,
      cash_saving: 0,
      balance_fix: 0,
      bank_cards_options: [],
      periods: [],
      paymentOption: [],
      payment: 'CS',
      transfer: 0,
      transfer_bank_account: "",
      is_transfer: false,
      bank_account: [],
    };

    this.handlerInputChange = this.handlerInputChange.bind(this);
    this.handlerChangeCashReceipt = this.handlerChangeCashReceipt.bind(this);
    this.handlerChangeCardBankCard = this.handlerChangeCardBankCard.bind(this);
    this.handlerChangeBankAccount = this.handlerChangeBankAccount.bind(this);
    this.handlerSubmitPayment = this.handlerSubmitPayment.bind(this);
    this.handlerChangeFee = this.handlerChangeFee.bind(this);
    this.handleSeting = this.handleSeting.bind(this);

    this.cashTotalInput = React.createRef();
  }

  handlerInputChange(e, v) {
    v.value = v.value.toString().replace(/,/g, "");
    if (isNaN(Number(v.value))) {
    } else {
      v.value = v.value.toString().replace(/^0+/, "");
      if (v.value.length === 0) {
        v.value = 0;
      }
      if (v.value.toString().split(".")[1] != undefined)
        if (v.value.toString().split(".")[1].length > 2) return;
      let total = Number(this.props.total.toString().replace(/,/g, "")) - Number(this.state.cash_saving)
      this.setState({ [v.name]: v.value });
      if (v.name === "cash") {
        let cash = Number(v.value);
        cash = cash < 0 || isNaN(cash) ? 0 : cash;
        if (cash > total) {
          cash = total
        }
        this.setState({ cash: cash });
        cash = cash + this.state.cash_saving
        let transfer = this.props.total - cash
        this.setState({
          transfer: transfer > 0 ? transfer : 0,
          is_transfer: transfer > 0,
          show_card: false,
          card: 0,
          card_code: "",
          card_bank_card: "",
          card_period: '0',
        });
      }
      if (v.name === 'transfer') {
        let transfer = Number(v.value);
        transfer = transfer < 0 || isNaN(transfer) ? 0 : transfer;
        let cash = this.state.cash + this.state.cash_saving
        if (transfer > (total - cash)) {
          transfer = (total - cash)
        }
        this.setState({ transfer: transfer });
        if (this.props.is_lease) {
          if (cash + transfer < total) {
            this.setState({ cash: total - (transfer + this.state.cash_saving) })
          }
        } else {
          let card = Number(this.props.total) - (cash + transfer)
          if (card > 0) {
            let card_service = Math.round(card * (this.state.card_fee / 100));
            let card_total = card + card_service;
            this.setState({
              show_card: true,
              card: card,
              card_service: card_service,
              card_total: card_total,
            });
          } else {
            this.setState({
              show_card: false,
              card: 0,
              card_code: "",
              card_bank_card: "",
              card_period: '0',
              card_service: 0,
              card_total: 0,
            });
          }
        }
      }
    }
  }

  handlerChangeCashReceipt(e, v) {
    v.value = v.value.toString().replace(/,/g, "");
    v.value = v.value.toString().replace(/^0+/, "");
    let count = v.value.split(".")[0]
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0;
      }
      if (count.length > 15) {
        return;
      }
      if (v.value.toString().split(".")[1] != undefined)
        if (v.value.toString().split(".")[1].length > 2) return;
      this.setState({
        cash_receipt: v.value,
      });
    }
  }

  componentDidMount() {
    Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
      if (status) {
        localStorage.setItem("system_setting", JSON.stringify(data))
      }
    })

    if (this.props.is_staff) {
      Utility.get(`${Settings.baseUrl}/account-staff/?is_enabled=1&branch=${this.props.auth.branch.id}`, (e, resJson) => {
        let bank_account = [];
        let transfer_bank_account = null
        resJson.forEach((a) => {
          if (a.is_main) {
            transfer_bank_account = a.id
          }
          bank_account.push({
            value: a.id,
            key: a.id,
            text: `${a.acc_name} (${a.acc_type === 'PROMPTPAY' ? a.promptpay : a.acc_number} | ${a.acc_type === 'PROMPTPAY' ? 'พร้อมเพย์' : a.bank_name} )`,
            number: a.acc_type === 'PROMPTPAY' ? a.promptpay : a.acc_number,
            name: a.acc_name,
            bank: a.acc_type === 'PROMPTPAY' ? 'พร้อมเพย์' : a.bank_name,
            over: (Number(a.max_amount) <= Number(a.used.amount) + Number(this.props.total)) || (Number(a.max_times) <= Number(a.used.times) + 1),
          })
        })
        if (bank_account.length > 0) {
          transfer_bank_account = bank_account[0].value
        }
        Utility.get(Settings.baseUrl + "/branch_setting/?is_enabled=1&branch=" + this.props.auth.branch.id, (e, resJson) => {
          this.b = resJson;
          this.setState({
            min_balance: Number(resJson[0].savings_withdraw_min_balance),
          })
        });
        this.setState({ bank_account, transfer_bank_account });
      });
    } else {
      Utility.get(Settings.baseUrl + "/bank_account/?is_enabled=1", (e, resJson) => {
        let bank_account = [];
        resJson.filter((a) => a.branch.includes(this.props.auth.branch.id)).forEach((a) => {
          bank_account.push({
            value: a.id,
            key: a.id,
            text: a.display,
            number: a.number,
            name: a.name,
            bank: a.bank.name,
          })
        })
        Utility.get(Settings.baseUrl + "/branch_setting/?is_enabled=1&branch=" + this.props.auth.branch.id, (e, resJson) => {
          this.b = resJson;
          this.setState({
            min_balance: Number(resJson[0].savings_withdraw_min_balance),
            transfer_bank_account: bank_account.find((a) => a.value === resJson[0].transfer_bank_account) ? resJson[0].transfer_bank_account : null,
          })
        });
        this.setState({ bank_account: bank_account });
      });
    }

    Utility.get(Settings.baseUrl + "/bank_cards/?is_enabled=1", (e, resJson) => {
      this.bank_cards = resJson;
      let bank_cards = [];
      let p = []
      for (let i in resJson) {
        bank_cards.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].kind + " - " + resJson[i].bank.name,
          period: resJson[i].period,
        });
        if (!p.includes(String(resJson[i].period))) {
          p.push(String(resJson[i].period))
        }
      }
      let options = []
      periodOption.forEach((o) => {
        if (p.includes(String(o.value))) {
          options.push({
            ...o,
          })
        }
      })
      this.setState({ bank_cards_options: bank_cards, periods: p, paymentOption: options });
    });

    if (this.props.customer) {
      let customer = Utility.getFetch(
        Settings.baseUrl + "/customer/" + this.props.customer + "/savings"
      );
      Promise.all([customer]).then((values) => {
        this.setState({ balance_fix: values[0].balance });
        this.setState({ savings_id: values[0].savings_id })
      });
    }
    setTimeout(() => {
      this.cashTotalInput.current.focus();
    }, 500);
  }

  handlerChangeCardBankCard(e, v) {
    let o = Utility.getObject(this.bank_cards, v.value);
    let card = parseFloat(this.state.card);
    let card_service = Math.round(card * (o.fee / 100));
    let card_total = card + card_service;
    this.setState({
      card_bank_card: v.value,
      card_fee: o.fee,
      card_service: card_service,
      card_total: card_total,
    });
  }

  handlerChangeBankAccount(e, v) {
    this.setState({
      transfer_bank_account: v.value,
    });
  }

  handlerChangeFee(e, v) {
    v.value = v.value.toString().replace(/,/g, "");
    if (v.value == null || v.value == "") v.value = 0;
    v.value = v.value.toString().replace(/^0+/, "");
    if (v.value.toString().split(".")[1] != undefined)
      if (v.value.toString().split(".")[1].length > 2) return;
    let card = parseFloat(this.state.card);
    let card_service = v.value + "";
    if (isNaN(Number(v.value))) {
      return;
    }
    let card_total = card + parseFloat(card_service + "");
    this.setState({
      card_service: card_service,
      card_total: card_total,
    });
  }

  handlercheckValidate() {
    let error = {};

    if (this.state.card > 0) {
      // valid card
      if (
        this.state.card_code == null ||
        !this.state.card_code.toString().trim().length ||
        !/(^[0-9]+(-[0-9]+)+$)|(^\d*$)/.test(this.state.card_code)
      ) {
        error["card_code"] = "กรุณาระบุรหัสบัตรให้ถูกต้อง";
      }
      if (
        this.state.card_bank_card == null ||
        !this.state.card_bank_card.toString().trim().length
      ) {
        error["card_bank_card"] = "กรุณาเลือกชนิดบัตร";
      }

      if (this.state.card_fee == null || this.state.card_fee.toString().trim().length) {
        this.setState({ card_fee: 0 });
      }
    }
    if (this.state.cash > 0) {
      // valid card
      if (
        this.state.cash_receipt == null ||
        !this.state.cash_receipt.toString().trim().length ||
        this.state.cash_receipt <= 0
      ) {
        error["cash_receipt"] = "กรุณาระบุยอดรับเงินสด";
        this.refs.cash_receipt.focus();
      }
      if (Number(this.state.cash_receipt) < Number(this.state.cash)) {
        error["cash_receipt"] = "กรุณาระบุยอดรับเงินสดให้ถูกต้อง";
      }
    }

    if (this.state.transfer > 0) {
      // valid transfer
      if (!this.state.transfer_bank_account) {
        error["transfer_bank_account"] = "กรุณาเลือกบัญชีธนาคาร";
      }
    }



    if (this.state.card > 0) {
      if (!this.state.card_bank_card) {
        error["card_bank_card"] = "กรุณาเลือกชนิดบัตร";
      }

      if (this.state.card_code.length < 16) {
        error["card_code"] = "กรอกรหัสบัตรผิดรูปแบบ";
      }
    }

    if (this.state.card != "" && isNaN(parseFloat(this.state.card))) {
      error["card"] = "ตัวเลขเท่านั้น";
    }

    if (this.state.cash_receipt != "" && isNaN(this.state.cash_receipt)) {
      error["cash_receipt"] = "ตัวเลขเท่านั้น";
    }

    this.setState({
      error: error,
    });
    return Object.keys(error).length == 0;
  }

  async handlerSubmitPayment() {
    if (this.is_submit) {
      return;
    }

    // if (this.state.savings && ((Number(this.state.balance_fix) - Number(this.state.cash_saving)) < this.state.min_balance)) {
    //   alert("เงินออมต้องคงเหลือไม่น้อยกว่่า "+Utility.priceFormat(this.state.min_balance));
    //   return;
    // }


    if (this.handlercheckValidate()) {
      if (!window.confirm("ยืนยันการชำระเงิน")) return;
      this.is_submit = true;
      const { branch, user } = this.props.auth;
      let card = this.state.card;
      let transfer = this.state.transfer;
      let cash = Number(this.state.cash) + Number(this.state.cash_saving);
      let payment = "CS";
      if (cash > 0 && card === 0 && transfer === 0) {
        payment = "CS"; // cash
      } else if (cash === 0 && card > 0 && transfer === 0) {
        payment = "CD"; // card
      } else if (cash === 0 && card === 0 && transfer > 0) {
        payment = "QR"; // qr
      } else if (cash > 0 && card > 0 && transfer === 0) {
        payment = "CC"; // cash + card
      } else if (cash > 0 && card === 0 && transfer > 0) {
        payment = "CSQ"; // cash + qr
      } else if (cash > 0 && card > 0 && transfer > 0) {
        payment = "CCQ"; // cash + card + qr
      } else if (cash === 0 && card > 0 && transfer > 0) {
        payment = "CDQ"; // card + qr
      }
      let ledger = {
        object_id: this.props.object_id,
        object_number: this.props.object_number,
        branch: branch.id,
        staff: this.props.staff,
        ledger_date: this.props.ledger_date,
        kind: "IN",
        customer: this.props.customer,
        total: this.props.total,
        ledger_category: this.props.ledger_category,
        cash: Number(cash),
        payment: payment,
      }
      if (card) {
        ledger = {
          ...ledger,
          card: card,
          card_bank_card: this.state.card_bank_card,
          card_fee: this.state.card_fee,
          card_service: this.state.card_service,
          card_code: this.state.card_code,
          card_period: this.state.card_period,
        }
      }
      if (transfer && !this.props.is_staff) {
        ledger = {
          ...ledger,
          transfer_bank_account: this.state.transfer_bank_account,
          transfer: this.state.transfer,
        }
      }
      if (transfer && this.props.is_staff) {
        ledger = {
          ...ledger,
          cash: Number(ledger.cash) + Number(this.state.transfer),
          transfer_account_staff: this.state.transfer_bank_account,
          staff_transfer: this.state.transfer,
          payment: 'CS',
        }
      }
      let data_savings = {
        savings: Utility.removeCommas(this.state.balance) == 0 ? false : this.state.savings,
        amount: this.state.cash_saving,
        savings_id: this.state.savings_id
      }
      if (this.state.savings && Utility.removeCommas(this.state.balance) != 0) {
        if (!window.confirm("ใช้เงินออมทองแล้วจะไม่สามารถยกเลิกบิลนี้ได้")) {
          this.is_submit = false;
          return;
        }
      }
      let point = true
      if (this.props.customer && this.props.customer !== 1 && this.props.giving_point) {
        let system_setting = JSON.parse(localStorage.getItem('system_setting'))
        system_setting = system_setting.find(setting => setting.setting == 'GIVING_POINT') || { value: '0' }
        if (system_setting.value === '1') {
          point = window.confirm('คุณต้องการให้แต้มลูกค้าจากธุรกรรมนี้หรือไม่?')
        }
      }

      let r = await this.props.onSubmit(ledger, data_savings, point);
      if (r)
        if (r.error != '') {
          this.is_submit = false
        }
    } else {
      this.is_submit = false;
    }
  }

  handleSeting(e, v) {
    this.setState({
      savings: v.checked,
      cash_saving: this.set_cash_saving(v.checked).cash_saving,
      focus_result_balance: false,
      cash: this.set_cash_saving(v.checked).c,
      card: this.props.total - this.set_cash_saving(v.checked).cash_saving - this.set_cash_saving(v.checked).c,
      show_card: this.props.total - this.set_cash_saving(v.checked).cash_saving - this.set_cash_saving(v.checked).c > 0 ? true : false,
      card_code: '',
      card_bank_card: '',
      card_service: '',
      card_total: '',
    });
  }
  set_cash_saving(v) {
    return {
      cash_saving: v ? this.state.balance : 0,
      c: v ? Number(this.props.total) - Number(this.state.balance) : this.props.total,
    };
  }
  render() {
    let change = parseFloat(this.state.cash_receipt) - parseFloat(this.state.cash);
    change = change < 0 ? 0 : change;
    return (
      <div>
        <Modal open={true}>
          <Dimmer active={this.props.loading} inverted>
            <Loader inverted>{this.props.loading_message || 'Loading'}</Loader>
          </Dimmer>
          <Button
            id="closePaymentModal"
            circular
            icon="close"
            basic
            floated="right"
            name=""
            onClick={this.props.onClose}
          />
          <Modal.Header id="headerModalPayment">
            <Icon name="payment" /> ชำระเงิน
          </Modal.Header>
          <Modal.Content className="scrolling1">
            <Modal.Description id="descriptModalPayment">
              <div>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Label as="div" basic color="green" floated="right" size="huge">
                        <right>{Utility.priceFormat(this.props.total)}</right>
                      </Label>
                      <br />

                      <Form
                        className="attached fluid segment"
                        size="small"
                        onSubmit={this.onSubmitCashTotal}
                      >
                        {this.props.ledger_category != 9 && <FormField>
                          <FormField width={16}>
                            <Checkbox
                              disabled={Number(this.state.balance_fix) <= 0}
                              toggle
                              label={
                                "ยอดจ่ายด้วยเงินออม (คงเหลือ " +
                                Utility.priceFormat(this.state.balance_fix) +
                                " บาท)"
                              }
                              onChange={this.handleSeting}
                              checked={this.state.savings}
                            />
                            {/* <label>ddd</label> */}
                          </FormField>

                          <Input
                            className="text-right"
                            disabled={!this.state.savings}
                            value={
                              this.state.focus_result_balance
                                ? Utility.removeCommas(this.state.balance)
                                : Utility.priceFormat(this.state.balance)
                            }
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_result_balance) {
                                  this.setState({
                                    focus_result_balance: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={(e) => {
                              e.target.select();
                              if (!this.state.focus_result_balance) {
                                this.setState({
                                  focus_result_balance: true,
                                });
                              }
                            }}
                            onChange={(e, data) => {
                              data.value = data.value.toString().replace(/,/g, "");
                              let amount = Utility.removeCommas(data.value);

                              if (isNaN(Number(amount)) || Number(amount) < 0) {
                              } else {
                                let str = data.value.split(".");
                                str[0] = str[0]
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                str[1] = str[1] == undefined ? "" : "." + str[1];
                                if (str.length != 1) {
                                  if (str[1].length > 3) {
                                    return;
                                  }
                                }
                                let ch = Number(this.props.total) < Number(this.state.balance_fix) ? this.props.total : this.state.balance_fix
                                if (Number(data.value) > Number(ch)) {
                                  data.value = ch
                                }
                                this.setState({
                                  balance: Number(data.value),
                                  cash_saving: Number(data.value),
                                  cash: Number(Number(this.props.total) - Number(data.value)).toFixed(2),
                                  transfer: 0,
                                  is_transfer: false,
                                  card: 0,
                                  show_card: false,
                                  card_code: '',
                                  card_bank_card: '',
                                  card_service: '',
                                  card_total: '',
                                });
                              }
                            }}
                          ></Input>
                        </FormField>}
                        <Form.Field id="resultcash">
                          <label>ยอดจ่ายเงินสด</label>
                          <Input
                            id="cash"
                            // onFocus={(e) => e.target.select()}
                            name="cash"
                            // type='number'
                            min="0"
                            ref={this.cashTotalInput}
                            focus
                            size="big"
                            className="text-right"
                            onKeyPress={(e) => {
                              if (e.key == "Enter") {
                                if (this.state.is_transfer) {
                                  this.refs.transfer.focus();
                                } else {
                                  this.refs.cash_receipt.focus();
                                  this.setState({ cash_receipt: this.state.cash });
                                }
                              }
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_result_cash) {
                                  this.setState({
                                    focus_result_cash: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={(e) => {
                              e.target.select();
                              if (!this.state.focus_result_cash) {
                                this.setState({
                                  focus_result_cash: true,
                                });
                              }
                            }}
                            value={
                              this.state.focus_result_cash
                                ? this.state.cash.toString().replace(/,/g, "")
                                : Utility.priceFormat(this.state.cash)
                            }
                            // value={this.state.cash.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            onChange={this.handlerInputChange}
                          />
                        </Form.Field>
                      </Form>
                      <Form className="attached fluid segment" size="small">
                        <div style={{
                          marginTop: '-1rem',
                          marginLeft: '-1rem',
                          marginRight: '-1rem',
                          textAlign: 'center',
                          paddingTop: '0.5rem',
                          paddingBottom: '0.5rem',
                          backgroundColor: '#d7d7d7',
                          fontWeight: 600,
                          color: 'black',
                        }}>
                          ชำระด้วยเงินโอน
                        </div>
                        <Form.Field error={this.state.error.transfer_bank_account != null} style={{ marginTop: '1rem' }}>
                          <label>
                            *บัญชีธนาคาร <MsgInput text={this.state.error.transfer_bank_account} />
                          </label>
                          <Dropdown
                            id="bank_account"
                            disabled={!this.state.is_transfer}
                            search
                            selection
                            width={14}
                            options={this.state.bank_account}
                            onChange={this.handlerChangeBankAccount}
                            value={this.state.transfer_bank_account}
                          />
                        </Form.Field>
                        <Form.Field id="resulttransfer" >
                          <label>ยอดเงินโอน </label>
                          <Input
                            id="transfer"
                            name="transfer"
                            ref='transfer'
                            min="0"
                            focus
                            size="big"
                            className="text-right"
                            disabled={!this.state.is_transfer}
                            onKeyPress={(e) => {
                              if (e.key == "Enter") {
                                if (this.state.card > 0) {
                                }
                                else {
                                  if (this.state.cash > 0) {
                                    this.refs.cash_receipt.focus()
                                    this.setState({ cash_receipt: this.state.cash });
                                  } else {
                                    this.handlerSubmitPayment()
                                  }
                                }
                              }
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_result_transfer) {
                                  this.setState({
                                    focus_result_transfer: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={(e) => {
                              e.target.select();
                              if (!this.state.focus_result_transfer) {
                                this.setState({
                                  focus_result_transfer: true,
                                });
                              }
                            }}
                            value={
                              this.state.focus_result_transfer
                                ? this.state.transfer.toString().replace(/,/g, "")
                                : Utility.priceFormat(this.state.transfer)
                            }
                            onChange={this.handlerInputChange}
                          />
                        </Form.Field>
                      </Form>
                      <Form className="attached fluid segment" size="small">
                        <div style={{
                          marginTop: '-1rem',
                          marginLeft: '-1rem',
                          marginRight: '-1rem',
                          textAlign: 'center',
                          paddingTop: '0.5rem',
                          paddingBottom: '0.5rem',
                          backgroundColor: '#d7d7d7',
                          fontWeight: 600,
                          color: 'black',
                        }}>
                          ชำระด้วยบัตร
                        </div>
                        {this.props.is_lease ?
                          <p className="text-center" style={{ color: 'red' }}>ไม่รองรับการชำระด้วยบัตรเครดิต</p>
                          : <>
                            <Form.Field error={this.state.error.card_period != null} style={{ marginTop: '1rem' }}>
                              <label>
                                *ประเภทชำระ <MsgInput text={this.state.error.card_period} />
                              </label>
                              <Dropdown
                                id="card_period"
                                disabled={this.state.disabled_card || !this.state.show_card}
                                selection
                                width={14}
                                options={this.state.paymentOption}
                                onChange={(e, v) => {
                                  this.setState({
                                    card_period: v.value,
                                    card_bank_card: '',
                                    card_fee: 0,
                                    card_service: 0,
                                    card_total: 0,
                                  });
                                }}
                                value={this.state.card_period}
                              />
                            </Form.Field>
                            <Form.Field error={this.state.error.card_bank_card != null}>
                              <label>
                                *ชนิดบัตร <MsgInput text={this.state.error.card_bank_card} />
                              </label>
                              <Dropdown
                                id="type"
                                disabled={this.state.disabled_card || !this.state.show_card}
                                search
                                selection
                                width={14}
                                options={this.state.bank_cards_options.filter(v => String(v.period) === String(this.state.card_period))}
                                onChange={this.handlerChangeCardBankCard}
                                value={this.state.card_bank_card}
                              />
                            </Form.Field>
                            <Form.Field error={this.state.error.card_code != null}>
                              <label>
                                *รหัสบัตร <MsgInput text={this.state.error.card_code} />
                              </label>
                              <NumberFormat
                                value={this.state.card_code}
                                format="#### #### #### ####"
                                onValueChange={(v) => {
                                  this.setState({ card_code: v.value });
                                }}
                                disabled={!this.state.show_card}
                                onKeyPress={(e) => {
                                  if (e.key == "Enter") {
                                    if (this.state.card_code.length >= 16 && this.state.cash > 0) {
                                      this.refs.cash_receipt.focus()
                                      this.setState({ cash_receipt: this.state.cash });
                                    } else {
                                      this.handlerSubmitPayment()
                                    }
                                  }
                                }}
                              />
                            </Form.Field>
                            {/* <Form.Field error={this.state.error.card_fee != null}>
                          <label>%ค่าธรรมเนียม</label>
                          <Input
                            disabled={this.state.disabled_card || !this.state.show_card}
                            value={this.state.card_fee}
                            className='text-right'
                            onChange={this.handlerFee} />
                        </Form.Field> */}
                            <Form.Field error={this.state.error.card != null}>
                              <label>จ่ายเครดิต</label>
                              <Input
                                id="credit"
                                readOnly
                                disabled={this.state.disabled_card || !this.state.show_card}
                                className="text-right"
                                value={Utility.priceFormat(this.state.card)}
                              />
                            </Form.Field>
                            <Form.Field error={this.state.error.card_service != null}>
                              <label>
                                ค่าธรรมเนียม{" "}
                                {this.state.card_bank_card != ""
                                  ? Utility.priceFormat(this.state.card_fee) + "%"
                                  : ""}
                              </label>
                              <Input
                                id="service"
                                readOnly={true}
                                disabled={this.state.disabled_card || !this.state.show_card}
                                className="text-right"
                                // value={this.state.card_service.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                onBlur={() => {
                                  setTimeout(() => {
                                    if (this.state.focus_cash) {
                                      this.setState({
                                        focus_cash: false,
                                      });
                                    }
                                  }, 0);
                                }}
                                onFocus={(e) => {
                                  e.target.select();
                                  if (!this.state.focus_cash) {
                                    this.setState({
                                      focus_cash: true,
                                    });
                                  }
                                }}
                                value={
                                  this.state.focus_cash
                                    ? this.state.card_service.toString().replace(/,/g, "")
                                    : this.state.card_service
                                      .toString()
                                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                }
                                onChange={this.handlerChangeFee}
                              />
                            </Form.Field>
                            <Form.Field error={this.state.error.card_total != null}>
                              <label>ยอดจ่ายรวม</label>
                              <Input
                                id="total"
                                disabled={this.state.disabled_card || !this.state.show_card}
                                readOnly
                                className="text-right"
                                value={Utility.priceFormat(this.state.card_total)}
                              />
                            </Form.Field>
                          </>}

                      </Form>
                    </Grid.Column>
                    <Grid.Column>
                      {this.state.cash > 0 && (
                        <Segment secondary>
                          <Form className="fluid " color="purple" size="big">
                            <Form.Field error={this.state.error.payment_date != null}>
                              <label>
                                วันที่ชำระเงิน{" "}
                                <MsgInput text={this.state.error.payment_date} />
                              </label>
                              <DatePicker
                                readOnly
                                dateFormat="DD/MM/YYYY"
                                selected={this.state.payment_date}
                                onChange={(date) => {
                                  this.setState({ payment_date: date });
                                }}
                              />
                            </Form.Field>
                            <Form.Field error={this.state.error.cash_receipt != null}>
                              <label>
                                จำนวนรับ เงินสด{" "}
                                <MsgInput text={this.state.error.cash_receipt} />
                              </label>
                              <Input
                                id="inputCash"
                                // type='number'
                                // value={this.state.cash_receipt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                // maxLength={9}
                                onBlur={() => {
                                  setTimeout(() => {
                                    if (this.state.focus) {
                                      this.setState({
                                        focus: false,
                                      });
                                    }
                                  }, 0);
                                }}
                                onFocus={(e) => {
                                  e.target.select();
                                  if (!this.state.focus) {
                                    this.setState({
                                      focus: true,
                                    });
                                  }
                                }}
                                value={
                                  this.state.focus
                                    ? this.state.cash_receipt.toString().replace(/,/g, "")
                                    : Utility.priceFormat(this.state.cash_receipt)
                                }
                                onChange={this.handlerChangeCashReceipt}
                                onKeyPress={(e) => {
                                  if (e.key == "Enter") {
                                    this.handlerSubmitPayment();
                                  }
                                }}
                                ref="cash_receipt"
                                name="cash_receipt"
                                className="text-right"
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>จำนวนเงินทอน</label>
                              <Input
                                id="change"
                                value={Utility.priceFormat(change)}
                                className="text-right"
                                readOnly
                              />
                            </Form.Field>
                          </Form>
                        </Segment>
                      )}
                      {this.state.transfer > 0 && (
                        <Segment secondary>
                          <Form>
                            <Form.Field>
                              <Input
                                label='ธนาคาร'
                                value={this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account) ? this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account).bank : ''}
                                className="text-right"
                                readOnly
                              />
                            </Form.Field>
                            <Form.Field >
                              <Input
                                label='ชื่อบัญชี'
                                value={this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account) ? this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account).name : ''}
                                className="text-right"
                                readOnly
                              />
                            </Form.Field>
                            <Form.Field>
                              <Input
                                label='เลขบัญชี'
                                value={this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account) ? this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account).number : ''}
                                className="text-right"
                                readOnly
                              />
                            </Form.Field>
                            <Form.Field>
                              <Input
                                label='จำนวนเงินโอน'
                                value={Utility.priceFormat(this.state.transfer)}
                                className="text-right"
                                readOnly
                              />
                            </Form.Field>
                          </Form>
                          {this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account) ? (this.state.bank_account.find((b) => b.value === this.state.transfer_bank_account).over
                            && <p style={{ color: 'red', marginTop: '10px' }}>บัญชีนี้ใช้ครบตามโควต้าแล้ว</p>) : ''}
                        </Segment>
                      )}

                      {/* {this.state.show_card && <Segment>
                        <b>บันทึกรายละเอียดการผ่อนด้วยบัตรเครดิต</b>
                        <Divider />
                        <Form size='small'>
                          <Form.Field >
                            <label>จำนวนงวด <MsgInput text={this.state.error.card_period} /></label>
                            <Input
                              disabled={this.state.disabled_card}
                              min={0}
                              // type='number'
                              value={this.state.card_period}
                              onChange={(e, v) => {
                                if (isNaN(parseInt(v.value))) {
                                  this.setState({ card_period: '' })
                                } else {
                                  this.setState({ card_period: Utility.parseInt(v.value, this.state.card_period) });
                                }

                              }} />
                          </Form.Field>
                          <Form.Field >
                            <label>วันที่เริ่มผ่อน <MsgInput text={this.state.error.card_start} /></label>
                            <DatePicker
                              disabled={this.state.disabled_card}
                              dateFormat="DD/MM/YYYY"
                              selected={this.state.card_start}
                              onChange={(date) => {

                                this.setState({ card_start: date });
                              }} />
                          </Form.Field>
                        </Form>
                      </Segment>} */}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id="btnSave"
              size="small"
              primary
              onClick={this.handlerSubmitPayment}
              className={this.state.button_class ? "loading" : ""}
            >
              บันทึกชำระเงิน
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        //e.preventDefault()
      },
    },
  };
}

PaymentModal.defaultProps = {
  total: 0,
  staff: "",
  object_id: 0,
  object_number: "",
  ledger_date: Utility.formatDate2(moment()),
  kind: "IN", // IN or EX,
  customer: 1,
};
PaymentModal.propTypes = {
  total: PropTypes.number.isRequired,
  staff: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ledger_category: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(PaymentModal));
