/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment, Header, Button, Loader, Dimmer, Modal, Menu, Grid, Table, Icon, Image
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import BillList from './BillList';
import Scoure from './Scoure';
import Lease from './Lease'
import Saving from './Saving'
import image from '../../images/square-image.png'
import config from '../../config';

class Customer extends Component {

    constructor(props) {
        super(props);

        this.modal_open = false;
        this.state = {
            message_error_hidden: true,
            loading: true,
            customer: {},
            setting: props.setting,
            activeItem: 'info',
            activeSubItem: 'info',
            qrcode: ''
        }

        this.gender_options = [
            {
                key: 'MA',
                value: 'MA',
                text: 'ชาย',
            }, {
                key: 'FE',
                value: 'FE',
                text: 'หญิง',
            }, {
                key: 'NA',
                value: 'NA',
                text: 'ไม่ระบุ',
            }
        ]

        this.handleItemClick = this.handleItemClick.bind(this)
        this.handleSubItemClick = this.handleSubItemClick.bind(this)
    }

    componentDidMount() {

        let customer = Utility.getFetch(Settings.baseUrl + '/customers/' + this.props.customer_id + '/')
        Promise.all([customer]).then((values) => {

            const op = Utility.getObjectByValue(this.gender_options, values[0].gender)
            if (op)
                values[0].gender = op.text
            if (values[0].birth_date != null) {
                values[0].age = Utility.getAge(values[0].birth_date)
                values[0].birth_date = Utility.formatDate(values[0].birth_date)
            }
            values[0]['career'] = values[0].career != null ? values[0].career.name : ''
            values[0]['workplace'] = values[0].workplace != null ? values[0].workplace.name : ''
            this.setState({
                customer: values[0],
                qrcode: {
                    username: values[0].code,
                    password: values[0].secret,
                    secret: values[0].secret,
                    customer: values[0].id
                },
                loading: false
            })
        });
    }

    handleItemClick(e, v) {
        this.setState({
            activeItem: v.name
        })
    }

    handleSubItemClick(e, v) {
        this.setState({
            activeSubItem: v.name
        })
    }
    render() {
        let setting = []
        for (let i in this.state.setting) {
            if (this.state.setting[i].check)
                setting.push(this.state.setting[i])
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }

        return (
            <div>
                <Modal id='cusInfoModal' open={true} size='fullscreen' /*dimmer='blurring'*/>
                    <Button id='btnClose'
                        circular
                        icon='close'
                        basic
                        floated='right'
                        name=''
                        onClick={this.props.onClose} />
                    <Modal.Header>{this.state.customer.name}</Modal.Header>
                    <Modal.Content className='scrolling segment'>
                        <Dimmer active={this.state.loading} inverted>
                            <Loader />
                        </Dimmer>
                        <Modal.Description>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={3}>
                                            <center>
                                                <Image src={this.state.customer.image == null ? image : this.state.customer.image.crop} circular size='small' />
                                                <Header as='h4'>
                                                    <Header.Content>
                                                        <div style={Width300}> {this.state.customer.name} </div>
                                                        <Header.Subheader>{this.state.customer.code}</Header.Subheader>
                                                    </Header.Content>
                                                </Header>

                                            </center>
                                        </Grid.Column>
                                        <Grid.Column width={13}>
                                            <Menu  >
                                                <Menu.Item id='infoPage' active={this.state.activeItem == 'info'} name='info' onClick={this.handleItemClick} >ข้อมูลลูกค้า</Menu.Item>
                                                <Menu.Item id='posPage' active={this.state.activeItem == 'pos'} name='pos' onClick={this.handleItemClick} >ซื้อ-ขายทอง</Menu.Item>
                                                {!config.rd && <Menu.Item id='leasePage' active={this.state.activeItem == 'lease'} name='lease' onClick={this.handleItemClick} >ขายฝาก</Menu.Item>}
                                                <Menu.Item id='savingPage' active={this.state.activeItem == 'saving'} name='saving' onClick={this.handleItemClick} >ออมทอง</Menu.Item>
                                                {!config.rd && <Menu.Item id='pointPage' active={this.state.activeItem == 'point'} name='point' onClick={this.handleItemClick} >คะแนน</Menu.Item>}
                                            </Menu>
                                            <Segment >
                                                {this.state.activeItem == 'info' &&
                                                    <div>
                                                        <Menu  >
                                                            <Menu.Item id='cusInfo' active={this.state.activeSubItem == 'info'} name='info' onClick={this.handleSubItemClick} >ข้อมูลส่วนตัว</Menu.Item>
                                                            <Menu.Item id='add2' active={this.state.activeSubItem == 'address2'} name='address2' onClick={this.handleSubItemClick} >ที่อยู่จัดส่งเอกสาร/ของรางวัล</Menu.Item>
                                                            {/* <Menu.Item id='add3' active={this.state.activeSubItem == 'address3'} name='address3' onClick={this.handleSubItemClick} >ที่อยู่ 3</Menu.Item> */}
                                                        </Menu>
                                                        {this.state.activeSubItem == 'info' &&
                                                            <Table basic='very' celled collapsing style={{ width: '100%' }}>
                                                                <Table.Row>
                                                                    <Table.Cell><b>รหัส</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.code}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>คำนำหน้า</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.prefix}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ชื่อ</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.name}</Table.Cell>
                                                                </Table.Row>

                                                                {this.state.setting.map((item, i) => {
                                                                    return (item.key === 'birth_date' ? <>
                                                                        <Table.Row key={item.key}>
                                                                            <Table.Cell><b>{item.label}</b></Table.Cell>
                                                                            <Table.Cell>{this.state.customer[item.key]}</Table.Cell>
                                                                        </Table.Row>
                                                                        {
                                                                            this.state.customer['age'] && <Table.Row key={'age'}>
                                                                                <Table.Cell><b>อายุ</b></Table.Cell>
                                                                                <Table.Cell>{`${this.state.customer['age']} ปี`}</Table.Cell>
                                                                            </Table.Row>
                                                                        }
                                                                    </> : <Table.Row key={item.key}>
                                                                        <Table.Cell><b>{item.label}</b></Table.Cell>
                                                                        <Table.Cell>{this.state.customer[item.key]}</Table.Cell>
                                                                    </Table.Row>)
                                                                })}
                                                                <Table.Row>
                                                                    <Table.Cell><b>เลขประจำตัวประชาชน</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.citizen_id}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell style={{ width: '30%' }}><b>เบอร์มือถือ</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.mobile}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ที่อยู่</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.address}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>หมายเหตุ</b></Table.Cell>
                                                                    <Table.Cell style={Width300}>{this.state.customer.description}</Table.Cell>
                                                                </Table.Row>
                                                            </Table>}

                                                        {this.state.activeSubItem == 'address2' &&
                                                            <Table basic='very' celled collapsing style={{ width: '100%' }}>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ที่อยู่</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.address2}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ตำบล</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.district2}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>อำเภอ</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.city2}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>จังหวัด</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.province2}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>รหัสไปรษณีย์</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.postal_code2}</Table.Cell>
                                                                </Table.Row>
                                                            </Table>}

                                                        {this.state.activeSubItem == 'address3' &&
                                                            <Table basic='very' celled collapsing style={{ width: '100%' }}>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ที่อยู่</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.address3}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ตำบล</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.district3}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>อำเภอ</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.city3}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>จังหวัด</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.province3}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>รหัสไปรษณีย์</b></Table.Cell>
                                                                    <Table.Cell>{this.state.customer.postal_code3}</Table.Cell>
                                                                </Table.Row>
                                                            </Table>}


                                                    </div>}
                                                {this.state.activeItem == 'pos' ? <BillList customer_id={this.state.customer.id} /> : ''}
                                                {this.state.activeItem == 'lease' ? <Lease customer_id={this.state.customer.id} /> : ''}
                                                {this.state.activeItem == 'saving' ? <Saving customer_id={this.state.customer.id} /> : ''}
                                                {this.state.activeItem == 'point' ? <Scoure customer_name={this.state.customer.name} customer_code={this.state.customer.code} customer_id={this.state.customer.id} /> : ''}
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </div>


                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}
export default Customer;
