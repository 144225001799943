/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../Utility';
import Settings from '../../Settings';
import config from '../../config';

class BranshRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const textRight = {
            'text-align': 'right'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        return (
            <Table.Body>
                <Table.Row >
                    <Table.HeaderCell style={textRight}>สาขา:</Table.HeaderCell>
                    <Table.HeaderCell colSpan='13'>{this.props.data.branch_name}</Table.HeaderCell>
                </Table.Row>

                {this.props.items.map((row, i) => {
                    if (row.head === true) {
                        return <Table.Row key={i}>
                            <Table.Cell style={textCenter}>{row.id}</Table.Cell>
                            <Table.Cell style={textCenter}>{row.number}</Table.Cell>
                            {!config.rd && <Table.Cell style={textCenter}>{row.lease_type}</Table.Cell>}
                            <Table.Cell style={textCenter}>{row.start_date_display}</Table.Cell>
                            <Table.Cell style={textCenter}>{row.customer_name}</Table.Cell>
                            <Table.Cell style={textCenter}>{row.product_name}</Table.Cell>
                            <Table.Cell style={textRight}>{row.weight}</Table.Cell>
                            <Table.Cell style={textRight}>{row.interest}</Table.Cell>
                            <Table.Cell style={textRight}>{row.month}</Table.Cell>
                            <Table.Cell style={textRight}>{row.amount_display}</Table.Cell>
                            <Table.Cell style={textRight}>{row.interest_m}</Table.Cell>
                            <Table.Cell style={textRight}>{row.total_interest}</Table.Cell>
                            <Table.Cell style={textCenter}>{row.end_date_display}</Table.Cell>
                            <Table.Cell style={textCenter}>{row.close_date_display}</Table.Cell>
                            <Table.Cell style={textCenter}>{row.phone}</Table.Cell>
                        </Table.Row>
                    }
                    else {
                        return (<Table.Row key={i}>
                            <Table.Cell colSpan={config.rd ? '4' : '5'}></Table.Cell>
                            <Table.Cell style={textCenter}>{row.product_name}</Table.Cell>
                            <Table.Cell colSpan='12'></Table.Cell>
                        </Table.Row>)
                    }

                })}

                <Table.Row >
                    <Table.Cell colSpan={config.rd ? '3' : '4'}></Table.Cell>
                    <Table.Cell style={textRight}>รวมตามสาขา</Table.Cell>
                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>{Utility.priceFormat(this.props.data.weight)}</b></div></Table.Cell>
                    <Table.Cell ></Table.Cell>
                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>{Utility.priceFormat(this.props.data.amount)}</b></div></Table.Cell>
                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>{Utility.priceFormat(this.props.data.total_interest)}</b></div></Table.Cell>
                    <Table.Cell colSpan='3'></Table.Cell>
                </Table.Row>
            </Table.Body>
        )

    }
}

class ProductDetailPrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch_arr: [],
            items: [],
            loader_active: true,
        }
    }

    componentDidMount() {
        const branch = {}
        let weight = 0
        let amount = 0
        let total_interest = 0
        const branch_arr = []
        const items = []

        setTimeout(() => {

            for (let i in this.props.items) {
                const item = this.props.items[i]
                if (branch[item.branch] == null)
                    branch[item.branch] = {
                        branch_name: item.branch_name,
                        weight: 0,
                        amount: 0,
                        total_interest: 0,
                        items: []
                    }

                branch[item.branch].items.push(item)
                branch[item.branch].weight += parseFloat(Utility.removeCommas(item.weight))
                branch[item.branch].amount += parseFloat(Utility.removeCommas(item.amount))
                branch[item.branch].total_interest += parseFloat(Utility.removeCommas(item.total_interest))
                weight += parseFloat(Utility.removeCommas(item.weight))
                amount += parseFloat(Utility.removeCommas(item.amount))
                total_interest += parseFloat(Utility.removeCommas(item.total_interest))
            }

            for (let i in branch) {
                branch_arr.push(branch[i])
                for (let j in branch[i].items) {
                    for (let k in branch[i].items[j].lease_prodcut) {
                        if (k == 0) {
                            items.push({
                                'id': Number(j) + 1,
                                'head': true,
                                'number': branch[i].items[j].number,
                                'lease_type': branch[i].items[j].lease_type,
                                'start_date_display': branch[i].items[j].start_date_display,
                                'customer_name': branch[i].items[j].customer_name,
                                'weight': branch[i].items[j].weight,
                                'interest': branch[i].items[j].interest,
                                'month': branch[i].items[j].month,
                                'amount_display': branch[i].items[j].amount_display,
                                'interest_m': branch[i].items[j].interest_m,
                                'total_interest': branch[i].items[j].total_interest,
                                'end_date_display': branch[i].items[j].end_date_display,
                                'close_date_display': branch[i].items[j].close_date_display,
                                'phone': branch[i].items[j].phone,
                                'product_name': branch[i].items[j].lease_prodcut[k].product_name,
                                // 'number': branch[i].items[j].number,
                            })

                        } else {
                            items.push({
                                'id': '',
                                'head': false,
                                'number': branch[i].items[j].number,
                                'lease_type': branch[i].items[j].lease_type,
                                'start_date_display': branch[i].items[j].start_date_display,
                                'customer_name': branch[i].items[j].customer_name,
                                'weight': branch[i].items[j].weight,
                                'interest': branch[i].items[j].interest,
                                'month': branch[i].items[j].month,
                                'amount_display': branch[i].items[j].amount_display,
                                'interest_m': branch[i].items[j].interest_m,
                                'total_interest': branch[i].items[j].total_interest,
                                'end_date_display': branch[i].items[j].end_date_display,
                                'close_date_display': branch[i].items[j].close_date_display,
                                'phone': branch[i].items[j].phone,
                                'product_name': branch[i].items[j].lease_prodcut[k].product_name,
                            })
                        }

                    }

                }
            }



            this.setState({
                branch_arr: branch_arr,
                weight: weight,
                amount: amount,
                total_interest: total_interest,
                items: items,
                loader_active: false,
            })
        }, 1000)

    }

    render() {
        let data = this.props.data

        let title = 'รายการขายฝาก';
        let filename = 'lese-' + Utility.formatDate3(data.start_date) + '-to-' + Utility.formatDate3(data.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        return (<div>
            <Modal open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                    <Loader content='Loading' inverted />
                </Dimmer>
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>
                    <div id='view-print'>
                        <div id='paperA4-portrait'>
                            <Table basic id='table-to-xls' style={divStyle}>
                                <Table.Header>
                                    <Table.Row >
                                        <Table.HeaderCell colSpan={config.rd ? '14' : '15'}><center>{title}</center></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={textCenter}>ลำดับที่</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>เลขที่บิล</Table.HeaderCell>
                                        {!config.rd && <Table.HeaderCell style={textCenter}>ประเภท</Table.HeaderCell>}
                                        <Table.HeaderCell style={textCenter}>วันที่นำเข้า</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>ชื่อลูกค้า</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>รายการสินค้า</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>น้ำหนักรวม</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>อัตราดอกเบี้ย</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>จำนวนเดือน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>จำนวนเงิน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ดอกเบี้ย/เดือน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>รวมดอกเบี้ยรับ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>วันครบกำหนด</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>วันที่ไถ่คืน</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>เบอร์โทร</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>


                                {this.state.branch_arr.map((row2, i) =>
                                    <BranshRow key={i} data={row2} items={this.state.items} loader_active={this.state.loader_active} />
                                )}
                                <Table.Body>
                                    <Table.Row >
                                        <Table.Cell colSpan={config.rd ? '3' : '4'}></Table.Cell>
                                        <Table.Cell style={textRight}><b>รวมทั้งหมด</b></Table.Cell>
                                        <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>{Utility.priceFormat(this.state.weight)}</b></div></Table.Cell>
                                        <Table.Cell ></Table.Cell>
                                        <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>{Utility.priceFormat(this.state.amount)}</b></div></Table.Cell>
                                        <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>{Utility.priceFormat(this.state.total_interest)}</b></div></Table.Cell>
                                        <Table.Cell colSpan='3'></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="ui primary button small"
                        table="table-to-xls"
                        filename={filename}
                        sheet={title}
                        buttonText="Download as XLS" >
                    </ReactHTMLTableToExcel>

                    <Button primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(() => {
                            mywindow.close();
                        }, 2000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div >)
    }
}

export default ProductDetailPrintPreview;