/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell,
} from 'fixed-data-table';
import {
  Dimmer,
  Loader,
  Button,
  Form,
  Popup,
  Modal,
  Input,
} from 'semantic-ui-react';
import 'react-dates/lib/css/_datepicker.css';
import { ContextMenuProvider } from 'react-contexify';
import Settings from '../../Settings';
import Utility from '../../Utility';
import moment from 'moment';
import config from '../../config';

class ItemsCell extends Component {


  render() {
    const { rowIndex, field, type, data, ...props } = this.props;
    let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
      : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
        : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
          : data[rowIndex][field];
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props}>
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id"
          className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>
          <Popup
            position={this.props.position}
            content={v}
            style={Width300}
            trigger={<div
              onClick={() => { this.props.action(data[rowIndex]['id'], data[rowIndex]['number']) }}
              style={ellipsisStyle}>{v}</div>}
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}

class BillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      total: {
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
      },
      lease_interest: [],
      modal_interest: false,
      table_width2: 600,
      table_hiegh2: 300,
    }
    this.loadInterest = this.loadInterest.bind(this)
    this.setFieldValueInterest = this.setFieldValueInterest.bind(this)
  }

  componentDidMount() {
    this.setState({
      loader_active: true
    });
    let querytoday = Utility.jsonToQueryString({});

    var bills = Utility.getFetch(Settings.baseUrl + '/lease/?is_enabled=1&customer=' + this.props.customer_id);
    Promise.all([bills]).then((values) => {
      this.items = values[0]


      this.setState({
        loader_active: false,
        items: this.setFieldValue(this.items),
        table_width: window.innerWidth,
        table_width2: window.innerWidth,
      });
    });
  }

  loadInterest(id, lease_nummber) {
    this.setState({
      modal_interest: true,
    }, () => {
      setTimeout(() => {

        let elHeight = document.getElementById('w_content')
        if (elHeight)
          this.setState({
            w_content: elHeight.clientWidth,
            table_width2: elHeight.clientWidth,
          });
      }, 1000)
    })
    if (id) {
      let lease_interest = Utility.getFetch(Settings.baseUrl + '/lease/' + id + '/interest/?is_enabled=1');
      this.setState({
        loading: true
      })

      Promise.all([lease_interest]).then((values) => {
        let total_interest = 0;
        for (let i in values[0]) {
          total_interest += parseFloat(values[0][i].total_receive)
        }
        this.setState({
          lease_interest: this.setFieldValueInterest(values[0]),
          total_interest: total_interest,
          lease_nummber: lease_nummber,
          loading: false
        })
      });
    }
  }

  setFieldValueInterest(list) {
    let j = list.length;
    for (let i in list) {
      list[i].no = j--
      list[i].pay_date_display = list[i].pay_date ? Utility.formatDate(list[i].pay_date) : ''
      list[i].interest_date_display = Utility.formatDate(list[i].interest_date)

      list[i].total_pay = Utility.priceFormat(list[i].total_receive)
      list[i].total = Utility.priceFormat(list[i].total)
      list[i].time = Utility.formatTime(list[i].record_date)
      if (list[i].ledger) {
        list[i].payment_title = Settings.payment[list[i].ledger.payment]
        list[i].branch_name = list[i].ledger.branch.name
        list[i].card_code = list[i].ledger.card_code
        list[i].card_code = list[i].ledger.card_code
      }
    }

    return list
  }


  setFieldValue(items) {
    let total = {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
    }
    let total_interest = 0
    for (let i in items) {
      items[i].branch_name = items[i].branch.name
      items[i].status_title = Settings.status_lease[items[i].status]
      var a = moment();
      var b = moment(items[i].end_date);
      items[i].day = a.diff(b, 'days') < 0 ? '' : a.diff(b, 'days')
      total[items[i].status + ''] += parseFloat(items[i].amount)
      total_interest += parseFloat(items[i].total_interest)
    }

    this.setState({
      total: total,
      total_interest: total_interest
    })
    return items
  }

  componentWillMount() {
    this.setState({
      table_width: this.props.clientWidth ? this.props.clientWidth : window.innerWidth - 70,
      table_height: window.innerHeight - 450
    });
  }
  render() {
    let items = this.state.items
    const activeItem = this.state.activeItem
    return (<div>

      <div className='relative' id='table_width'>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>

        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={this.state.table_height}>
          <Column
            header={<Cell>สาขา</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top left' width={140} data={items} field="branch_name" />
            }
            width={140}
          />
          <Column
            header={<Cell>เลขที่ขายฝาก</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top left' width={120} id='leaseID' data={items} field="number" />
            }
            width={120}
          />
          <Column
            header={<Cell>วันที่นำเข้า</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top left' width={180} data={items} field="start_date" />
            }
            width={180}
          />
          <Column
            header={<Cell>วันครบกำหนด</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top left' width={120} data={items} field="end_date" />
            }
            width={120}
          />
          <Column
            header={<Cell className='text-right'>จำนวนเงิน</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top right' width={180} data={items} field="amount" textAlign='text-right' type='price' />
            }
            width={180}
          />
          <Column
            header={<Cell className='text-center'>สถานะ</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top center' width={180} data={items} field="status_title" textAlign='text-center' />
            }
            width={180}
          />
          <Column
            header={<Cell className='text-right'>เกินกำหนด(วัน)</Cell>}
            cell={
              <ItemsCell action={this.loadInterest} position='top right' width={180} data={items} field="day" textAlign='text-right' />
            }
            width={180}
          />
        </Table>

        <Form size='small'>
          <Form.Group widths='equal'>
            <Form.Input className='text-right' label='คัดออก' value={Utility.priceFormat(this.state.total['4'])} />
            <Form.Input className='text-right' label='ไถ่คืน' value={Utility.priceFormat(this.state.total['3'])} />
            <Form.Input className='text-right' label='ยังไม่ไถ่คืน' value={Utility.priceFormat(this.state.total['1'] + this.state.total['2'])} />
            <Form.Input className='text-right' label='รวม' value={Utility.priceFormat(this.state.total['1'] + this.state.total['2'] + this.state.total['3'] + this.state.total['4'])} />

          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field width={3}></Form.Field>
            <Form.Field width={3}></Form.Field>
            <Form.Field width={3}></Form.Field>
            <Form.Input width={3} className='text-right' label='ดอกเบี้ยรับรวม' value={Utility.priceFormat(this.state.total_interest)} />
          </Form.Group>
        </Form>
      </div>
      {
        this.state.modal_interest &&
        <Modal size='large' open={true} /*dimmer='blurring'*/>

          <Button id='btnClose' circular icon='close' title='ปิด' basic floated='right' name='' onClick={(e) => {
            e.preventDefault();
            this.setState({ modal_interest: false })
          }} />
          <Modal.Header>ประวัติต่อดอกใบสัญญาขายฝาก {this.state.lease_nummber}</Modal.Header>

          <Modal.Content className=''>
            <Modal.Description>
              <div id="w_content" className='relative'>
                <Dimmer active={this.state.loading} inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
                <Table
                  rowsCount={this.state.lease_interest.length}
                  rowHeight={30}
                  headerHeight={30}
                  width={this.state.table_width2}
                  height={this.state.table_hiegh2}>
                  <Column
                    header={<Cell className='text-center'>ครั้งที่</Cell>}
                    cell={
                      <ItemsCell position='top right' width={50} data={this.state.lease_interest} field="no" textAlign='text-center' />
                    }
                    width={50}
                  />
                  <Column
                    header={<Cell className='text-right'>ดอกเบี้ยคำนวณ</Cell>}
                    cell={
                      <ItemsCell position='top right' width={180} data={this.state.lease_interest} field="total" textAlign='text-right' />
                    }
                    width={180}
                  />
                  <Column
                    header={<Cell className='text-right'>ดอกเบี้ยรับ</Cell>}
                    cell={
                      <ItemsCell position='top right' width={180} data={this.state.lease_interest} field="total_pay" textAlign='text-right' />
                    }
                    width={180}
                  />
                  <Column
                    header={<Cell>คิดดอกเบี้ยถึงวันที่</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="interest_date_display" />
                    }
                    width={120}
                  />
                  <Column
                    header={<Cell>วันที่จ่าย</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="pay_date_display" />
                    }
                    width={120}
                  />
                  <Column
                    header={<Cell>เวลา</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="time" />
                    }
                    width={120}
                  />
                  <Column
                    header={<Cell>สาขา</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="branch_name" />
                    }
                    width={120}
                  />
                  <Column
                    header={<Cell>ประเภทการชำระ</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="payment_title" />
                    }
                    width={120}
                  />
                  <Column
                    header={<Cell>ช่องทางชำระ</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="payment" />
                    }
                    width={120}
                  />
                  <Column
                    header={<Cell>หมายเหตุ</Cell>}
                    cell={
                      <ItemsCell position='top left' width={120} data={this.state.lease_interest} field="description" />
                    }
                    width={120}
                  />
                </Table>
              </div>
              <div style={{ marginTop: '5px' }}>
                <Form size='small'>
                  <Form.Field inline>
                    <label> รวมดอกเบี้ยรับ</label>
                    <Input
                      readOnly
                      value={Utility.priceFormat(this.state.total_interest)}
                      className="text-right" />
                  </Form.Field>
                </Form>
              </div>
            </Modal.Description>

          </Modal.Content>
        </Modal>
      }
    </div>)
  }
}

export default BillList;
