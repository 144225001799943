/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Modal, Table, Checkbox, Divider
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class GroupData extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { value, group_by } = this.props;
        const { items, count, total } = value
        return (
            <>
                {items.map((row, i) => <Table.Row key={i}>
                    {
                        group_by === 'account' ?
                            <>
                                <Table.Cell style={{ 'text-align': 'left' }}>{i === 0 ? row.account : ''}</Table.Cell>
                                <Table.Cell style={{ 'text-align': 'center' }}>{row.pay_date}</Table.Cell>
                            </> :
                            <>
                                <Table.Cell style={{ 'text-align': 'center' }}>{i === 0 ? row.pay_date : ''}</Table.Cell>
                                <Table.Cell style={{ 'text-align': 'left' }}>{row.account}</Table.Cell>
                            </>
                    }
                    <Table.Cell style={{ 'text-align': 'right' }}>{Utility.numberFormat(row.count)}</Table.Cell>
                    <Table.Cell style={{ 'text-align': 'right' }}>{Utility.priceFormat(row.total)}</Table.Cell>
                </Table.Row>)}
                <Table.Row >
                    <Table.Cell style={{ 'text-align': 'right' }} colSpan='2'><b>{'รวม'}</b></Table.Cell>
                    <Table.Cell style={{ 'text-align': 'right' }}><b>{Utility.numberFormat(count)}</b></Table.Cell>
                    <Table.Cell style={{ 'text-align': 'right' }}><b>{Utility.priceFormat(total)}</b></Table.Cell>
                </Table.Row>
            </>
        );
    }
}

class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                start_date: moment(),
                end_date: moment()
            },
            group_by: 'account',
            items: [],
            total: 0,
            count: 0,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let qrstring = Utility.jsonToQueryString({
            ...search,
            group_by: this.state.group_by,
        })

        this.setState({
            loading: true,
            items: [],
            count: 0,
            total: 0,
        })

        var _data = Utility.getFetch(Settings.baseReportUrl + '/report-account-online/?' + qrstring);
        Promise.all([_data]).then((values) => {
            const { list, count, total } = values[0]
            this.setState({
                loading: false,
                items: list,
                count,
                total,
            });
        });
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานบัญชีรับเงินต่อดอก (online)';
        let filename = 'account-online-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const descriptionWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }

        let items = this.state.items

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Divider />
                            <Checkbox radio label='จัดกลุ่มด้วยเลขบัญชี' checked={this.state.group_by === 'account'} onChange={(e, v) => { this.setState({ group_by: 'account' }) }} />
                            <br />
                            <Checkbox radio label='จัดกลุ่มด้วยวันที่' checked={this.state.group_by === 'pay_date'} onChange={(e, v) => { this.setState({ group_by: 'pay_date' }) }} />
                            <br />
                            <br />

                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ : {Utility.formatDate(this.state.search.end_date)} </center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            {
                                                this.state.group_by === 'account' ?
                                                    <>
                                                        <Table.HeaderCell style={textCenter}>เลขบัญชีรับเงินต่อดอก</Table.HeaderCell>
                                                        <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                                    </> :
                                                    <>
                                                        <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                                        <Table.HeaderCell style={textCenter}>เลขบัญชีรับเงินต่อดอก</Table.HeaderCell>
                                                    </>
                                            }
                                            <Table.HeaderCell style={textCenter}>จำนวนรายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ยอดโอนเงินรวม</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {items.map((row, i) =>
                                            <GroupData key={i} value={row.value} group_by={this.state.group_by} />
                                        )}
                                        <Table.Row >
                                            <Table.Cell style={textRight} colSpan='2'><b>{'รวมทั้งหมด'}</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>{Utility.numberFormat(this.state.count)}</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>{Utility.priceFormat(this.state.total)}</b></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)