export const nationalities = [
    "อัฟกานิสถาน",
    "แอลเบเนีย",
    "แอลจีเรีย",
    "อันดอร์รา",
    "แองโกลา",
    "แอนติกาและบาร์บูดา",
    "อาร์เจนตินา",
    "อาร์เมเนีย",
    "ออสเตรเลีย",
    "ออสเตรีย",
    "อาเซอร์ไบจาน",
    "บาฮามาส",
    "บาห์เรน",
    "บังคลาเทศ",
    "บาร์เบโดส",
    "เบลารุส",
    "เบลเยียม",
    "เบลีซ",
    "เบนิน",
    "ภูฏาน",
    "โบลิเวีย",
    "บอสเนียและเฮอร์เซโกวีนา",
    "บอตสวานา",
    "บราซิล",
    "บรูไน",
    "บัลแกเรีย",
    "บูร์กินาฟาโซ",
    "บุรุนดี",
    "ไอวอรีโคสต์",
    "กัมพูชา",
    "แคเมอรูน",
    "แคนาดา",
    "เคปเวิร์ด",
    "สาธารณรัฐแอฟริกากลาง",
    "ชาด",
    "ชิลี",
    "จีน",
    "โคลอมเบีย",
    "คอโมโรส",
    "คองโก",
    "คอสตาริกา",
    "โครเอเชีย",
    "คิวบา",
    "ไซปรัส",
    "เช็ก",
    "เดนมาร์ก",
    "จิบูตี",
    "โดมินิกา",
    "สาธารณรัฐโดมินิกัน",
    "ติมอร์ตะวันออก",
    "เอกวาดอร์",
    "อียิปต์",
    "เอลซัลวาดอร์",
    "อิเควทอเรียลกินี",
    "เอริเทรีย",
    "เอสโตเนีย",
    "เอธิโอเปีย",
    "ฟิจิ",
    "ฟินแลนด์",
    "ฝรั่งเศส",
    "กาบอง",
    "แกมเบีย",
    "จอร์เจีย",
    "เยอรมนี",
    "กานา",
    "กรีซ",
    "เกรเนดา",
    "กัวเตมาลา",
    "กินี",
    "กินี-บิสเซา",
    "กายอานา",
    "เฮติ",
    "ฮอนดูรัส",
    "ฮังการี",
    "ไอซ์แลนด์",
    "อินเดีย",
    "อินโดนีเซีย",
    "อิหร่าน",
    "อิรัก",
    "ไอร์แลนด์",
    "อิสราเอล",
    "อิตาลี",
    "จาเมกา",
    "ญี่ปุ่น",
    "จอร์แดน",
    "คาซัคสถาน",
    "เคนยา",
    "คิริบาส",
    "เกาหลีเหนือ",
    "เกาหลีใต้",
    "คูเวต",
    "คีร์กีซสถาน",
    "ลาว",
    "ลัตเวีย",
    "เลบานอน",
    "เลโซโท",
    "ไลบีเรีย",
    "ลิเบีย",
    "ลิกเตนสไตน์",
    "ลิทัวเนีย",
    "ลักเซมเบิร์ก",
    "มาดากัสการ์",
    "มาลาวี",
    "มาเลเซีย",
    "มัลดีฟส์",
    "มาลี",
    "มอลตา",
    "หมู่เกาะมาร์แชลล์",
    "มอริเตเนีย",
    "มอริเชียส",
    "เม็กซิโก",
    "ไมโครนีเซีย",
    "มอลโดวา",
    "โมนาโก",
    "มองโกเลีย",
    "มอนเตเนโกร",
    "โมร็อกโก",
    "โมซัมบิก",
    "พม่า",
    "นามิเบีย",
    "นาอูรู",
    "เนปาล",
    "เนเธอร์แลนด์",
    "นิวซีแลนด์",
    "นิการากัว",
    "ไนเจอร์",
    "ไนจีเรีย",
    "นอร์ทมาซิโดเนีย",
    "นอร์เวย์",
    "โอมาน",
    "ปากีสถาน",
    "ปาเลา",
    "ปาเลสไตน์",
    "ปานามา",
    "ปาปัวนิวกินี",
    "ปารากวัย",
    "เปรู",
    "ฟิลิปปินส์",
    "โปแลนด์",
    "โปรตุเกส",
    "กาตาร์",
    "โรมาเนีย",
    "รัสเซีย",
    "รวันดา",
    "เซนต์คิตส์และเนวิส",
    "เซนต์ลูเซีย",
    "เซนต์วินเซนต์และเกรนาดีนส์",
    "ซามัว",
    "ซานมารีโน",
    "เซาตูเมและปรินซิเป",
    "ซาอุดีอาระเบีย",
    "เซเนกัล",
    "เซอร์เบีย",
    "เซเชลส์",
    "เซียร์ราลีโอน",
    "สิงคโปร์",
    "สโลวาเกีย",
    "สโลวีเนีย",
    "หมู่เกาะโซโลมอน",
    "โซมาเลีย",
    "แอฟริกาใต้",
    "เซาท์ซูดาน",
    "สเปน",
    "ศรีลังกา",
    "ซูดาน",
    "ซูรินาเม",
    "สวาซิแลนด์",
    "สวีเดน",
    "สวิตเซอร์แลนด์",
    "ซีเรีย",
    "ไต้หวัน",
    "ทาจิกิสถาน",
    "แทนซาเนีย",
    "ไทย",
    "โตโก",
    "ตองกา",
    "ตรินิแดดและโตเบโก",
    "ตูนิเซีย",
    "ตุรกี",
    "เติร์กเมนิสถาน",
    "ตูวาลู",
    "ยูกันดา",
    "ยูเครน",
    "สหรัฐอาหรับเอมิเรตส์",
    "สหราชอาณาจักร",
    "สหรัฐอเมริกา",
    "อุรุกวัย",
    "อุซเบกิสถาน",
    "วานูอาตู",
    "วาติกัน",
    "เวเนซุเอลา",
    "เวียดนาม",
    "เยเมน",
    "แซมเบีย",
    "ซิมบับเว"
];

export const thaiPrefixes = [
    "เด็กชาย", "เด็กหญิง", "นาย", "นาง", "นางสาว", "ดร.", "ศ.", "ผศ.", "รศ.",
    "นพ.", "พญ.", "อ.", "อ.ดร.", "พล.ต.", "พ.ต.", "ร.ต.", "พ.อ.", "ร.อ.",
    "พ.ท.", "ร.ท.", "ร.ต.", "ร.อ.", "พ.ท.", "ร.ท.", "พ.ต.", "พล.อ.", "พล.ท.",
    "พล.ต.อ.", "พล.ต.ท.", "พล.ต.ต.", "พ.ต.อ.", "พ.ต.ท.", "พ.ต.ต.", "ร.ต.อ.",
    "ร.ต.ท.", "ร.ต.ต.", "พล.อ.อ.", "พล.ท.อ.", "พล.ต.อ.", "น.อ.", "น.ท.",
    "น.ต.", "พ.อ.อ.", "พ.ท.อ.", "พ.ต.อ.", "ร.อ.อ.", "ร.ท.อ.", "ร.ต.อ.",
    "พล.อ.ร.", "พล.ท.ร.", "พล.ต.ร.", "น.อ.ร.", "น.ท.ร.", "น.ต.ร.", "พ.อ.ร.",
    "พ.ท.ร.", "พ.ต.ร.", "ร.อ.ร.", "ร.ท.ร.", "ร.ต.ร."
];

export function splitPrefix(name) {
    const sortedPrefixes = [...thaiPrefixes].sort((a, b) => b.length - a.length);

    for (const prefix of sortedPrefixes) {
        if (name.startsWith(prefix)) {
            return { prefix, name: name.slice(prefix.length).trim() };
        }
    }
    return { prefix: "", name }; // กรณีไม่มีคำนำหน้า
}