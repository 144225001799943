/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Image, Input, Label
} from 'semantic-ui-react';
import logo from '../../logo.png';
import Settings from '../../Settings';
import Utility from '../../Utility';

var config = require( '../../config' )

var base64 = require('base-64')

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message_error_hidden: true
    }
    
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this)
  }

  onSubmit(e) {
    e.preventDefault();
    localStorage.removeItem('noti')
    const formData = {
      username: this.state.username,
      password: this.state.password,
      grant_type: 'password',
      client_id: Settings.client_id,
      client_secret: Settings.client_secret
    };

    this.setState({
      username_error: false,
      password_error: false
    })

    if (formData.username == null || formData.username == '') {
      this.setState({
        username_error: true
      })
      this.refs.username.focus();
      return true;
    }
    if (formData.password == null || formData.password == '') {
      this.setState({
        password_error: true
      })
      this.refs.password.focus();
      return true;
    }
    this.setState({
      button_loading: true
    })
    const url = Settings.authUrl;
    var self = this;

    Utility.postBasic(url, formData, (status, data,code) => {
      if(status){
        if ( data.access_token) {
          self.setState({
            button_loading: false,
            username_error: false,
            password_error: false,
            message_error_hidden: true
          })

          localStorage.setItem("token", JSON.stringify(data));
          Utility.get(Settings.baseUrl + '/login_log/', (s, r) => {
            console.log('save log')
          });
          this.props.onAccess()
        } else if(code==400 || code==401){
          setTimeout(()=>{
            self.setState({
              button_loading: false,
              username_error: true,
              password_error: true,
              message_error_hidden: false
            })
          },1000)
        }
        
      }else{
        
        self.setState({
          button_loading: false,
          username_error: false,
          password_error: false,
          message_error_hidden: true
        })

        alert('ไม่สามารถเชื่อมต่อ Server ได้')
      }
      
    });
    
  }

  handleInput(e, v) {
    this.setState({
      [v.name]: v.value
    })
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');
    if(token){
      urlSearchParams.delete("token");
      const newUrl = window.location.pathname + "?" + urlSearchParams.toString();
      window.history.replaceState({}, "", newUrl);
      let data = {
        access_token: token,
        token_type: "Bearer",

      }
      localStorage.setItem("token", JSON.stringify(data));
      this.props.onAccess()
    }
  }

  render() {
    return (
      <div className="box-login">
        <Segment className='segment-login' >
          <Header size='huge'><center><Image className='logo-login' src={logo} size='tiny' circular /> </center></Header>
          <center><p>Sign In</p></center><br/>
          <Form onSubmit={this.onSubmit} size='small' loading={this.state.loader_active}>
            <Message hidden={this.state.message_error_hidden} negative>
              <Message.Header>ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง!</Message.Header>
            </Message>
            <Form.Field className={this.state.username_error ? 'error' : ''} >
              <Input id='userInput' placeholder='Username'
                icon='user'
                iconPosition='left'
                onChange={this.handleInput}
                ref="username" name="username" />
            </Form.Field>
            <Form.Field className={this.state.password_error ? 'error' : ''}>
              <Input id='passwordInput'type='password' placeholder='Password'
                icon='key'
                iconPosition='left'
                onChange={this.handleInput}
                ref="password" name="password" />
            </Form.Field>
            <br />
            <center>
              <Button id='btnLogin'size='small' type="submit" primary className={this.state.button_loading ? 'loading' : ''}>เข้าสู่ระบบ</Button> <br />
            </center>
          </Form>
        </Segment>
        <center>
        {config.env == 'production' ? <br /> : <Label as='i' color='blue'>{config.env}{config.rd && ' - R'}</Label>}
          {window.location.pathname.includes('/redeem') ? <Label as='i' color='red'>redeem</Label>: null}
          <Label><i>V{Settings.version}</i></Label>
        </center>
      </div>
    );
  }
}

export default Login;
