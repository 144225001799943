/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import OptionItemsCell from './OptionItemsCell'
import MsgInput from '../Error/MsgInput'
import {
  Form,
  Input,
  Button,
  Modal,
  Dimmer,
  Loader,
  Dropdown,
  Header,
  Popup,
  TextArea,
  Icon,
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { connect } from 'react-redux'

function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, format, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={
              format === 'number' ? Utility.numberFormat(data[rowIndex][field])
                : format === 'price' ? Utility.priceFormat(data[rowIndex][field])
                  : data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{
                format === 'number' ? Utility.numberFormat(data[rowIndex][field])
                  : format === 'price' ? Utility.priceFormat(data[rowIndex][field])
                    : data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class AccountStaff extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      branchOption: [],
      acc_type: '',
      promptpay: '',
      acc_number: '',
      acc_name: '',
      max_amount: '',
      max_times: '',
      is_enabled: 1,
      is_main: 0,
      description: '',
      used: {
        amount: 0,
        times: 0,
      },
      bank: '',
      banks: [
        {
          key: '002',
          value: '002',
          text: 'ธนาคารกรุงเทพ',
        },
        {
          key: '004',
          value: '004',
          text: 'ธนาคารกสิกรไทย',
        },
        {
          key: '006',
          value: '006',
          text: 'ธนาคารกรุงไทย',
        }, {
          key: '011',
          value: '011',
          text: 'ธนาคารทหารไทยธนชาต',
        }, {
          key: '014',
          value: '014',
          text: 'ธนาคารไทยพาณิชย์',
        }, {
          key: '022',
          value: '022',
          text: 'ธนาคารซีไอเอ็มบี ไทย',
        },
        {
          key: '024',
          value: '024',
          text: 'ธนาคารยูโอบี',
        }, {
          key: '025',
          value: '025',
          text: 'ธนาคารกรุงศรีอยุธยา',
        }, {
          key: '030',
          value: '030',
          text: 'ธนาคารออมสิน',
        }, {
          key: '066',
          value: '066',
          text: 'ธนาคารอิสลามแห่งประเทศไทย',
        }, {
          key: '069',
          value: '069',
          text: 'ธนาคารเกียรตินาคิน',
        },
      ],
      modal_open: false,
      msg_error: {},
      search: {}
    }
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.search = this.search.bind(this);
  }

  submitData() {

    var msg_error = {}

    if (!this.state.branch) {
      msg_error['branch'] = ['*จำเป็น']
    }

    if (!this.state.acc_type) {
      msg_error['acc_type'] = ['*จำเป็น']
    }

    if (this.state.acc_type === 'PROMPTPAY' && !this.state.promptpay) {
      msg_error['promptpay'] = ['*จำเป็น']
    }

    if (this.state.acc_type === 'BANKAC' && !this.state.bank) {
      msg_error['bank'] = ['*จำเป็น']
    }

    if (!this.state.acc_name) {
      msg_error['acc_name'] = ['*จำเป็น']
    }

    if (this.state.acc_type === 'BANKAC' && !this.state.acc_number) {
      msg_error['acc_number'] = ['*จำเป็น']
    }

    if (!this.state.max_amount || Number(this.state.max_amount) <= 0) {
      msg_error['max_amount'] = ['*จำเป็น']
    }

    if (!this.state.max_times || Number(this.state.max_times) <= 0) {
      msg_error['max_times'] = ['*จำเป็น']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error,
        button_class: ''
      })
      return;
    }

    var formData = {
      branch: this.state.branch,
      bank: this.state.bank ? this.state.bank : null,
      acc_type: this.state.acc_type,
      promptpay: this.state.promptpay,
      acc_number: this.state.acc_number,
      acc_name: this.state.acc_name,
      is_enabled: this.state.is_enabled,
      is_main: this.state.is_main === 1,
      description: this.state.description,
      max_amount: Number(this.state.max_amount),
      max_times: Number(this.state.max_times),
    };

    this.setState({ button_class: 'loading' })
    const self = this;
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/account-staff/';
      Utility.postJson(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        } else { // error
          if (data.length !== 0)
            this.setState({
              button_class: '',
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/account-staff/' + this.state.object_id + "/";
      Utility.putJson(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        } else { // error
          if (data.length !== 0)
            this.setState({
              button_class: '',
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {
    this.setState({
      branch: this.props.auth.branch.id,
      acc_type: '',
      promptpay: '',
      bank: '',
      acc_number: '',
      acc_name: '',
      max_amount: '',
      max_times: '',
      is_enabled: 1,
      is_main: 0,
      description: '',
      used: {
        amount: 0,
        times: 0,
      },
      button_class: '',
      msg_error: {}
    });
  }


  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  search() {
    console.log(this.state.search, 's')
    Utility.get(`${Settings.baseUrl}/account-staff/?branch=${this.state.search.branch || ''}&is_enabled=${this.state.search.is_enabled ? 1 : (this.state.search.is_enabled === 0 ? 0 : '')}`, (status, data) => {
      this.setState({
        items: this.setFieldValue(data),
        loader_active: false
      });
    });
  }

  componentDidMount() {
    this.setState({
      loader_active: true
    });
    this.search()
    Utility.get(Settings.baseUrl + '/branches/?is_enabled=1', (status, data) => {
      let branchOption = []
      data.forEach(b => {
        branchOption.push({
          key: b.id,
          value: b.id,
          text: b.name
        });
      });
      this.setState({
        branchOption: branchOption,
      });
    });

  }

  setFieldValue(v) {

    for (let i in v) {
      v[i].max_amount = String(v[i].max_amount).split('.')[0]
      v[i].max_times = String(v[i].max_times).split('.')[0]
      v[i].amount = `${Utility.numberFormat(v[i].max_amount)} ${v[i].used.amount ? `(ใช้แล้ว: ${Utility.priceFormat(v[i].used.amount)})` : ''}`
      v[i].times = `${Utility.numberFormat(v[i].max_times)} ${v[i].used.times ? `(ใช้แล้ว: ${Utility.numberFormat(v[i].used.times)})` : ''}`
      v[i].status = v[i].is_enabled ? 'แสดง' : 'ซ่อน'
      v[i].main = v[i].is_main ? 'ใช่' : ''
    }

    return v;
  }

  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        acc_type: data.acc_type,
        bank: data.bank,
        branch: data.branch,
        promptpay: data.promptpay,
        acc_number: data.acc_number,
        acc_name: data.acc_name,
        max_amount: data.max_amount,
        max_times: data.max_times,
        is_enabled: data.is_enabled,
        is_main: data.is_main ? 1 : 0,
        description: data.description,
        used: data.used,
        object_id: data.id
      });
    }
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>บัญชีพนักงาน</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button id='btnAddAccount' size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างบัญชีพนักงาน'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <label>สาขา</label>
              <Dropdown
                placeholder='สาขา'
                search
                selection
                defaultValue={this.state.search.branch}
                value={this.state.search.branch}
                options={[{
                  key: '',
                  value: '',
                  text: 'ทั้งหมด',
                }, ...this.state.branchOption]}
                onChange={(e, data) => {
                  this.setState({
                    search: {
                      ...this.state.search,
                      branch: data.value,
                    },
                  });
                }}
                name='branch' />
            </Form.Field>
            <Form.Field width={5}>
              <label>สถานะ</label>
              <Dropdown
                placeholder='สถานะ'
                search
                selection
                defaultValue={this.state.search.is_enabled}
                value={this.state.search.is_enabled}
                options={[{
                  key: '',
                  value: '',
                  text: 'ทั้งหมด',
                }, ...Settings.is_enabled_option]}
                onChange={(e, data) => {
                  this.setState({
                    search: {
                      ...this.state.search,
                      is_enabled: data.value,
                    },
                  });
                }}
                name='is_enabled' />
            </Form.Field>
            <Form.Field width={6}>
              <label><br /></label>
              <Button id='btnSearch' size='small' onClick={this.search} type='button'><Icon name='search' /> ค้นหา</Button>
              <Button id='btnSearchAll' size='small' onClick={() => {
                this.setState({
                  search: {},
                }, () => this.search())
              }} type='button' >ทั้งหมด</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu>
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข
          </MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='btnCloseAccount'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={Boolean(this.state.msg_error.branch)}>
                  <label>*สาขา <MsgInput text={this.state.msg_error.branch} /></label>
                  <Dropdown id='dropDownBranch'
                    placeholder='สาขา'
                    selection
                    search
                    options={this.state.branchOption}
                    value={this.state.branch}
                    defaultValue={this.state.branch}
                    onChange={(e, data) => {
                      this.setState({
                        branch: data.value,
                      });
                    }} />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.acc_type)}>
                  <label>*ประเภทบัญชี <MsgInput text={this.state.msg_error.acc_type} /></label>
                  <Dropdown
                    selection
                    width={14}
                    options={[
                      {
                        key: 'BANKAC',
                        value: 'BANKAC',
                        text: 'บัญชีธนาคาร',
                      },
                      {
                        key: 'PROMPTPAY',
                        value: 'PROMPTPAY',
                        text: 'พร้อมเพย์',
                      },
                    ]}
                    placeholder='เลือกประเภท'
                    onChange={(e, v) => {
                      this.setState({ acc_type: v.value });
                    }}
                    value={this.state.acc_type} />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.promptpay)}>
                  <label>{this.state.acc_type === 'PROMPTPAY' && '*'}เลขพร้อมเพย์ <MsgInput text={this.state.msg_error.promptpay} /></label>
                  <Input
                    onChange={(e) => this.setState({ promptpay: e.target.value })}
                    value={this.state.promptpay}
                    placeholder='เลขพร้อมเพย์'
                    maxLength={20}
                  />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.bank)}>
                  <label>{this.state.acc_type === 'BANKAC' && '*'}ธนาคาร <MsgInput text={this.state.msg_error.bank} /></label>
                  <Dropdown
                    search
                    selection
                    width={14}
                    options={this.state.banks}
                    placeholder='เลือกธนาคาร'
                    onChange={(e, v) => {
                      this.setState({ bank: v.value });
                    }}
                    value={this.state.bank} />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.acc_number)}>
                  <label>{this.state.acc_type === 'BANKAC' && '*'}เลขบัญชีธนาคาร <MsgInput text={this.state.msg_error.acc_number} /></label>
                  <Input
                    onChange={(e) => this.setState({ acc_number: e.target.value })}
                    value={this.state.acc_number}
                    placeholder='เลขบัญชีธนาคาร'
                    maxLength={20}
                  />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.acc_name)}>
                  <label>*ชื่อบัญชีธนาคาร <MsgInput text={this.state.msg_error.acc_name} /></label>
                  <Input
                    onChange={(e) => this.setState({ acc_name: e.target.value })}
                    value={this.state.acc_name}
                    placeholder='ชื่อบัญชีธนาคาร'
                    maxLength={100}
                  />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.max_amount)}>
                  <label>* จำกัดจำนวนยอดเงิน / ต่อปี {this.state.modal_action !== 'add' && `(ใช้แล้ว: ${Utility.priceFormat(this.state.used.amount)})`} <MsgInput text={this.state.msg_error.max_amount} /></label>
                  <Input
                    min={0}
                    onChange={(e) => {
                      if (String(e.target.value).includes('.')) return
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.split('.')[0].length > 12) {
                          return;
                        }
                        if (e.target.value.split('.').length != 1)
                          if (e.target.value.split('.')[1].length > 2) {
                            return;
                          }
                        this.setState({ max_amount: e.target.value })
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        this.setState({
                          focus_amount: false,
                        });
                      }, 0);
                    }}
                    onFocus={(e) => {
                      this.setState({
                        focus_amount: true,
                      });
                    }}
                    value={this.state.focus_amount ? this.state.max_amount : (this.state.max_amount ? Utility.numberFormat(this.state.max_amount) : '')}
                    placeholder='ยอดเงิน'
                    maxLength={18}
                  />
                </Form.Field>
                <Form.Field error={Boolean(this.state.msg_error.max_times)}>
                  <label>* จำกัดจำนวนโอน / ต่อปี {this.state.modal_action !== 'add' && ` (ใช้แล้ว: ${Utility.numberFormat(this.state.used.times)})`} <MsgInput text={this.state.msg_error.max_times} /></label>
                  <Input
                    min={0}
                    onChange={(e) => {
                      if (String(e.target.value).includes('.')) return
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.split('.')[0].length > 12) {
                          return;
                        }
                        if (e.target.value.split('.').length != 1)
                          if (e.target.value.split('.')[1].length > 0) {
                            return;
                          }
                        this.setState({ max_times: e.target.value })
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        this.setState({
                          focus_times: false,
                        });
                      }, 0);
                    }}
                    onFocus={(e) => {
                      this.setState({
                        focus_times: true,
                      });
                    }}
                    value={this.state.focus_times ? this.state.max_times : (this.state.max_times ? Utility.numberFormat(this.state.max_times) : '')}
                    placeholder='จำนวนโอน'
                    maxLength={18}
                  />
                </Form.Field>
                <Form.Field error={this.state.msg_error.is_main != null}>
                  <label>บัญชีหลัก <MsgInput text={this.state.msg_error.is_main} /></label>
                  <Dropdown id='inputBranchStatus' selection fluid onChange={(e, v) => this.setState({ is_main: v.value })} value={this.state.is_main} options={Settings.is_main_option} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown id='inputBranchStatus' selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} />
                </Form.Field>
                <Form.Field>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea
                    placeholder='หมายเหตุ'
                    onChange={(e) => this.setState({ description: e.target.value })}
                    value={this.state.description}
                    maxLength={255}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSaveAccount' size='small' primary onClick={this.submitData}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancelAccount' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} hide_del={true} />}
            />
            <Column
              header={<Cell>สาขา</Cell>}
              cell={
                <ItemsCell position='top left' width={100} data={items} field="branch_name" />
              }
              width={100}
            />
            <Column
              header={<Cell>ประเภทบัญชี</Cell>}
              cell={
                <ItemsCell position='top left' width={100} data={items} field="type_display" />
              }
              width={100}
            />
            <Column
              header={<Cell>ธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={120} data={items} field="bank_name" />
              }
              width={120}
            />
            <Column
              header={<Cell>ชื่อบัญชีธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={180} data={items} field="acc_name" />
              }
              width={180}
            />
            <Column
              header={<Cell>เลขบัญชีธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="acc_number" />
              }
              width={150}
            />
            <Column
              header={<Cell>เลขพร้อมเพย์</Cell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="promptpay" />
              }
              width={150}
            />
            <Column
              header={<Cell>บัญชีหลัก</Cell>}
              cell={
                <ItemsCell position='top left' width={80} data={items} field="main" />
              }
              width={80}
            />
            <Column
              header={<Cell>สถานะ</Cell>}
              cell={
                <ItemsCell position='top left' width={60} data={items} field="status" />
              }
              width={60}
            />
            <Column
              header={<Cell>จำกัดจำนวนยอดเงิน / ต่อปี</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="amount" />
              }
              width={200}
            />
            <Column
              header={<Cell>จำกัดจำนวนโอน / ต่อปี</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="times" />
              }
              width={200}
            />
            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="description" />
              }
              width={200}
            />
          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}

export default connect(
  mapStateToProps,
)(AccountStaff)
