/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,
    Input,
    Header,
    Button,
    Grid,
    Modal,
    Dimmer,
    Loader,
    TextArea,
    Label,
    Icon,
    Popup,
    Dropdown,
    Segment,
    Table as TB,
} from 'semantic-ui-react';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { Table, Column, Cell } from 'fixed-data-table';
import Utility from '../../Utility';
import Settings from '../../Settings';

import { connect } from 'react-redux'
import config from '../../config';

class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            rowIndex,
            field,
            data,
            ...props
        } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }
        return (
            <Cell {...props}>
                <Popup
                    position={this.props.position}
                    content={data[rowIndex][field]}
                    style={Width300}
                    trigger={
                        <div style={ellipsisStyle}
                            className={
                                ['cash', 'card', 'card_service', 'transfer', 'online'].includes(field) ?
                                    Number(Utility.removeCommas(data[rowIndex][field])) == 0 ? this.props.textAlign
                                        : Number(Utility.removeCommas(data[rowIndex][field])) < 0 ? this.props.textAlign + ' red'
                                            : this.props.textAlign + ' green'
                                    : this.props.textAlign
                            }
                        >{data[rowIndex][field]}</div>}
                />
            </Cell >
        );
    }
}


class LedgerBalanceModal extends Component {
    constructor(props) {
        super(props);
        this.setting = props.setting
        this.state = {
            branch: this.props.auth.branch.id,
            cash_count: 0,
            cash_diff: 0,
            cash_total: 0,
            card_total: 0,
            check_total: 0,
            transfer_total: 0,
            online_total: 0,
        }
        this.handlerUpdate = this.handlerUpdate.bind(this)
    }

    async componentDidMount() {
        var monthNamesThai = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
            "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤษจิกายน", "ธันวาคม"];
        let date_moment = new Date(this.props.date)
        this.setState({
            date_thai: "วันที่ " + date_moment.getDate() + " " + monthNamesThai[date_moment.getMonth()] + " " + (Number(date_moment.getFullYear()) + Number(543))
        })
        let date_before = new Date(this.props.date)
        date_before.setDate(date_before.getDate() - 1)
        date_before = Utility.formatDate2(date_before)
        const daily_ledger_balance_before = await Utility.getAsync(Settings.baseUrl + "/daily_ledger_balance/?date=" + date_before + "&branch=" + this.state.branch)
        const daily_ledger_balance = await Utility.getAsync(Settings.baseUrl + "/daily_ledger_balance/?date=" + Utility.formatDate2(date_moment) + "&branch=" + this.state.branch)
        const ledger = await Utility.getAsync(`${Settings.baseUrl}/ledger/?start_date=${this.props.date}&end_date=${this.props.date}&branch=${this.props.auth.branch.id}&type=${config.rd ? 'R' : ''}`)
        let logs = []
        if (daily_ledger_balance.data[0] != undefined)
            logs = await Utility.getAsync(Settings.baseUrl + "/daily_ledger_balance_log/?daily_ledger_balance=" + daily_ledger_balance.data[0].id)
        const staff = await Utility.getAsync(Settings.baseUrl + '/staffs/?is_enabled=1&branch=' + this.props.auth.branch.id);
        let staffs = []
        for (let i in staff.data) {
            staffs.push({
                value: staff.data[i].id,
                key: staff.data[i].id,
                text: staff.data[i].name
            });
        }
        this.setState({
            staffs: staffs,
            detail: this.setFieldValue(daily_ledger_balance_before.data, ledger.data),
            daily_ledger_balance: daily_ledger_balance.data,
            logs: this.setFieldValue2(logs.data, staffs),
            cash_count: this.props.action == 'update' ? 0 : daily_ledger_balance.data[0] == undefined ? 0 : daily_ledger_balance.data[0].cash,
            description: this.props.action == 'update' ? '' : daily_ledger_balance.data[0] == undefined ? '' : daily_ledger_balance.data[0].description,
            staff: this.props.action == 'update' ? null : daily_ledger_balance.data[0] == undefined ? null : daily_ledger_balance.data[0].staff,
            date: Utility.formatDate2(date_moment),
        });
        this.setState({
            cash_diff: this.props.action == 'update' ? this.state.cash_count - this.state.cash_total : daily_ledger_balance.data[0] == undefined ? 0 : daily_ledger_balance.data[0].cash_diff,
        })
    }

    handlerUpdate(e) {
        let msg_err = 0
        if (this.state.staff == null) {
            msg_err += 1
        }
        if (this.state.description == '') {
            msg_err += 2
        }
        if (msg_err != 0) {
            let msg = msg_err == 1 ? 'กรุณากรอกชื่อพนักงาน'
                : msg_err == 2 ? 'กรุณากรอกหมายเหตุ'
                    : msg_err == 3 ? 'กรุณากรอกชื่อพนักงานและหมายเหตุ' : ''
            alert(msg)
            return;
        }
        if (!window.confirm('ยืนยันส่งยอดเงินสด ณ ' + this.state.date_thai))
            return;
        var formData = {
            cash: this.state.cash_count,
            cash_diff: this.state.cash_diff,
            description: this.state.description,
            staff: this.state.staff,
            branch: this.props.auth.branch.id,
            date: this.state.date,
        };
        if (this.state.daily_ledger_balance[0] == undefined) {
            const url = Settings.baseUrl + '/daily_ledger_balance/';
            Utility.post(url, formData, (status, data) => {
                if (status) {
                    alert('ส่งยอดเงินสดสำเร็จ!')
                    this.componentDidMount()
                } else {
                    alert('ส่งยอดเงินสด ณ ' + this.state.date_thai + ' ผิดพลาด')
                }
            });
        } else {
            const url = Settings.baseUrl + '/daily_ledger_balance/' + this.state.daily_ledger_balance[0].id + "/";
            Utility.put(url, formData, (status, data) => {
                if (status) {
                    alert('ส่งยอดเงินสดสำเร็จ!')
                    this.componentDidMount()
                } else { // error
                    alert('ส่งยอดเงินสด ณ ' + this.state.date_thai + ' ผิดพลาด')
                }
            });
        }
    }

    setFieldValue(balance_before, ledger) {
        const items = []
        let cash_total = 0
        let card_total = 0
        let card_service_total = 0
        let check_total = 0
        let transfer_total = 0
        let online_total = 0
        let staff_total = 0
        if (balance_before.length != 0) {
            items.push({
                kind_title: '',
                number: '-',
                object_number: '-',
                date: Utility.formatDate(balance_before[0].date),
                time: '00:00',
                object_title: 'ยกมายอดเงินสด',
                type_pay: '',
                cash: Utility.priceFormat(balance_before[0].cash),
                card: Utility.priceFormat(0),
                check: Utility.priceFormat(0),
                transfer: Utility.priceFormat(0),
                online: Utility.priceFormat(0),
            })
            cash_total = Number(balance_before[0].cash)
        }
        for (let i in ledger) {
            let cash = ledger[i].kind == 'EX' ? - ledger[i].cash : ledger[i].cash
            let card = ledger[i].kind == 'EX' ? - ledger[i].card : ledger[i].card
            let card_service = ledger[i].kind == 'EX' ? - ledger[i].card_service : ledger[i].card_service
            let check = ledger[i].kind == 'EX' ? - ledger[i].check : ledger[i].check
            let online = ledger[i].payment === 'TF' ? ledger[i].transfer : 0
            let transfer = ledger[i].payment !== 'TF' ? ledger[i].transfer : 0
            let staff_transfer = Number(ledger[i].staff_transfer)
            cash_total = Number(cash_total) + Number(cash)
            card_total = Number(card_total) + Number(card)
            card_service_total = Number(card_service_total) + Number(card_service)
            check_total = Number(check_total) + Number(check)
            transfer_total = Number(transfer_total) + Number(transfer)
            online_total = Number(online_total) + Number(online)
            staff_total = Number(staff_total) + Number(staff_transfer)
            let type_pay = Utility.getObjectByValue(Settings.payment_option, ledger[i].payment).text
            let card_bank_card_display = ''
            if (ledger[i].card_bank_card) {
                type_pay = `${type_pay} ${ledger[i].card_bank_card.period ? ` (${ledger[i].card_bank_card.period_display})` : ''}`
                card_bank_card_display = `${ledger[i].card_bank_card.kind} - ${ledger[i].card_bank_card.bank.name}`
            }
            items.push({
                kind_title: ledger[i].kind == 'EX' ? 'รายจ่าย' : 'รายรับ',
                number: ledger[i].number,
                object_number: ledger[i].object_number,
                date: Utility.formatDate(ledger[i].record_date),
                time: Utility.formatTime(ledger[i].record_date),
                object_title: ledger[i].ledger_category.title,
                type_pay: type_pay,
                card_bank_card_display: card_bank_card_display,
                transfer_bank_account_name: ledger[i].transfer_bank_account_name,
                transfer_account_staff_name: ledger[i].transfer_account_staff_name,
                cash: Utility.priceFormat(cash),
                card: Utility.priceFormat(card),
                card_service: Utility.priceFormat(card_service),
                check: Utility.priceFormat(check),
                transfer: Utility.priceFormat(transfer),
                online: Utility.priceFormat(online),
                staff_transfer: staff_transfer ? Utility.priceFormat(staff_transfer) : '',
            })
        }
        this.setState({
            cash_total,
            card_total,
            card_service_total,
            check_total,
            transfer_total,
            online_total,
            staff_total,
        })
        return items;
    }

    setFieldValue2(logs, staffs) {
        const items = []
        for (let i in logs) {
            items.push({
                record_date: Utility.formatDate(logs[i].record_date),
                time: Utility.formatTime(logs[i].record_date),
                cash: Utility.priceFormat(logs[i].cash),
                cash_diff: Utility.priceFormat(logs[i].cash_diff),
                description: logs[i].description,
                staff_name: logs[i].staff == null ? 'ระบบอัตโนมัติ' : Utility.getObjectByValue(staffs, logs[i].staff).text,
            })
        }
        return items;
    }

    render() {
        let items = this.state.detail == undefined ? [] : this.state.detail
        console.log(items)
        let items2 = this.state.logs == undefined ? [] : this.state.logs

        return (

            <Modal id='ModalLedgerBalance' size="fullscreen" open={true} >
                <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={(this.props.onClose)} />

                <Modal.Header>
                    <Header as='h4'>
                        <Header.Content>
                            สรุปยอดเงินสดคงเหลือ ณ {this.state.date_thai}
                        </Header.Content>
                    </Header>
                </Modal.Header>
                <Modal.Content className='scrolling2 '>
                    <div >
                        <Dimmer active={this.props.loading || this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <div>
                            <Form className='attached fluid'>
                                <Grid >
                                    <Grid.Row >
                                        <Grid.Column width={10}>
                                            <div id="table_width">
                                                <h3>รายการรายรับ-รายจ่าย</h3>
                                                <Table
                                                    rowsCount={items.length}
                                                    rowHeight={35}
                                                    headerHeight={35}
                                                    width={(window.innerWidth * 0.62) - 36}
                                                    height={650}>
                                                    <Column
                                                        header={< Cell className='text-center' > ประเภท</Cell >}
                                                        cell={< ItemsCell
                                                            position='top center'
                                                            width={80}
                                                            data={
                                                                items
                                                            }
                                                            field="kind_title"
                                                            textAlign='text-center'
                                                        />}
                                                        width={80}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-center' > เลขที่ใบสำคัญ</Cell >}
                                                        cell={< ItemsCell
                                                            position='top center'
                                                            width={120}
                                                            data={
                                                                items
                                                            }
                                                            field="number"
                                                            textAlign='text-center'
                                                        />}
                                                        width={120}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-center' > เลขที่อ้างอิง</Cell >}
                                                        cell={< ItemsCell
                                                            position='top center'
                                                            width={120}
                                                            data={
                                                                items
                                                            }
                                                            field="object_number"
                                                            textAlign='text-center'
                                                        />}
                                                        width={120}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-center' > วันที่</Cell >}
                                                        cell={< ItemsCell
                                                            position='top center'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="date"
                                                            textAlign='text-center'
                                                        />}
                                                        width={100}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-center' > เวลา</Cell >}
                                                        cell={< ItemsCell
                                                            position='top center'
                                                            width={50}
                                                            data={
                                                                items
                                                            }
                                                            field="time"
                                                            textAlign='text-center'
                                                        />}
                                                        width={50}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-center' > รายการ</Cell >}
                                                        cell={< ItemsCell
                                                            position='top center'
                                                            width={120}
                                                            data={
                                                                items
                                                            }
                                                            field="object_title"
                                                            textAlign='text-center'
                                                        />}
                                                        width={120}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-left' > ประเภทชำระ</Cell >}
                                                        cell={< ItemsCell
                                                            position='top left'
                                                            width={180}
                                                            data={
                                                                items
                                                            }
                                                            field="type_pay"
                                                            textAlign='text-left'
                                                        />}
                                                        width={180}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-left' > บัญชีธนาคาร</Cell >}
                                                        cell={< ItemsCell
                                                            position='top left'
                                                            width={180}
                                                            data={
                                                                items
                                                            }
                                                            field="transfer_bank_account_name"
                                                            textAlign='text-left'
                                                        />}
                                                        width={180}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-left' > ประเภทบัตรเครดิต</Cell >}
                                                        cell={< ItemsCell
                                                            position='top left'
                                                            width={180}
                                                            data={
                                                                items
                                                            }
                                                            field="card_bank_card_display"
                                                            textAlign='text-left'
                                                        />}
                                                        width={180}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-right' > เงินสด</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="cash"
                                                            textAlign='text-right'
                                                        />}
                                                        width={100}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-right' >เงินโอน</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="transfer"
                                                            textAlign='text-right'
                                                        />}
                                                        width={100}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-right' > บัตร</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="card"
                                                            textAlign='text-right'
                                                        />}
                                                        width={100}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-right' >ค่าธรรมเนียม</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="card_service"
                                                            textAlign='text-right'
                                                        />}
                                                        width={100}
                                                    />
                                                    <Column
                                                        header={< Cell className='text-right' >ต่อดอกออนไลน์</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="online"
                                                            textAlign='text-right'
                                                        />}
                                                        width={100}
                                                    />
                                                    {!config.rd && <Column
                                                        header={< Cell className='text-right' >ยอดโอนเงินบัญชีพนักงาน</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={180}
                                                            data={
                                                                items
                                                            }
                                                            field="staff_transfer"
                                                            textAlign='text-right'
                                                        />}
                                                        width={180}
                                                    />}
                                                    {!config.rd && <Column
                                                        header={< Cell className='text-left' >บัญชีโอนเงินบัญชีพนักงาน</Cell >}
                                                        cell={< ItemsCell
                                                            position='top left'
                                                            width={200}
                                                            data={
                                                                items
                                                            }
                                                            field="transfer_account_staff_name"
                                                            textAlign='text-left'
                                                        />}
                                                        width={200}
                                                    />}
                                                    {/* <Column
                                                        header={< Cell className='text-right' > เช็ค</Cell >}
                                                        cell={< ItemsCell
                                                            position='top right'
                                                            width={100}
                                                            data={
                                                                items
                                                            }
                                                            field="check"
                                                            textAlign='text-right'
                                                        />}
                                                        width={100}
                                                    /> */}
                                                </Table>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Segment>
                                                <Grid >
                                                    <Grid.Row style={{
                                                        'text-align': 'center',
                                                        'padding': '10px 0px 5px 0px',
                                                    }}>
                                                        <Grid.Column >
                                                            <h3>สรุปยอดเงินประจำวัน</h3><small>{this.state.date_thai}</small>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{
                                                        'text-align': 'right',
                                                        'padding': '5px 0px 5px 0px',
                                                    }}>
                                                        <Grid.Column width={8}>
                                                            ยอดเงินสด
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            {Utility.priceFormat(this.state.cash_total)}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{
                                                        'text-align': 'right',
                                                        'padding': '5px 0px 5px 0px',
                                                    }}>
                                                        <Grid.Column width={8}>
                                                            ยอดเงินโอน
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            {Utility.priceFormat(this.state.transfer_total)}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{
                                                        'text-align': 'right',
                                                        'padding': '5px 0px 5px 0px',
                                                    }}>
                                                        <Grid.Column width={8}>
                                                            ยอดบัตร+ค่าธรรมเนียม
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            {Utility.priceFormat(this.state.card_total + this.state.card_service_total)}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{
                                                        'text-align': 'right',
                                                        'padding': '5px 0px 5px 0px',
                                                    }}>
                                                        <Grid.Column width={8}>
                                                            ยอดต่อดอกออนไลน์
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            {Utility.priceFormat(this.state.online_total)}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {!config.rd && <Grid.Row style={{
                                                        'text-align': 'right',
                                                        'padding': '5px 0px 5px 0px',
                                                    }}>
                                                        <Grid.Column width={8}>
                                                            ยอดเงินโอนเงินบัญชีพนักงาน
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            {Utility.priceFormat(this.state.staff_total)}
                                                        </Grid.Column>
                                                    </Grid.Row>}
                                                    {/* <Grid.Row style={{
                                                        'text-align': 'right',
                                                        'padding': '5px 0px 10px 0px',
                                                    }}> */}
                                                    {/* <Grid.Column width={8}>
                                                            ยอดเช็ค
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            {Utility.priceFormat(this.state.check_total)}
                                                        </Grid.Column> */}
                                                    {/* </Grid.Row> */}
                                                </Grid>
                                            </Segment>
                                            <Grid >
                                                <Grid.Row style={{
                                                    'text-align': 'right',
                                                    'padding-bottom': '2px',
                                                }}>
                                                    <Grid.Column width={8}>
                                                        ยอดเงินสดที่นับได้
                                                    </Grid.Column>
                                                    <Grid.Column width={7}>
                                                        <Input
                                                            fluid
                                                            onFocus={() => {
                                                                if (this.props.action == 'update')
                                                                    if (!this.state.focus) {
                                                                        this.setState({
                                                                            focus: true,
                                                                        });
                                                                    }
                                                            }}
                                                            onBlur={() => {
                                                                setTimeout(() => {
                                                                    if (this.state.focus) {
                                                                        this.setState({
                                                                            focus: false,
                                                                        });
                                                                    }
                                                                }, 0);
                                                            }}
                                                            onChange={(e) => {
                                                                if (isNaN(Number(e.target.value))) {
                                                                } else {
                                                                    let cash_count = e.target.value
                                                                    if (cash_count.split('.')[0].length > 10) {
                                                                        return;
                                                                    }

                                                                    if (cash_count.split('.').length != 1)
                                                                        if (cash_count.split('.')[1].length > 2) {
                                                                            return;
                                                                        }
                                                                    this.setState({
                                                                        cash_count: e.target.value,
                                                                        cash_diff: Number(e.target.value - this.state.cash_total).toFixed(2),
                                                                    });
                                                                }
                                                            }}
                                                            className='text-right'
                                                            readOnly={this.props.action == 'check'}
                                                            value={this.state.focus ? this.state.cash_count.toString().replace(/,/g, '') : this.state.cash_count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                        ></Input>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{
                                                    'text-align': 'right',
                                                    'padding': '2px 0px 2px 0px',
                                                }}>
                                                    <Grid.Column width={8}>
                                                        ความคลาดเคลื่อน
                                                    </Grid.Column>
                                                    <Grid.Column width={7}>
                                                        <Input
                                                            fluid
                                                            readOnly
                                                            color='green'
                                                            className='text-right green'
                                                            value={Utility.priceFormat(this.state.cash_diff)}
                                                        ></Input>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{
                                                    'text-align': 'right',
                                                    'padding-top': '2px',
                                                }}>
                                                    <Grid.Column width={8}>
                                                        พนักงาน
                                                    </Grid.Column>
                                                    <Grid.Column width={7}>
                                                        {this.props.action == 'update' ?
                                                            <Dropdown
                                                                placeholder='พนักงาน'
                                                                fluid
                                                                search selection
                                                                options={this.state.staffs}
                                                                value={this.state.staff}
                                                                onChange={(e, v) => {
                                                                    this.setState({
                                                                        staff: v.value
                                                                    })
                                                                }}
                                                            />
                                                            : <Input
                                                                fluid
                                                                readOnly
                                                                value={this.state.staff == null ?
                                                                    'ระบบอัตโนมัติ'
                                                                    : this.state.staffs == undefined ? ''
                                                                        : Utility.getObjectByValue(this.state.staffs, this.state.staff) == undefined ? ''
                                                                            : Utility.getObjectByValue(this.state.staffs, this.state.staff).text}
                                                            />
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <br />
                                            <Grid>
                                                <Grid.Row style={{
                                                    'padding': '2px',
                                                }}>
                                                    <Grid.Column>
                                                        หมายเหตุ <small style={{ 'color': 'red' }}>*อธิบายความคลาดเคลื่อน</small>
                                                        <TextArea
                                                            placeholder='หมายเหตุ'
                                                            value={this.state.description}
                                                            maxLength={255}
                                                            readOnly={this.props.action == 'check'}
                                                            onChange={(e) => this.setState({ description: e.target.value })}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                {this.props.action == 'update' ?
                                                    <Grid.Row style={{
                                                        'padding': '8px',
                                                    }}>
                                                        <Grid.Column>
                                                            <Button primary size='small'
                                                                style={{ 'display': 'flex', 'margin': 'auto', }}
                                                                onClick={this.handlerUpdate}
                                                                content='ส่งยอดเงิน' />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    : ''}
                                            </Grid>
                                            <Grid>
                                                <Grid.Row style={{
                                                    'padding': '2px',

                                                }}>
                                                    <Grid.Column>
                                                        <label>ประวัติ</label>
                                                        <div id="table_width2">
                                                            <Table
                                                                rowsCount={items2.length}
                                                                rowHeight={35}
                                                                headerHeight={35}
                                                                width={(window.innerWidth * 0.36) - 14}
                                                                height={200}>
                                                                <Column
                                                                    header={< Cell className='text-center' > วันที่</Cell >}
                                                                    cell={< ItemsCell
                                                                        position='top center'
                                                                        width={100}
                                                                        data={
                                                                            items2
                                                                        }
                                                                        field="record_date"
                                                                        textAlign='text-center'
                                                                    />}
                                                                    width={100}
                                                                />
                                                                <Column
                                                                    header={< Cell className='text-center' > เวลา</Cell >}
                                                                    cell={< ItemsCell
                                                                        position='top center'
                                                                        width={50}
                                                                        data={
                                                                            items2
                                                                        }
                                                                        field="time"
                                                                        textAlign='text-center'
                                                                    />}
                                                                    width={50}
                                                                />
                                                                <Column
                                                                    header={< Cell className='text-right' >  ยอดเงินสดคงเหลือ</Cell >}
                                                                    cell={< ItemsCell
                                                                        position='top right'
                                                                        width={150}
                                                                        data={
                                                                            items2
                                                                        }
                                                                        field="cash"
                                                                        textAlign='text-right'
                                                                    />}
                                                                    width={150}
                                                                />
                                                                <Column
                                                                    header={< Cell className='text-center' > โดย</Cell >}
                                                                    cell={< ItemsCell
                                                                        position='top center'
                                                                        width={180}
                                                                        data={
                                                                            items2
                                                                        }
                                                                        field="staff_name"
                                                                        textAlign='text-center'
                                                                    />}
                                                                    width={180}
                                                                />
                                                                <Column
                                                                    header={< Cell className='text-left' > หมายเหตุ</Cell >}
                                                                    cell={< ItemsCell
                                                                        position='top left'
                                                                        width={200}
                                                                        data={
                                                                            items2
                                                                        }
                                                                        field="description"
                                                                        textAlign='text-left'
                                                                    />}
                                                                    width={200}
                                                                />
                                                            </Table>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row >
                                </Grid>
                            </Form>
                        </div>
                    </div>

                </Modal.Content>
            </Modal >
        );
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}

export default connect(mapStateToProps)(LedgerBalanceModal)
