/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,
    Button,
    Dropdown,
    Modal,
    Input,
    TextArea,
    Label,
    Icon,
    Checkbox,
    Menu,
    Segment,
    Confirm
} from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import openSocket from 'socket.io-client';
import MsgInput from '../Error/MsgInput'
import Settings from '../../Settings';
import Utility from '../../Utility';
import moment from 'moment';
import { splitPrefix, thaiPrefixes } from '../../constant';
import { nationalities } from '../../constant';

class Customer extends Component {

    constructor(props) {
        super(props);

        this.modal_open = false;
        this.ref_list = []

        let customer = props.customer

        this.state = {
            message_error_hidden: true,
            action: 'add',
            modal_title: 'สร้างลูกค้า',
            prefix: '',
            name: '',
            description: '',
            mobile: '',
            email: '',
            citizen_id: '',
            button_class: '',
            search: {},
            msg_error: {},
            is_application: true,
            activeItem: 'profile',
            address_primary: 2,
            birth_date: '',
            is_reader: true,
            card_status: '',
        }

        if (props.action != 'add') {
            this.state['modal_title'] = 'แก้ไข'
            for (let i in customer) {


                this.state[i] = customer[i]
                if (i == 'birth_date') {

                    if (customer[i] == null)
                        this.state[i] = ''
                    else
                        this.state.birth_date = moment(this.state.birth_date)
                }
            }
        }


        this.handlerSubmitForm = this
            .handlerSubmitForm
            .bind(this)
        // this.socket = openSocket('ws://localhost:3333');
        // this.socketConnect()
        let temp_this = this
        this.setStateCard(0)
        this.timerId = setInterval(function () {
            temp_this.socketConnect();
        }, 1500);

        this.handleItemClick = this.handleItemClick.bind(this)
    }

    socketConnect() {
        this.socket = new WebSocket('ws://localhost:3333');
        let temp_this = this

        this.socket.onmessage = function (event) {
            let data = JSON.parse(event.data)
            let card_status = data['data']['card_status']
            if (card_status == temp_this.state.card_status) {
                return
            }
            console.log(data['data'])
            if (card_status == 'connect') {
                temp_this.setStateCard(1)
            }
            if (card_status == 'disconnect') {
                temp_this.setStateCard(0)
            }
            if (card_status == 'card-inserted') {
                temp_this.setStateCard(2)
                data['data']['data']['birth_date'] = moment(data['data']['data']['birth_date'])
                const full_name = splitPrefix(data['data']['data']['name'])
                data['data']['data']['name'] = full_name.name
                data['data']['data']['prefix'] = full_name.prefix
                data['data']['data']['nationality'] = 'ไทย'
                data['data']['data']['ethnicity'] = 'ไทย'
                temp_this.setState(data['data']['data'])
            }
            temp_this.setState({ 'card_status': card_status })

        }
        this.socket.onopen = function () {
            temp_this.socket.send('check_smartcard')
        }
        this.socket.onclose = function () {

        }
        this.socket.onerror = function () {
            console.log('An error occur when tring to connect smartcard reader')
            temp_this.setStateCard(0)
            temp_this.setState({ 'card_status': '' })
            // clearInterval(temp_this.timerId)
        }
    }


    componentDidMount() {
        this.setStateCard(0)
        let system_setting = JSON.parse(localStorage.getItem('system_setting'))
        system_setting = system_setting.filter(setting => setting.setting == 'CUSF')
        if (system_setting.length == 0) {
            Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
                for (let i in data) {
                    if (data[i].setting == 'CUSF') {
                        this.sys_setting_id = data[i].id
                        this.setState({
                            setting: JSON.parse(data[i].value)
                        })
                        break
                    }
                }
            });
        } else {
            this.sys_setting_id = system_setting[0].id
            this.setState({
                setting: JSON.parse(system_setting[0].value)
            })
        }

        Utility.get(Settings.baseUrl + '/career/?is_enabled=1', (status, data) => {
            let choice = []
            for (let i in data) {
                let choice_dict = {}
                let temp_data = data[i]

                choice_dict['key'] = temp_data.id
                choice_dict['value'] = temp_data.id
                choice_dict['text'] = temp_data.name

                choice.push(choice_dict)
            }
            this.setState({
                'career_option': choice
            })
        });

        Utility.get(Settings.baseUrl + '/workplace/?is_enabled=1', (status, data) => {
            let choice = []
            for (let i in data) {
                let choice_dict = {}
                let temp_data = data[i]

                choice_dict['key'] = temp_data.id
                choice_dict['value'] = temp_data.id
                choice_dict['text'] = temp_data.name

                choice.push(choice_dict)
            }
            this.setState({
                'workplace_option': choice
            })
        });

        Utility.get(Settings.baseUrl + '/staffs/?is_enabled=1', (status, data) => {
            let choice = []
            for (let i in data) {
                let choice_dict = {}
                let temp_data = data[i]

                choice_dict['key'] = temp_data.id
                choice_dict['value'] = temp_data.id
                choice_dict['text'] = temp_data.name

                choice.push(choice_dict)
            }
            this.setState({
                'staff_option': choice
            })
        });

        this.nextRef(0)
    }
    componentWillUnmount() {
        console.log('socket close')
        clearInterval(this.timerId)
    }
    resetForm() {
        this.setState({
            prefix: '',
            name: '',
            description: '',
            mobile: '',
            email: '',
            citizen_id: '',
            button_class: '',
            search: {},
            msg_error: {},
            is_application: true
        });
    }

    handleItemClick(e, v) {
        this.setState({
            activeItem: v.value
        })
    }

    handlerSubmitForm(e) {
        e.preventDefault();
        var formData = {
            prefix: this.state.prefix,
            name: this.state.name,
            mobile: this.state.mobile,
            email: this.state.email,
            description: this.state.description,
            citizen_id: this.state.citizen_id,
            is_application: this.state.is_application,
            address: this.state.address,
            address2: this.state.address2,
            address3: this.state.address3,
            district: this.state.district,
            district2: this.state.district2,
            district3: this.state.district3,
            city: this.state.city,
            city2: this.state.city2,
            city3: this.state.city3,
            province: this.state.province,
            province2: this.state.province2,
            province3: this.state.province3,
            postal_code: this.state.postal_code,
            postal_code2: this.state.postal_code2,
            postal_code3: this.state.postal_code3,
            address_primary: this.state.address_primary,
            is_employee: this.state.is_employee,
            staff: this.state.is_employee ? this.state.staff : null,
        };

        let setting = this.state.setting
        for (let i in setting) {
            const o = setting[i]
            if (this.state[o.key] && o.check)
                formData[o.key] = this.state[o.key]

            if (o.key == 'birth_date') {
                if (formData[o.key] != '' && formData[o.key] != null)
                    formData[o.key] = moment(formData[o.key]).format('YYYY-MM-DD')
                else
                    delete formData[o.key]
            }
        }
        const self = this;
        this.setState({ button_class: 'loading' })
        if (this.props.action == 'add') {
            const url = Settings.baseUrl + '/customers/';
            Utility.post(url, formData, (status, data) => {
                this.setState({ button_class: '' })

                if (status) {

                    alert('บันทึกข้อมูลสำเร็จ!')
                    this.resetForm()
                    this.props
                        .onClose();
                    this.props
                        .onCreate(data);
                    self.resetForm();
                } else {
                    if (data.length !== 0)
                        if (data['email']) {

                            if (data['email'][0] == "customer และ email มีอยู่แล้ว") {
                                data['email'] = ' *มีอีเมลนี้อยู่ในระบบเเล้ว'
                            } else {
                                data['email'] = ' *' + data['email']
                            }
                        }
                    if (data['mobile']) {

                        if (data['mobile'][0] == "customer และ mobile มีอยู่แล้ว") {
                            data['mobile'] = ' *มีเบอร์มือถือนี้อยู่ในระบบแล้ว'
                        } else {
                            data['mobile'] = ' *' + data['mobile']
                        }
                    }
                    if (data['staff']) {
                        if (data['staff'][0] == "This field must be unique.") {
                            data['staff'] = ' พนักงานนี้ถูกใช้แล้ว'
                        }
                    }
                    this.setState({ msg_error: data })
                    if (data.error) {
                        alert(data.error)
                    }
                }
            });
        } else {
            const url = Settings.baseUrl + '/customers/' + this.state.id + "/";
            Utility.put(url, formData, (status, data) => {
                this.setState({ button_class: '' })
                if (status) {
                    this.setState({ button_class: '', msg_error: {} })
                    alert('บันทึกข้อมูลสำเร็จ!')
                    this.resetForm()
                    this.props
                        .onUpdate(data);
                } else {
                    if (data.length !== 0)
                        if (data['email']) {
                            if (data['email'][0] == "customer และ email มีอยู่แล้ว") {
                                data['email'] = ' *มีอีเมลนี้อยู่ในระบบเเล้ว'
                            } else {
                                data['email'] = ' *' + data['email']
                            }
                        }
                    if (data['staff']) {
                        if (data['staff'][0] == "This field must be unique.") {
                            data['staff'] = ' พนักงานนี้ถูกใช้แล้ว'
                        }
                    }
                    this.setState({ msg_error: data })
                    if (data.error) {
                        alert(data.error)
                    }
                }
            });
        }
    }

    handlerStateModal() {

        this.modal_open = this.props.open;
    }

    setStateCard(action) {
        let state = {}
        if (action == 0) {
            state['smc_message'] = 'ไม่สามารถเชื่อมต่อกับอุปกรณ์ Smart Card'
            state['smc_color'] = ''
        } else if (action == 1) {
            state['smc_message'] = 'กรุณาเสียบบัตรประชาชนอีกครั้ง'
            state['smc_color'] = 'yellow'
        } else if (action == 2) {
            state['smc_message'] = 'อ่านข้อมูลบัตรแล้ว'
            state['smc_color'] = 'green'
        }

        this.setState(state)
    }

    nextRef(index) {

        if (index == this.ref_list.length - 1) {
            this.handlerSubmitForm()
            return false;
        }

        let ref = this.ref_list[index]
        if (ref != null && ref.focus != null)
            ref.focus()
    }

    render() {

        const { activeItem } = this.state

        if (this.props.action == 'add')
            this.handlerStateModal()

        let setting = []
        for (let i in this.state.setting) {
            if (this.state.setting[i].check)
                setting.push(this.state.setting[i])
        }

        var stateOptions = [{ key: '1', value: 1, text: 'ที่อยู่ 1' }, { key: '2', value: 2, text: 'ที่อยู่จัดส่งเอกสาร/ของรางวัล' }]
        return (
            <div>
                <Modal id="AddCustomerModal" open={this.props.open} size='tiny' /*dimmer='blurring'*/
                >
                    <Button id="CloseAddCustomer"
                        circular
                        icon='close'
                        basic
                        floated='right'
                        name=''
                        onClick={(e) => {
                            //this.socket.close()
                            this.resetForm()
                            this.props.onClose(e)
                        }} />
                    <Modal.Header>{this.state.modal_title}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>

                            <Form size='small'>
                                {/* <Form.Field error={this.state.msg_error.address_primary != null}>
                                    <label>ที่อยู่หลัก</label>

                                    <Dropdown value={this.state.address_primary} search selection options={stateOptions} onChange={(e, v) => { this.setState({ address_primary: v.value }) }} />
                                </Form.Field> */}
                                <Form.Field error={this.state.msg_error.is_application != null}>
                                    <label>{this.state.msg_error.is_application}<MsgInput text={this.state.msg_error.is_application} /></label>

                                    <Checkbox
                                        label='ไม่ประสงค์จะใช้งานแอพพลิเคชั่นมือถือ'
                                        name='is_application'
                                        checked={!this.state.is_application}
                                        onChange={(e, v) => this.setState({
                                            is_application: !v.checked
                                        })} />
                                </Form.Field>
                                <Menu attached='top' tabular>
                                    <Menu.Item
                                        name='ข้อมูลส่วนตัว'
                                        value='profile'
                                        active={activeItem == 'profile'}
                                        onClick={this.handleItemClick} />
                                    <Menu.Item value='address2'
                                        active={activeItem == 'address2'}
                                        onClick={this.handleItemClick} >
                                        ที่อยู่จัดส่งเอกสาร/ของรางวัล </Menu.Item>

                                    {/* <Menu.Item
                                        name='ที่อยู่ 3'
                                        value='address3'
                                        active={activeItem == 'address3'}
                                        onClick={this.handleItemClick} /> */}
                                </Menu>
                                <Segment attached='bottom'>


                                    {activeItem == 'profile' && <div>
                                        <Form.Field>
                                            <Label basic as='div' color={this.state.smc_color}><Icon name="id card" size="large" /> {this.state.smc_message}</Label>
                                        </Form.Field>

                                        {this.props.action == 'edit' && <Form.Field error={this.state.msg_error.code != null}>
                                            <label>รหัสลูกค้า {this.state.msg_error.code}<MsgInput text={this.state.msg_error.code} /></label>
                                            <Input readOnly={true} value={this.state.code} />
                                        </Form.Field>}
                                        <Form.Field error={this.state.msg_error.prefix != null}>
                                            <label>{'คำนำหน้า'}<MsgInput text={this.state.msg_error.prefix} /></label>
                                            <Dropdown
                                                options={thaiPrefixes.map((p) => {
                                                    return {
                                                        value: p,
                                                        key: p,
                                                        text: p,
                                                    }
                                                })}
                                                search
                                                selection
                                                name='prefix'
                                                placeholder='คำนำหน้า'
                                                style={{ 'width': '100%' }}
                                                onChange={(e, v) => {
                                                    this.setState({
                                                        prefix: v.value,
                                                    })
                                                }}
                                                value={this.state.prefix} />
                                        </Form.Field>
                                        <Form.Field error={this.state.msg_error.name != null}>
                                            <label>ชื่อ <MsgInput text={this.state.msg_error.name} /></label>
                                            <Input
                                                ref={(c) => this.ref_list[0] = c}
                                                placeholder='ชื่อ'
                                                position={0}
                                                onKeyPress={(e) => {
                                                    if (e.key == 'Enter') {
                                                        this.nextRef(1)
                                                        e.preventDefault()
                                                    }
                                                }}
                                                maxLength={100}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                value={this.state.name} />
                                        </Form.Field>
                                        <Form.Field error={this.state.msg_error.mobile != null}>
                                            <label>เบอร์มือถือ
                                                <MsgInput text={this.state.msg_error.mobile} /></label>
                                            <Input
                                                onKeyPress={(e) => {
                                                    if (e.key == 'Enter') {
                                                        this.nextRef(2)
                                                        e.preventDefault()
                                                    }
                                                }}
                                                ref={(c) => this.ref_list[1] = c}
                                                placeholder='เบอร์มือถือ'
                                                position={1}
                                                maxLength={10}
                                                onChange={(e) => {
                                                    if (/^[0-9]*$/.test(e.target.value)) {
                                                        this.setState({
                                                            mobile: e.target.value
                                                        })
                                                    }
                                                }}
                                                value={this.state.mobile} />
                                        </Form.Field>
                                        <Form.Field error={this.state.msg_error.email != null}>
                                            <label>อีเมล
                                                <MsgInput text={this.state.msg_error.email} /></label>
                                            <Input
                                                onKeyPress={(e) => {
                                                    if (e.key == 'Enter') {
                                                        this.nextRef(3)
                                                        e.preventDefault()
                                                    }
                                                }}
                                                ref={(c) => this.ref_list[2] = c}
                                                placeholder='อีเมล'
                                                position={2}
                                                maxLength={100}
                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                value={this.state.email} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>เลขประจำตัวประชาชน</label>
                                            <Input
                                                onKeyPress={(e) => {
                                                    if (e.key == 'Enter') {
                                                        this.nextRef(4)
                                                        e.preventDefault()
                                                    }
                                                }}
                                                ref={(c) => this.ref_list[3] = c}
                                                placeholder='เลขประจำตัวประชาชน'
                                                position={3}
                                                maxLength={13}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if (value.match((/^([A-Za-z0-9])*$/i))) {
                                                        this.setState({ citizen_id: e.target.value })
                                                    }

                                                }}
                                                value={this.state.citizen_id} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>ที่อยู่</label>
                                            <Input
                                                onChange={(e) => this.setState({ address: e.target.value })}
                                                placeholder='ที่อยู่'
                                                value={this.state.address}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        {setting.map((row, i) => <Form.Field key={i} error={this.state.msg_error[row.key] != null}>
                                            <label>{row.label}
                                                <MsgInput text={this.state.msg_error[row.key]} /></label>
                                            {row.options
                                                ? <Dropdown
                                                    options={row.options}
                                                    search
                                                    selection
                                                    placeholder='เพศ'
                                                    name={row.key}
                                                    onChange={(e, v) => {
                                                        this.setState({
                                                            [v.name]: v.value
                                                        })
                                                    }}
                                                    value={this.state[row.key]} />
                                                : <div>
                                                    {row.key == 'birth_date'
                                                        ? <>
                                                            <DatePicker
                                                                customInput={<input onKeyPress={(e) => {

                                                                    if (e.key == 'Enter') {
                                                                        this.nextRef(5 + i)
                                                                        e.preventDefault()
                                                                    }
                                                                }} />}
                                                                ref={(c) => this.ref_list[4 + i] = c}
                                                                dateFormat="DD/MM/YYYY"
                                                                placeholderText='วันเกิด'
                                                                selected={this.state.birth_date}
                                                                onChange={(date) => {
                                                                    this.setState({
                                                                        [row.key]: date
                                                                    })
                                                                }} />
                                                                {this.state.birth_date && <label style={{marginLeft: '8px'}}>อายุ {Utility.getAge(this.state.birth_date)} ปี</label>}
                                                        </>
                                                        : row.key == 'postal_code' ?
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    if (e.key == 'Enter') {
                                                                        this.nextRef(5 + i)
                                                                        e.preventDefault()
                                                                    }
                                                                }}
                                                                ref={(c) => this.ref_list[4 + i] = c}
                                                                position={3 + i}
                                                                placeholder='รหัสไปรษณีย์'
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        [row.key]: e.target.value
                                                                    })
                                                                }}
                                                                maxLength={10}
                                                                value={this.state[row.key]} />
                                                            : row.key == 'phone' ?
                                                                <Input
                                                                    onKeyPress={(e) => {
                                                                        if (e.key == 'Enter') {
                                                                            this.nextRef(5 + i)
                                                                            e.preventDefault()
                                                                        }
                                                                    }}
                                                                    placeholder='เบอร์โทร'
                                                                    ref={(c) => this.ref_list[4 + i] = c}
                                                                    position={3 + i}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            [row.key]: e.target.value
                                                                        })

                                                                    }}
                                                                    maxLength={20}
                                                                    value={this.state[row.key]} />
                                                                : row.key == 'career' ?
                                                                    <Dropdown
                                                                        options={this.state.career_option}
                                                                        search
                                                                        selection
                                                                        name={row.key}
                                                                        placeholder='อาชีพ'
                                                                        style={{ 'width': '100%' }}
                                                                        onChange={(e, v) => {
                                                                            this.setState({
                                                                                [v.name]: v.value
                                                                            })
                                                                        }}
                                                                        value={this.state[row.key]} />
                                                                    : row.key == 'workplace' ?
                                                                        <Dropdown
                                                                            options={this.state.workplace_option}
                                                                            search
                                                                            selection
                                                                            name={row.key}
                                                                            placeholder='สถานที่ทำงาน'
                                                                            style={{ 'width': '100%' }}
                                                                            onChange={(e, v) => {
                                                                                this.setState({
                                                                                    [v.name]: v.value
                                                                                })
                                                                            }}
                                                                            value={this.state[row.key]} />
                                                                        : row.key == 'nationality' ?
                                                                            <Dropdown
                                                                                options={nationalities.map((nation) => {
                                                                                    return {
                                                                                        value: nation,
                                                                                        key: nation,
                                                                                        text: nation,
                                                                                    }
                                                                                })}
                                                                                search
                                                                                selection
                                                                                name={row.key}
                                                                                placeholder='สัญชาติ'
                                                                                style={{ 'width': '100%' }}
                                                                                onChange={(e, v) => {
                                                                                    this.setState({
                                                                                        [v.name]: v.value,
                                                                                        ethnicity: v.value,
                                                                                    })
                                                                                }}
                                                                                value={this.state[row.key]} />
                                                                            : row.key == 'ethnicity' ?
                                                                                <Dropdown
                                                                                    options={nationalities.map((nation) => {
                                                                                        return {
                                                                                            value: nation,
                                                                                            key: nation,
                                                                                            text: nation,
                                                                                        }
                                                                                    })}
                                                                                    search
                                                                                    selection
                                                                                    name={row.key}
                                                                                    placeholder='เชื้อชาติ'
                                                                                    style={{ 'width': '100%' }}
                                                                                    onChange={(e, v) => {
                                                                                        this.setState({
                                                                                            [v.name]: v.value,
                                                                                        })
                                                                                    }}
                                                                                    value={this.state[row.key]} />
                                                                                : <Input
                                                                                    onKeyPress={(e) => {
                                                                                        if (e.key == 'Enter') {
                                                                                            this.nextRef(5 + i)
                                                                                            e.preventDefault()
                                                                                        }
                                                                                    }}
                                                                                    ref={(c) => this.ref_list[4 + i] = c}
                                                                                    placeholder={row.label}
                                                                                    position={3 + i}
                                                                                    onChange={(e) => {
                                                                                        this.setState({
                                                                                            [row.key]: e.target.value
                                                                                        })

                                                                                    }}
                                                                                    maxLength={100}
                                                                                    value={this.state[row.key]} />
                                                    }
                                                </div>}
                                        </Form.Field>)}
                                        <Form.Field>
                                            <label>หมายเหตุ</label>
                                            <TextArea
                                                ref={(c) => this.ref_list[this.ref_list.length] = c}
                                                position={this.ref_list.length}
                                                placeholder='หมายเหตุ'
                                                maxLength={255}
                                                onChange={(e) => this.setState({ description: e.target.value })}
                                                value={this.state.description} />
                                        </Form.Field>
                                        <Form.Field error={this.state.msg_error.staff != null}>
                                            <Checkbox
                                                label='พนักงาน'
                                                name='is_employee'
                                                checked={this.state.is_employee}
                                                onChange={(e, v) => this.setState({
                                                    is_employee: v.checked
                                                })} />
                                            <MsgInput text={this.state.msg_error.staff} />
                                            {
                                                this.state.is_employee &&
                                                <Dropdown
                                                    options={this.state.staff_option}
                                                    search
                                                    selection
                                                    name={'staff'}
                                                    placeholder='พนักงาน'
                                                    style={{ 'width': '100%', 'margin-top': '5px' }}
                                                    onChange={(e, v) => {
                                                        this.setState({
                                                            staff: v.value
                                                        })
                                                    }}
                                                    value={this.state['staff']}
                                                />
                                            }
                                        </Form.Field>
                                    </div>}
                                    {activeItem == 'address2' && <div>
                                        <Form.Field>
                                            <label>ที่อยู่</label>
                                            <Input
                                                onChange={(e) => this.setState({ address2: e.target.value })}
                                                placeholder='ที่อยู่'
                                                value={this.state.address2}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>ตำบล</label>
                                            <Input
                                                onChange={(e) => this.setState({ district2: e.target.value })}
                                                placeholder='ตำบล'
                                                value={this.state.district2}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field></Form.Field>
                                        <Form.Field>
                                            <label>อำเภอ</label>
                                            <Input
                                                onChange={(e) => this.setState({ city2: e.target.value })}
                                                placeholder='อำเภอ'
                                                value={this.state.city2}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>จังหวัด</label>
                                            <Input
                                                onChange={(e) => this.setState({ province2: e.target.value })}
                                                placeholder='จังหวัด'
                                                value={this.state.province2}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>รหัสไปรษณีย์</label>
                                            <Input
                                                onChange={(e) => this.setState({ postal_code2: e.target.value })}
                                                placeholder='รหัสไปรษณีย์'
                                                value={this.state.postal_code2}
                                                maxLength={10}
                                            />
                                        </Form.Field>
                                    </div>}
                                    {activeItem == 'address3' && <div>
                                        <Form.Field>
                                            <label>ที่อยู่</label>
                                            <Input
                                                onChange={(e) => this.setState({ address3: e.target.value })}
                                                placeholder='ที่อยู่'
                                                value={this.state.address3}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>ตำบล</label>
                                            <Input
                                                onChange={(e) => this.setState({ district3: e.target.value })}
                                                placeholder='ตำบล'
                                                value={this.state.district3}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>อำเภอ</label>
                                            <Input
                                                onChange={(e) => this.setState({ city3: e.target.value })}
                                                placeholder='อำเภอ'
                                                value={this.state.city3}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>จังหวัด</label>
                                            <Input
                                                onChange={(e) => this.setState({ province3: e.target.value })}
                                                placeholder='จังหวัด'
                                                value={this.state.province3}
                                                maxLength={100}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>รหัสไปรษณีย์</label>
                                            <Input
                                                onChange={(e) => this.setState({ postal_code3: e.target.value })}
                                                placeholder='รหัสไปรษณีย์'
                                                value={this.state.postal_code3}
                                                maxLength={10} />
                                        </Form.Field>
                                    </div>}



                                </Segment>

                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>

                        <Button
                            id="AddConfirm"
                            size='small'
                            primary
                            onClick={this.handlerSubmitForm}
                            className={this.state.button_class}>บันทึก</Button>
                        <Button
                            id="AddCancel"
                            size='small'
                            onClick={(e) => {
                                e.preventDefault();
                                this.resetForm()
                                this
                                    .props
                                    .onClose();
                            }}>ยกเลิก</Button>
                    </Modal.Actions>
                </Modal> </div>
        );
    }

}
Customer.defaultProps = {
    action: 'add'
};

export default Customer